import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Icon, Loader } from "semantic-ui-react";
import PromoList from "./PromoList/controller";
import { ToolbarOptions } from "../../../../../data";
import Tooltip from "../../../../../components/Tooltip";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import RichTextEditorError from "../../../../../components/RichTextEditorError";

export default function DotComLowerThirdsView({
  position,
  title,
  graphicsURL,
  script,
  round_id,
  edit,
  createPromo,
  updatePromo,
  deletePromo,
  warningData,
  promos,
  sectionRef,
  isLoading,
  isHydrating,
  isDeleting,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section uploads">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            DOTCOM Lower Thirds
          </label>
          {isHydrating && (
            <span className="initializing__loader">
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col dbl__full">
          <div className="section__input">
            <label className="input__label">CREATE OR EDIT PROMOS</label>
            <br />

            <div style={{ display: "flex" }}>
              <div
                className="field__set"
                style={{ width: "50%", marginRight: 10 }}
              >
                <label className="field__label">Position</label>
                <input
                  onChange={(e) => position.setter(e.target.value)}
                  min={1}
                  type="number"
                  value={position.value}
                  name="position"
                  placeholder=""
                />
              </div>

              <div className="field__set" style={{ width: "50%" }}>
                <label className="field__label">Round</label>
                <input
                  onChange={(e) => round_id.setter(e.target.value)}
                  min={1}
                  max={4}
                  type="number"
                  value={round_id.value}
                  name="round_id"
                  placeholder=""
                />
              </div>
            </div>

            <div className="field__set">
              <label className="field__label">
                Promo Title<span className="req">*</span>
              </label>
              <input
                onChange={(e) => title.setter(e.target.value)}
                type="text"
                value={title.value}
                name="title"
                placeholder=""
              />
            </div>

            <div className="field__set">
              <label className="field__label">
                Graphics URL<span className="req">*</span>
              </label>
              <input
                onChange={(e) => graphicsURL.setter(e.target.value)}
                type="text"
                value={graphicsURL.value}
                name="graphics_url"
                placeholder=""
              />
            </div>

            <div className="field__set">
              <label className="field__label">
                Script<span className="req">*</span> <br />
                <Tooltip
                  label={"Text only"}
                  tip={
                    "Please do not insert or paste images, files or anything other than rich-text."
                  }
                />
              </label>
              <ErrorBoundary
                fallback={
                  <RichTextEditorError handleReset={() => updatePromo(" ")} />
                }
              >
                <Editor
                  toolbar={ToolbarOptions}
                  editorState={
                    script.value.raw === ""
                      ? EditorState.createEmpty()
                      : script.value.draft
                  }
                  onEditorStateChange={(editorState) => {
                    const obj = {
                      raw: draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      ),
                      draft: editorState,
                    };
                    script.setter(obj);
                  }}
                />
              </ErrorBoundary>
            </div>
            {Object.values(warningData).some(
              (warning) => warning.active === true
            ) && (
              <ul className="form__warnings">
                {Object.values(warningData)
                  .filter((warning) => warning.active === true)
                  .map((warning, index) => (
                    <li key={index}>
                      <Icon name="ban" />{" "}
                      <span>{warning?.message && warning.message()}</span>
                    </li>
                  ))}
              </ul>
            )}
            <br />
            <div className="field__set submit">
              {edit.value.value === true && (
                <button
                  className="cancel"
                  onClick={() =>
                    edit.setter({
                      value: false,
                      id: "",
                    })
                  }
                >
                  cancel edit
                </button>
              )}
              <button
                className="submit"
                style={{ paddingRight: 30 }}
                onClick={
                  edit.value.value === true
                    ? () => updatePromo()
                    : () => createPromo()
                }
              >
                {edit.value.value === true ? (
                  <>
                    <Icon name="save" /> Update Promo
                  </>
                ) : (
                  <>
                    <Icon name="add" /> Create Promo
                  </>
                )}

                {isLoading && (
                  <span className="initializing__loader">
                    <Loader active inline size="mini" inverted />
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className="section__input">
            <PromoList
              promos={promos}
              edit={edit}
              deletePromo={deletePromo}
              isDeleting={isDeleting}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
