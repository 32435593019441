import React from "react";
import { Icon } from "semantic-ui-react";
import SmartImage from "../../../../components/SmartImage/controller";

export default function EditTournamentSidebarView({
  tournament,
  sectionRefs,
  sidebarToggle,
}) {
  return (
    <div
      className={`edit__tournament__header ${
        sidebarToggle.value ? "expanded" : "collapsed"
      }`}
    >
      <div className="header__container">
        <div className="col__container">
          {tournament &&
            typeof tournament === "object" &&
            tournament !== null &&
            Object.keys(tournament).length > 1 && (
              <>
                <div className="tournament__col">
                  <div className="tournament__header">
                    <div>
                      {tournament?.tour?.tour_name && (
                        <span className="tour__name">
                          {tournament.tour.tour_name}
                        </span>
                      )}

                      <h3>{tournament.tournament_name}</h3>
                    </div>
                    <div className="tournament__logo">
                      <SmartImage
                        url={`${tournament.tournament_logo_url}`}
                        alt="tournament logo"
                      />
                    </div>
                  </div>
                  <ul className="tournament__quick__details">
                    {tournament?.date_range && (
                      <li>
                        <Icon name="calendar outline" /> {tournament.date_range}
                      </li>
                    )}
                    {tournament?.tournament_location && (
                      <li>
                        <Icon name="map marker alternate" />{" "}
                        {tournament.tournament_location}
                      </li>
                    )}
                    {tournament?.golf_course && (
                      <li>
                        <Icon name="flag" /> {tournament.golf_course}
                      </li>
                    )}
                  </ul>
                </div>
              </>
            )}
          <div className="tournament__navigation">
            <h3>Navigation Menu</h3>
            <ul className="edit__tournament__menu">
              {typeof sectionRefs === "object" &&
                sectionRefs !== null &&
                Object.values(sectionRefs).map((section, index) => (
                  <li key={index}>
                    <button
                      onClick={() => {
                        if (section.ref.current) {
                          section.ref.current.scrollIntoView();
                        }
                      }}
                    >
                      {section.label}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      <button
        className="toggle"
        onClick={() => sidebarToggle.setter(!sidebarToggle.value)}
      >
        <Icon name={`caret ${sidebarToggle.value ? "left" : "right"}`} />
      </button>
    </div>
  );
}
