import React from "react";
import { Icon } from "semantic-ui-react";
import { capitalize } from "../../../../../utility";
import PreloaderOverlay from "../../../../../components/PreloaderOverlay";
import ErrorOverlay from "../../../../../components/ErrorOverlay";

export default function PlayerGroupingsNotesView({
  groupingActions,
  groupedCatalystNotes,
}) {
  const excludes = ["first_name", "last_name", "player_id"];

  return (
    <div className="player__groupings__notes">
      <div className="groupings__header">
        <button className="back__prompt" onClick={() => groupingActions.back()}>
          <Icon name="chevron left" />
        </button>
        <div className="header__content">
          <h4>Group #{groupingActions.value.group_number}</h4>
          <p>
            {groupingActions.value.players.map((player, index) => (
              <span key={index}>
                {player?.first_name && player.first_name[0]}.{" "}
                {player?.last_name && player.last_name}
                {index + 1 === groupingActions.value.players.length ? "" : ", "}
              </span>
            ))}
          </p>
        </div>
      </div>
      <div className="groupings__notes">
        {groupedCatalystNotes !== null ? (
          Array.isArray(groupedCatalystNotes) &&
          groupedCatalystNotes.length > 0 ? (
            groupedCatalystNotes.map((playerNotes, index) => (
              <div key={index} className="player__notes">
                <h2 className="tab__print__title">
                  <span className="title__parent">Catalyst Notes</span>
                  {playerNotes.first_name} {playerNotes.last_name}
                </h2>
                <h5 className="player__name">
                  {playerNotes.first_name} {playerNotes.last_name}
                </h5>
                {Object.entries(playerNotes).filter(
                  ([key, _]) => !excludes.includes(key)
                ).length > 0 ? (
                  Object.entries(playerNotes)
                    .filter(([key, _]) => !excludes.includes(key))
                    .map(([key, value], index) => (
                      <React.Fragment key={index}>
                        {Array.isArray(value) && value.length > 0 && (
                          <div key={index} className="note__grouping">
                            <h6>{capitalize(key)}</h6>
                            <ul>
                              {value.map((note, index) => (
                                <li key={index}> {note}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </React.Fragment>
                    ))
                ) : (
                  <React.Fragment key={index}>
                    <div className="note__grouping">
                      <h6>No catalyst notes to display.</h6>
                    </div>
                  </React.Fragment>
                )}
              </div>
            ))
          ) : (
            <div
              style={{ height: "100%", minHeight: 300, position: "relative" }}
            >
              <PreloaderOverlay message="Fetching catalyst notes..." />
            </div>
          )
        ) : (
          <div style={{ minHeight: 150 }}>
            <ErrorOverlay message={"Error fetching catalyst notes."} />
          </div>
        )}
      </div>
    </div>
  );
}
