import React from "react";
import { Icon, Loader } from "semantic-ui-react";

export default function StaticLink({ url, title, onDelete, loading }) {
  return (
    <div className="link__card">
      <a href={url} target="_blank" rel="noreferrer">
        <span className="link__icon">
          <Icon name="chain" />
        </span>
        <span className="link__content">
          <h3>
            {title} <Icon name="external square alternate" />
          </h3>
          <span className="link__url">{url}</span>
        </span>

        {onDelete && typeof onDelete === "function" && (
          <button className="delete__file" onClick={() => onDelete()}>
            <Icon name="trash" />
          </button>
        )}

        {loading && (
          <span className="link__loader">
            <Loader active inline size="mini" />
          </span>
        )}
      </a>
    </div>
  );
}
