import React, { useEffect, useState } from "react";
import CameraPlanFlowchartView from "./view";
import { postTechnicalWorkbook } from "../../../../../api/Resources/technical_workbook";
import { useIsMounted } from "../../../../../hooks/useIsMounted";
import { useAuth } from "../../../../../context/AuthProvider";

export default function TechDirAudioWorkbooksController({
  workbooks,
  mainTab,
  sectionRef,
}) {
  const [computedWorkbooks, setComputedWorkbooks] = useState([]);
  const [isInitializing, setIsInitializing] = useState(false);

  const authContext = useAuth();
  const { apiKey } = authContext;

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let workbooks_ = [...workbooks];

    for (let i = 0; i < workbooks_.length; i++) {
      let s3URL;
      try {
        const { data } = await postTechnicalWorkbook.fetchS3Url({
          uid: workbooks_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      workbooks_[i] = {
        ...workbooks_[i],
        fileURI: workbooks_[i].fileURI.replace(`/${workbooks_[i].id}`, ""),
        s3URI: s3URL,
      };
    }

    if (isMounted.current) {
      setIsInitializing(false);
      setComputedWorkbooks(workbooks_);
    }
  };

  useEffect(() => {
    if (Array.isArray(workbooks) && workbooks.length > 0) initializeData();
  }, [workbooks]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CameraPlanFlowchartView
      workbooks={computedWorkbooks}
      mainTab={mainTab}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
