import React, { useEffect, useState } from "react";
import PlayerGroupingsNotesView from "./view";
import { catalystNotes } from "../../../../../api/Resources/catalyst_notes";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useAuth } from "../../../../../context/AuthProvider";

export default function PlayerGroupingNotesController({ groupingActions }) {
  const [groupedCatalystNotes, setGroupedCatalystNotes] = useState([]);

  const notificationContext = useNotification();
  const { setDisplayNotification, setNotification } = notificationContext;

  const authContext = useAuth();
  const { logout, token } = authContext;

  const getPlayerGroupingNotes = async () => {
    const { players } = groupingActions.value;
    const result = await catalystNotes.fetchNotes({
      players: players,
      token: token.idToken.value,
    });

    if (result.status === 401) {
      logout();
      setNotification({
        message: "Session has expired. You have been logged out.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      return setDisplayNotification(true);
    }

    if (result.error === true) {
      return setGroupedCatalystNotes(null);
    } else {
      setGroupedCatalystNotes(result);
    }
  };

  useEffect(() => {
    if (
      Array.isArray(groupingActions.value.players) &&
      groupingActions.value.players.length > 0 &&
      Array.isArray(groupedCatalystNotes)
    )
      getPlayerGroupingNotes();
  }, [groupingActions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => setGroupedCatalystNotes([]);
  }, []);

  return (
    <PlayerGroupingsNotesView
      groupingActions={groupingActions}
      groupedCatalystNotes={groupedCatalystNotes}
    />
  );
}
