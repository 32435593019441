import React, { useState, useEffect, useMemo } from "react";
import { computeDateRange, sortTournaments } from "../utility";
import { useTournaments } from "../context/TournamentsProvider";
import { useAuth } from "../context/AuthProvider";
import { getOneTournament } from "../api/Tournaments/tournament";
import { useIsMounted } from "../hooks/useIsMounted";
import TournamentDetails from "../features/TournamentDetails/controller";

export default function Tournament({ match }) {
  const [tournamentData, setTournamentData] = useState(null);
  const [fetched, setFetched] = useState(false);

  const { tournaments, selectedTour } = useTournaments();
  const { token, apiKey } = useAuth();

  const tournamentId = useMemo(() => match?.params?.id, [match]);
  const tourCode = useMemo(() => tournamentId[0], [tournamentId]);
  const tournamentPrevNext = useMemo(
    () => computePrevNext(),
    [tournamentData, tournaments] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const hasError = useMemo(() => {
    if (
      fetched &&
      (!tournaments || !Array.isArray(tournaments[tourCode]) || !tournamentData)
    )
      return true;
    return false;
  }, [fetched, tournaments, tourCode, tournamentData]);

  let isMounted = useIsMounted();

  const initializeData = (tournamentData) => {
    if (typeof tournamentData !== "object" || tournamentData === null)
      return null;

    const tournament = tournamentData;
    const obj = {
      ...tournament,
      date_range: computeDateRange(
        tournament.tournament_start,
        tournament.tournament_end
      ),
    };

    return obj;
  };

  const getTournament = async () => {
    if (!tournamentId) return;
    let formattedTournament;

    try {
      const result = await getOneTournament({
        id: tournamentId,
        apiKey: apiKey,
      });

      if (result.error)
        throw new Error(`Error fetching tournament: ${tournamentId}`);
      formattedTournament = initializeData(result);
    } catch (error) {
      console.log(error);
      formattedTournament = null;
    }

    if (isMounted.current) {
      setFetched(true);
      setTournamentData(formattedTournament);
    }
  };

  function computePrevNext() {
    let obj = { next: null, prev: null };
    if (!tourCode || !tournaments[tourCode]) return obj;

    let t = sortTournaments([...tournaments[tourCode]]);
    let foundTournament = t.findIndex(
      (tournament) => tournament.id === tournamentId
    );

    if (foundTournament !== 0) obj["prev"] = t[foundTournament - 1];
    if (foundTournament + 1 !== t.length) obj["next"] = t[foundTournament + 1];

    return obj;
  }

  useEffect(() => {
    if (Array.isArray(tournaments[tournamentId[0]]) && tournamentData === null)
      getTournament();
  }, [tournamentData, tournaments, token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTournamentData(null);
    setFetched(false);
  }, [match]);

  useEffect(() => {
    if (tourCode) selectedTour.setter(tourCode);
  }, [tourCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const tournamentProps = {
    tournamentId,
    tournamentData,
    tournamentPrevNext,
    fetched,
    hasError,
  };

  return (
    <TournamentDetails
      key={tournamentId}
      tourCode={tourCode}
      tournamentProps={tournamentProps}
    />
  );
}
