import React from "react";
import { Icon } from "semantic-ui-react";
import DragDrop from "../../../../components/Form/DragDrop";
import { parseExtension, formatBytes } from "../../../../utility";
import { Loader } from "semantic-ui-react";
import EmptyDialogue from "../../../../components/EmptyDialogue";

export default function SponsorShipAgendasView({
  bucket,
  initializing,
  sectionRef,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section uploads">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            Sponsorship Agendas
          </label>

          {initializing && (
            <span className="initializing__loader">
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col dbl__full reverse__wrap">
          {typeof bucket?.state === "object" &&
            bucket?.state !== null &&
            Object.keys(bucket?.state).length > 0 &&
            Object.values(bucket.state).map((roundBucket, index) => (
              <React.Fragment key={index}>
                <div className="section__input">
                  <label className="input__label">
                    {roundBucket.roundData.label}
                  </label>
                  <DragDrop
                    handleDropped={({ files }) => {
                      bucket.selector({
                        files: files,
                        roundKey: roundBucket.roundData.key,
                      });
                    }}
                  >
                    <div className="file__drag">
                      {roundBucket.selected &&
                      Array.isArray(roundBucket.selected) &&
                      roundBucket.selected.length > 0 ? (
                        roundBucket.selected.map((selected, index) => (
                          <div className="file__preview" key={index}>
                            <span className="label__popout">
                              {selected.file.name}
                            </span>
                            <div className="file__icon">
                              <span>{parseExtension(selected.file.name)}</span>
                              <Icon name="file" />
                            </div>
                            <p
                              style={{ lineHeight: "1em" }}
                              className="file__data"
                            >
                              {selected.label.active === true ||
                              selected.label.value !== "" ? (
                                <span
                                  style={{
                                    display: "flex",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <input
                                    className={`file__label ${selected.label.active}`}
                                    onChange={(e) =>
                                      bucket.labeller({
                                        operation: "update",
                                        id: selected.id,
                                        newValue: e.target.value,
                                        roundKey: roundBucket.roundData.key,
                                      })
                                    }
                                    onClick={
                                      !selected.label.active
                                        ? () =>
                                            bucket.labeller({
                                              operation: "toggle",
                                              id: selected.id,
                                              roundKey:
                                                roundBucket.roundData.key,
                                            })
                                        : () => null
                                    }
                                    type="text"
                                    value={selected.label.value}
                                  />
                                  {selected.label.active === true && (
                                    <span className="label__actions">
                                      <button
                                        onClick={() =>
                                          bucket.labeller({
                                            operation: "toggle",
                                            id: selected.id,
                                            roundKey: roundBucket.roundData.key,
                                          })
                                        }
                                      >
                                        <Icon
                                          name="check"
                                          style={{ color: "var(--success-1)" }}
                                        />
                                      </button>
                                    </span>
                                  )}
                                </span>
                              ) : null}

                              {selected.file.name}
                              <span
                                className="size"
                                style={{ display: "block", marginTop: 3 }}
                              >
                                {formatBytes(selected.file.size)}
                              </span>
                            </p>
                            <div className="button__actions">
                              <button
                                className="delete__file"
                                onClick={() =>
                                  bucket.labeller({
                                    id: selected.id,
                                    operation: "toggle",
                                    roundKey: roundBucket.roundData.key,
                                  })
                                }
                              >
                                <Icon name="tag" />
                              </button>
                              <button
                                className="delete__file"
                                onClick={() =>
                                  bucket.deselector({
                                    id: selected.id,
                                    roundKey: roundBucket.roundData.key,
                                  })
                                }
                              >
                                <Icon name="x" />
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="upload__prompt">
                          <Icon name="upload" />
                          <p>Select File(s)</p>
                          <span className="accepted">
                            {bucket.bucket.accepted.map(
                              (acc, index) =>
                                `${acc}${
                                  index === bucket.bucket.accepted.length - 1
                                    ? ""
                                    : ", "
                                }`
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </DragDrop>
                  <label className="file__input">
                    <input
                      multiple="multiple"
                      onChange={(e) =>
                        bucket.selector({
                          files: e.target.files,
                          roundKey: roundBucket.roundData.key,
                        })
                      }
                      className="input"
                      type="file"
                      accept={`${bucket.bucket.accepted.join(", ")}`}
                      style={{
                        position: "absolute",
                        width: 0,
                        height: 0,
                        opacity: 0,
                      }}
                      placeholder=""
                    />
                    Drag and drop files above, or &nbsp;
                    <span className="browse" tabIndex="0">
                      browse files
                    </span>{" "}
                    to select files.
                  </label>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      className="save__btn"
                      style={{ maxWidth: 175, marginTop: 20 }}
                      onClick={() =>
                        bucket.uploader({ roundKey: roundBucket.roundData.key })
                      }
                    >
                      <Icon name="cloud upload" />
                      Upload Files
                      {roundBucket.loading && (
                        <span className="initializing__loader">
                          <Loader active inline size="mini" inverted />
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="section__input">
                  <label className="input__label" style={{ opacity: 0 }}>
                    {roundBucket.roundData.label}
                  </label>
                  <div className="uploaded__files bucket">
                    <span className="box__label">
                      {roundBucket.roundData.label} – Uploaded{" "}
                      <span className="count">
                        <span>({roundBucket.uploaded.length})</span>
                      </span>
                    </span>
                    <div className="bucket__container">
                      {roundBucket.uploaded &&
                      Array.isArray(roundBucket.uploaded) &&
                      roundBucket.uploaded.length > 0 ? (
                        roundBucket.uploaded.map((uploaded, index) => (
                          <div className="file__preview" key={index}>
                            <span className="label__popout">
                              {uploaded.file_name}
                            </span>
                            <div className="file__icon">
                              <span>{parseExtension(uploaded.file.name)}</span>
                              <Icon name="file" />
                            </div>
                            <p
                              style={{ lineHeight: "1em" }}
                              className="file__data"
                            >
                              {uploaded.file_label !== "" && (
                                <span className="file__label false">
                                  {uploaded.file_label}
                                </span>
                              )}
                              <a
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "var(--highlight-primary-1)",
                                  display: "block",
                                  marginBottom: 3,
                                }}
                                href={uploaded.file_url}
                                target="blank_"
                                rel="noreferrer"
                              >
                                {uploaded.file_name}
                              </a>
                              <span
                                className="size"
                                style={{ display: "block" }}
                              >
                                {formatBytes(uploaded.file.size)}
                              </span>
                            </p>
                            <div className="button__actions">
                              <button
                                className="delete__file"
                                onClick={() =>
                                  bucket.deleter({
                                    id: uploaded.id,
                                    roundKey: roundBucket.roundData.key,
                                  })
                                }
                              >
                                <Icon name="trash" />
                              </button>
                            </div>

                            {uploaded.loading === true && (
                              <span className="upload__loader">
                                <Loader active inline size="mini" />
                              </span>
                            )}
                          </div>
                        ))
                      ) : (
                        <EmptyDialogue
                          message={{
                            title: "No uploads yet",
                            subtitle: "Select a file to begin uploading!",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </form>
  );
}
