import React from "react";
import TabSelectors from "./TabSelectors/controller";
import SectionHead from "../../../../components/SectionHead";
import Document from "../../../../components/Document";
import { extractFileNameFromPath } from "../../../../utility";
import EmptyDialogue from "../../../../components/EmptyDialogue";
import LoadingDialogue from "../../../../components/LoadingDialogue";

export default function SponsorshipAgendasView({
  agendas,
  currentTab,
  printRef,
  sectionRef,
  initializing,
}) {
  let tabSelectors = [];
  if (typeof agendas === "object" && agendas !== null)
    tabSelectors = Object.keys(agendas);

  return (
    <div className="section__container" ref={printRef}>
      <div className="section__anchor__main" ref={sectionRef}></div>{" "}
      <div className="tab__container">
        <SectionHead title={"Sponsorship Agendas"} />
        {Array.isArray(tabSelectors) && tabSelectors.length > 0 && (
          <TabSelectors selectors={tabSelectors} currentTab={currentTab} />
        )}
        <div className="section__body">
          {typeof agendas === "object" && agendas !== null ? (
            Object.entries(agendas).map(([key, value], index) => (
              <div
                key={index}
                className={`tab__content ${
                  currentTab.value === key ? "active" : ""
                }`}
              >
                {Array.isArray(value) && value.length > 0 ? (
                  <>
                    <div className="document__container col2">
                      {value.map((file, index) => (
                        <Document
                          key={index}
                          name={
                            file.label
                              ? file.label
                              : extractFileNameFromPath(file.file_name)
                          }
                          path={{
                            prepend: null,
                            path: file.fileURI,
                          }}
                          externalURL={file.s3URI}
                          lastUpdated={"2d Ago"}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="empty__content">
                    {initializing ? <LoadingDialogue /> : <EmptyDialogue />}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="empty__content">
              {initializing ? <LoadingDialogue /> : <EmptyDialogue />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
