import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export default function ScrollingTickerView({
  cells,
  scrollBoxRef,
  mouse,
  initialized,
  selectedTour,
  tours,
}) {
  return (
    <div className="scrolling__ticker">
      <div
        className="ticker__container"
        ref={scrollBoxRef}
        onMouseDown={(e) => mouse.mouseDown(e)}
        onMouseUp={(e) => mouse.mouseUp(e)}
        onMouseMove={(e) => mouse.mouseMove(e)}
        onMouseLeave={(e) => mouse.mouseUp(e)}
        onWheel={(e) => mouse.mouseWheel(e)}
      >
        {initialized &&
          cells.length > 0 &&
          cells.map((cell, index) => (
            <button key={index} className="ticker__cell">
              <Link to={`/tournaments/${cell.id}`}>
                <h4>{cell.tournament_name}</h4>
              </Link>
              <span className="sub">{cell.date_range}</span>
            </button>
          ))}
        {cells.length === 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                fontSize: "14px",
                marginLeft: "20px",
                whiteSpace: "nowrap",
              }}
            >
              Unfortunately, we could not fetch any tournaments for you.
            </p>
          </div>
        )}
      </div>
      <div className="ticker__panel left">
        <div className="panel__meta">
          <div className="minimal__select">
            <select
              onChange={(e) => selectedTour.setter(e.target.value)}
              value={selectedTour.value}
            >
              {tours.map((tour, index) => (
                <option key={index} value={tour.key}>
                  {tour.label}
                </option>
              ))}
            </select>
            <Icon name="chevron down" />
          </div>
          <span className="label">Events</span>
        </div>
      </div>
      <div className="ticker__panel right"></div>
    </div>
  );
}
