import React from "react";
import SectionHead from "../../../../../components/SectionHead";
import SmartImage from "../../../../../components/SmartImage/controller";
import PersonnelList from "../../../../../components/PersonnelList";
import TournamentDetails from "../../../../../components/TournamentDetails";

import DailySchedule from "../../DailySchedule/controller";
import DailySchedulePGATE from "../../DailySchedulePGATE/controller";
import StreamDetails from "../../StreamDetails/controller";
import Memorandums from "../../Memorandums/controller";
import TournamentDocuments from "../../TournamentDocuments/controller";
import Timesheets from "../../Timesheets/controller";
import UrgentMessages from "../../UrgentMessages/controller";
import PlayerNotes from "../../PlayerNotes/controller";

export default function OverviewHView({
  method,
  tournament,
  printRef,
  sectionRefs,
}) {
  return (
    <div className="tournament__overview" style={{ position: "relative" }}>
      <div
        ref={sectionRefs?.overview?.ref || null}
        className="section__anchor__main"
      ></div>
      <div className="tournament__overview__sidebar" ref={printRef}>
        <div className="tournament__overview__overview">
          <div className="print__title">
            <h4>Tournament Overview</h4>
          </div>
          <SectionHead title={"Overview"} method={method} />
          <div className="overview__content">
            {typeof tournament === "object" &&
              tournament !== null &&
              Object.keys(tournament).length > 1 && (
                <>
                  <div className="tournament__header">
                    <div>
                      {tournament?.tour?.tour_name && (
                        <span className="tour__name">
                          {tournament.tour.tour_name}
                        </span>
                      )}

                      <h3>{tournament?.tournament_name}</h3>
                    </div>
                    <div className="tournament__logo">
                      <SmartImage
                        url={`${tournament.tournament_logo_url}`}
                        alt="tournament logo"
                      />
                    </div>
                  </div>
                  <TournamentDetails
                    date_range={tournament?.date_range}
                    tournament_location={tournament?.tournament_location}
                    golf_course={tournament?.golf_course}
                  />

                  <PersonnelList personnel={tournament?.personnel} />
                  <Memorandums
                    memos={tournament?.memos}
                    links={tournament?.memos_link}
                  />
                </>
              )}
          </div>
        </div>

        <div className="tournament__overview__timesheets">
          <Timesheets
            files={tournament?.timesheet}
            sectionRef={sectionRefs?.timesheets?.ref || null}
          />
        </div>
      </div>

      <div className="tournament__overview__main">
        <UrgentMessages
          files={tournament?.urgent_message_file}
          content={tournament?.urgent_message?.content}
        />
        <div className="tournament__overview__main__grid">
          <div className="tournament__overview__schedule__onsite daily__schedule">
            <DailySchedule
              schedule={tournament?.daily_schedule}
              sectionRef={sectionRefs?.daily_schedule?.ref || null}
            />
          </div>
          <div className="tournament__overview__schedule__pgate daily__schedule">
            <DailySchedulePGATE
              schedule={tournament?.daily_schedule_pgate}
              sectionRef={sectionRefs?.daily_schedule_pgate?.ref || null}
            />
          </div>

          <div className="tournament__overview__stream__details">
            <StreamDetails
              title={"Broadcast Details"}
              details={tournament?.stream_detail}
              sectionRef={sectionRefs?.stream_details?.ref || null}
            />
          </div>

          <div className="tournament__overview__stream__assignments">
            <PlayerNotes
              playerNotes={tournament?.resources?.talent.player_note}
              sectionRef={sectionRefs?.player_notes?.ref || null}
            />
          </div>

          <div className="tournament__overview__documents">
            <TournamentDocuments
              crafterTournament={tournament?.crafter_api?.tournament}
              sectionRef={sectionRefs?.tournament_documents?.ref || null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
