import { Component } from "react";

export default class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = { error: null };

  componentDidCatch(error) {
    if (this.props.onError) this.props.onError(error);
  }

  render() {
    if (this.state.error !== null) {
      return this.props.fallback;
    } else {
      return this.props.children;
    }
  }
}
