import React from "react";
import SectionHead from "../../../../components/SectionHead";
import Document from "../../../../components/Document";
import { extractFileNameFromPath } from "../../../../utility";
import { Loader } from "semantic-ui-react";
import parse from "html-react-parser";

export default function UrgentMessagesView({
  files,
  content,
  isInitializing: initializing,
}) {
  if (
    (content !== "" && content) ||
    (Array.isArray(files) && files.length > 0)
  ) {
    return (
      <div className="urgent__messages">
        <SectionHead title={"Urgent Messages"} method={null} />
        <div className="tab__body">
          {content && (
            <div className="rich__text" style={{ position: "relative" }}>
              {parse(content)}
            </div>
          )}
          {Array.isArray(files) && files.length > 0 ? (
            <>
              <div
                className="document__container col2"
                style={{ marginTop: 10 }}
              >
                {files.map((file, index) => (
                  <div className="urgent__message__file" key={index}>
                    <Document
                      name={
                        file.label
                          ? file.label
                          : extractFileNameFromPath(file.file_name)
                      }
                      path={{
                        prepend: null,
                        path: file.fileURI,
                      }}
                      externalURL={file.s3URI}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            initializing && (
              <>
                <br />
                <span className="initializing__loader">
                  <Loader active inline size="mini" /> &nbsp; Fetching files
                </span>
              </>
            )
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
