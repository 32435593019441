import React from "react";
import SectionHead from "../../../../components/SectionHead";
import parse from "html-react-parser";
import EmptyDialogue from "../../../../components/EmptyDialogue";

export default function StreamDetailsView({
  title,
  method,
  printRef,
  details,
  sectionRef,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      ref={printRef}
      className="section__container"
    >
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={title} method={method} />

      <div className="print__title">
        <h4>{title}</h4>
      </div>
      <div className="section__body" style={{ position: "relative" }}>
        {details && typeof details === "object" && details !== null ? (
          <>
            {details?.content !== null && details?.content !== undefined ? (
              <div className="rich__text" style={{ position: "relative" }}>
                {parse(details.content)}
              </div>
            ) : (
              <EmptyDialogue />
            )}
          </>
        ) : (
          <div className="empty__content">
            <EmptyDialogue />
          </div>
        )}
      </div>
    </div>
  );
}
