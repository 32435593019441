import React, { useRef } from "react";
import DailyScheduleView from "./view";
import { useReactToPrint } from "react-to-print";

export default function DailySchedulePGATEController({ schedule, sectionRef }) {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <DailyScheduleView
      printRef={printRef}
      sectionRef={sectionRef}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
      schedule={schedule}
    />
  );
}
