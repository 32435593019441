import React, { useState, useEffect } from "react";
import Slideshow from "../components/Slideshow/controller";
import { useScreen } from "../context/ScreenProvider";
import { useTournaments } from "../context/TournamentsProvider";
import TournamentExplorer from "../features/TournamentExplorer/controller";
import Header from "../components/Header/controller";
import ScrollingTicker from "../components/ScollingTicker/controller";
import Ribbon from "../components/Ribbon";
import Footer from "../components/Footer";
import { Icon } from "semantic-ui-react";

export default function Home() {
  const [toggledFavorites, setToggledFavorites] = useState(true);
  const [toggledNotifications, setToggledNotifications] = useState(true);
  const screenContext = useScreen();
  const { screenWidth } = screenContext;
  const { selectedTour } = useTournaments();

  useEffect(() => {
    if (screenWidth < 800) {
      setToggledFavorites(true);
      setToggledNotifications(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (screenWidth < 800) {
      if (toggledFavorites === true && toggledNotifications === true) {
        setToggledNotifications(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (screenWidth < 800) {
      if (toggledFavorites === true && toggledNotifications === true) {
        setToggledNotifications(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledFavorites]);

  useEffect(() => {
    if (screenWidth < 800) {
      if (toggledNotifications === true && toggledFavorites === true) {
        setToggledFavorites(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledNotifications]);

  return (
    <>
      <div className="pseudo__header">
        {selectedTour.value === "R" && (
          <Ribbon>
            <p>
              <Icon name="bullhorn" /> Have any photos or videos to feature on
              social media? Email them to{" "}
              <a
                href="mailto:LIVESocial@pgatourhq.com"
                target="blank_"
                rel="noreferrer"
              >
                LIVESocial@pgatourhq.com
              </a>
            </p>
          </Ribbon>
        )}

        <Header />
        <ScrollingTicker />
      </div>
      <div className="page">
        <div className="hero" style={{ position: "relative" }}>
          <Slideshow />
        </div>
        <div className="home__explorer">
          <TournamentExplorer />
        </div>
      </div>
      <Footer />
    </>
  );
}
