import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function TournamentBreadcrumbsView({ tournament }) {
  return (
    <div className="tournament__head">
      <div className="breadcrumbs">
        <Link to="/">
          <span>
            <Icon name="home" /> Tournaments
          </span>
        </Link>
        <Icon className="chevron" name="chevron right" />
        <span>
          <span
            style={{
              background: "var(--highlight-primary-3)",
              color: "var(--elevation-1)",
              padding: "3px 6px",
              borderRadius: 2,
              fontSize: 11,
            }}
          >
            {tournament.tour.tour_name}
          </span>{" "}
          &nbsp;
          {tournament.tournament_name}
        </span>
      </div>
    </div>
  );
}
