import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import Logo from "../Logo";

export default function Footer() {
  return (
    <footer>
      {" "}
      <div className="footer__wrap">
        <div className="footer__top">
          <Link to="/" className="footer__logo">
            <div className="logo">
              <Logo />
            </div>
          </Link>
          <div className="quick__links">
            <h4>Quick Links</h4>
            <ul>
              {quickLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.path} target="_blank" rel="noreferrer">
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footer__bottom">
          <p className="copyright">
            {new Date().getFullYear()} PGA Tour INC. All rights reserved. <br />{" "}
            100 PGA TOUR BLVD, Ponte Vedra Beach, FL 32086
          </p>
          <div className="social__menu">
            <h4>Connect with the PGA Tour on</h4>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/PGATour"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/pgatour"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/pgatour"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/pgatour"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="youtube" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.pgatour.com/mobile.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="mobile alternate" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

const quickLinks = [
  {
    label: "Media Website",
    path: "https://pgatourmedia.pgatourhq.com/",
  },
  {
    label: "Resource Registration Form",
    path: "https://app.smartsheet.com/b/form/7156fa6ea1f044ba81b34deca1621c84",
  },
  {
    label: "Travel Profile Form",
    path: "https://app.smartsheet.com/b/form/c316ae8d60e14f3597d8b2f9120ba513",
  },
  {
    label: "Travel Request Form",
    path: "https://app.smartsheet.com/b/form/452b211dac4746e1a3115cc0550fe1ae",
  },
];
