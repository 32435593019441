import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthProvider";
import Header from "./view";
import { withRouter } from "react-router";
import { staffDashboard } from "../../api/AdminSettings/settings";

export default withRouter(function HeaderController({ location, match }) {
  const authContext = useAuth();
  const { logout, profile, isAdmin, isStaff, apiKey } = authContext;

  let adminLinks_ = [
    {
      metaData: {
        type: "link",
        isExternal: false,
        path: "/dashboard/tournaments",
      },
      label: {
        text: "Admin Dashboard",
      },
    },
  ];

  const [menuStatus, toggleMenu] = useState(false);
  const [adminLinks, setAdminLinks] = useState(adminLinks_);
  const [staffingDashboardLink, setStaffingDashboardLink] = useState({
    label: "",
    url: "",
  });

  const fetchDashboardLink = async () => {
    if (isStaff || isAdmin) {
      try {
        const result = await staffDashboard.fetchHyperlink({
          apiKey: apiKey,
        });

        const { label, url } = result?.data;
        let hyperlink_ = { ...staffingDashboardLink };
        hyperlink_["label"] = label;
        hyperlink_["url"] = url;

        setStaffingDashboardLink(hyperlink_);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const path = location.pathname;
    const regex = new RegExp(`/tournaments/R*`, "gi");
    const isTournamentDetail = regex.test(path);
    if (isTournamentDetail) {
      setAdminLinks([
        {
          metaData: {
            type: "link",
            isExternal: false,
            path: "/dashboard/tournaments",
          },
          label: {
            text: "Admin Dashboard",
          },
        },
        {
          metaData: {
            type: "link",
            isExternal: false,
            path: `/dashboard/tournament/edit/${match.params.id}`,
          },
          label: {
            text: "Edit This Tournament",
            icon: "edit",
          },
        },
      ]);
    } else {
      setAdminLinks([
        {
          metaData: {
            type: "link",
            isExternal: false,
            path: "/dashboard/tournaments",
          },
          label: {
            text: "Admin Dashboard",
          },
        },
      ]);
    }
  }, [match]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchDashboardLink();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Header
      menu={{ status: menuStatus, toggle: toggleMenu }}
      profile={profile?.value}
      logout={logout}
      adminLinks={adminLinks}
      isAdmin={isAdmin}
      isStaff={isStaff}
      staffingDashboardLink={staffingDashboardLink}
    />
  );
});
