import React, { useEffect, useState } from "react";
import { postCameraPositions } from "../../../../api/Resources/camera_positions";
import CameraStartingPositionsView from "./view";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { useAuth } from "../../../../context/AuthProvider";

export default function CameraStartingPositionsController({
  charts,
  sectionRef,
}) {
  const [computedCharts, setComputedCharts] = useState([]);
  const [isInitializing, setIsInitializing] = useState(false);

  const authContext = useAuth();
  const { apiKey } = authContext;

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let charts_ = [...charts];

    for (let i = 0; i < charts_.length; i++) {
      let s3URL;
      try {
        const { data } = await postCameraPositions.fetchS3Url({
          uid: charts_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      charts_[i] = {
        ...charts_[i],
        fileURI: charts_[i].fileURI.replace(`/${charts_[i].id}`, ""),
        s3URI: s3URL,
      };
    }
    if (isMounted.current) setIsInitializing(false);
    if (isMounted.current) setComputedCharts(charts_);
  };

  useEffect(() => {
    if (Array.isArray(charts) && charts.length > 0) initializeData();
  }, [charts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CameraStartingPositionsView
      charts={computedCharts}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
