import React, { useState, useEffect } from "react";
import SponsorReadsView from "./view";
import { EditorState, ContentState } from "draft-js";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import { postSponsorshipReadsRounds } from "../../../../../api/Resources/sponsorship_reads";
import { evaluateTokenExpiry } from "../../../../../api";
import htmlToDraft from "html-to-draftjs";

export default function SponsorReadsController({
  tournamentId,
  sponsorReads,
  sectionRef,
}) {
  const richTextState = {
    raw: "",
    draft: EditorState.createEmpty(),
  };

  const rounds = {
    round1: {
      key: "round1",
      label: "Round 1",
    },
    round2: {
      key: "round2",
      label: "Round 2",
    },
    round3: {
      key: "round3",
      label: "Round 3",
    },
    round4: {
      key: "round4",
      label: "Round 4",
    },
  };

  const stateObj = {};
  const roundsArray = Object.values(rounds);

  for (let i = 0; i < roundsArray.length; i++) {
    stateObj[roundsArray[i].key] = {
      round: rounds[roundsArray[i].key],
      value: richTextState,
    };
  }

  const [sponsorReadsState, setSponsorReadsState] = useState(stateObj);
  const [isInitializing, setIsInitializing] = useState(false);

  const { setNotification, setDisplayNotification } = useNotification();
  const { logout, token } = useAuth();

  const setSponsorReads = ({ obj, roundId }) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    const currentState = { ...sponsorReadsState };
    currentState[roundId].value = obj;

    setSponsorReadsState(currentState);
  };

  const submitSponsorReads = async (overrideContent) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    const readsByRound = Object.values(sponsorReadsState);

    readsByRound.forEach(async (readByRound) => {
      try {
        setIsInitializing(true);
        const result = await postSponsorshipReadsRounds({
          tournamentId: tournamentId,
          content: overrideContent ? overrideContent : readByRound.value.raw,
          roundId: roundIdLookup[readByRound.round.key],
          token: token.idToken.value,
        });

        if (result.status === 401) {
          logout();
          setNotification({
            message: "Authentication error. Please login.",
            iconName: "ban",
            hex: "var(--danger-1)",
          });

          return setDisplayNotification(true);
        }

        if (result.error === true) throw new Error("Error");

        setNotification({
          message: `Successfully updated Sponsorship Reads`,
          iconName: "check",
          hex: "var(--success-1)",
        });
        setDisplayNotification(true);
        setIsInitializing(false);
      } catch (error) {
        setIsInitializing(false);
        console.log(error);

        setNotification({
          message: `Could not update 1 or more Sponsorship Reads`,
          iconName: "ban",
          hex: "var(--danger-1)",
        });
        setDisplayNotification(true);
      }
    });
  };

  const hydrateSponsorReads = () => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    if (!sponsorReads) return;
    if (!Array.isArray(sponsorReads)) return;
    if (sponsorReads.length < 1) return;
    setIsInitializing(true);

    const currentState = JSON.parse(JSON.stringify(sponsorReadsState));

    sponsorReads
      .filter((read) => read.round !== "graphics" || read.round !== "talent")
      .forEach((readsByRound) => {
        const roundId = roundAliasLookup[readsByRound.round];
        const content = readsByRound.content;

        if (!currentState[roundId]) return;

        let contentBlock = htmlToDraft(content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);

          currentState[roundId].value = {
            ...currentState[roundId].value,
            raw: content,
            draft:
              !content || content === ""
                ? EditorState.createEmpty()
                : editorState,
          };

          setSponsorReadsState(currentState);
        }
      });

    setIsInitializing(false);
  };

  useEffect(() => {
    hydrateSponsorReads();
  }, [sponsorReads]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SponsorReadsView
      sponsorReads={sponsorReadsState}
      setSponsorReads={setSponsorReads}
      submitSponsorReads={submitSponsorReads}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}

const roundIdLookup = {
  round1: "1",
  round2: "2",
  round3: "3",
  round4: "4",
  round5: "5",
};

const roundAliasLookup = {
  "Round 1": "round1",
  "Round 2": "round2",
  "Round 3": "round3",
  "Round 4": "round4",
};
