import React from "react";
import Scripts from "../../Scripts/R/controller";
import DotComLowerThirds from "../../DotComLowerThirds/R/controller";
import TalentRotation from "../../TalentRotation/controller";
import SponsorshipReads from "../../SponsorshipReads/R/controller";
import CatalystNotes from "../../CatalystNotes/controller";
import CameraAssignments from "../../CameraAssignments/controller";
import CameraPlanFlowchart from "../../CameraPlanFlowcharts/controller";
import CameraStartingPositions from "../../CameraStartingPositions/controller";
import ReliefSchedulesPGATE from "../../ReliefSchedulesPGATE/controller";
import SponsorshipAgendas from "../../SponsorshipAgendas/controller";
import TechDirAudioWorkbooks from "../../TechDirAudioWorkbooks/R";
import AdditionalResources from "../../AdditionalResources/controller";

export default function ResourcesRView({
  tab,
  resources,
  tournamentId,
  sectionRefs,
}) {
  return (
    <div className="tournament__resources__container">
      <div className="tab__container is_alternate">
        <div className="tab__selectors is_alternate">
          <div className="selectors__flex">
            <div className="selectors__container">
              <button
                value="crew"
                onClick={(e) => tab.toggle(e.target.value)}
                className={`btn tab is_alternate ${
                  tab.active === "crew" ? "active" : "inactive"
                }`}
              >
                Crew
              </button>
              <button
                value="talent"
                onClick={(e) => tab.toggle(e.target.value)}
                className={`btn tab is_alternate ${
                  tab.active === "talent" ? "active" : "inactive"
                }`}
              >
                Talent
              </button>
            </div>
          </div>
        </div>
        <div
          className={`tab__content ${
            tab.active === "talent" ? "active" : "inactive"
          }`}
        >
          <div className="section__resource">
            <Scripts
              scripts={resources?.talent.scripts}
              sectionRef={sectionRefs.scripts.ref}
            />
            <TalentRotation
              files={resources?.talent.talentRotation}
              mainTab={tab.active}
              sectionRef={sectionRefs.talent_rotation.ref}
            />
            <CatalystNotes
              sectionRef={sectionRefs.catalyst_notes.ref}
              tournamentId={tournamentId}
            />
            <SponsorshipReads
              sponsorshipReads={resources?.talent.sponsorshipReads}
              sectionRef={sectionRefs.sponsor_reads.ref}
            />
            <DotComLowerThirds
              promos={resources?.talent.dotComLowerThirds}
              sectionRef={sectionRefs.dotcom_thirds_talent.ref}
            />
          </div>
        </div>
        <div
          className={`tab__content ${
            tab.active === "crew" ? "active" : "inactive"
          }`}
        >
          <div className="section__resource">
            <CameraPlanFlowchart
              charts={resources?.crew.cameraPlanFlowcharts}
              sectionRef={sectionRefs.camera_flowcharts.ref}
            />
            <TechDirAudioWorkbooks
              sectionRef={sectionRefs.tech_dir_audio_workbooks.ref}
            />
            <CameraStartingPositions
              charts={resources?.crew.cameraStartingPositions}
              sectionRef={sectionRefs.camera_positions.ref}
            />

            <ReliefSchedulesPGATE
              files={resources?.crew.reliefSchedulesPGATE}
              mainTab={tab.active}
              sectionRef={sectionRefs.relief_schedules_pgate.ref}
            />
            <CameraAssignments
              cameraAssignments={resources?.crew.cameraAssignment}
              sectionRef={sectionRefs.camera_assignments.ref}
            />
            <AdditionalResources
              sectionRef={sectionRefs.additional_resources.ref}
              files={resources?.crew.additional_resource_files}
              links={resources?.crew.additional_resource_links}
            />
            <DotComLowerThirds
              promos={resources?.talent?.dotComLowerThirds}
              sectionRef={sectionRefs.dotcom_thirds_crew.ref}
            />
            <SponsorshipAgendas
              agendas={resources?.crew.sponsorshipAgendas}
              sectionRef={sectionRefs.sponsor_agendas.ref}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
