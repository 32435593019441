import React, { useState, useEffect, useRef } from "react";
import ScrollingTickerView from "./view";
import { computeDateRange, sortFilterTournaments } from "../../utility";
import { useTournaments } from "../../context/TournamentsProvider";
import { useLocation, useHistory, useParams } from "react-router-dom";

const today = new Date();
export default function ScrollingTickerController({ match }) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const { tournaments, selectedTour, tours } = useTournaments();

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const scrollBoxRef = useRef();

  const onMouseDown = (e) => {
    setIsScrolling(true);
    setClientX(e.clientX);
  };

  const onMouseUp = (e) => {
    setIsScrolling(false);
  };

  const onWheel = (e) => {
    if (scrollBoxRef.current) {
      const scrollPos = scrollBoxRef.current.scrollLeft;
      const scrollX_ = scrollPos + e.deltaY;
      scrollBoxRef.current.scrollTo({
        top: 0,
        left: scrollX_,
      });
      setScrollX(scrollBoxRef.current.scrollLeft * -1);
      setClientX(scrollBoxRef.current.scrollLeft * -1);
    }
  };

  const onMouseMove = (e) => {
    if (isScrolling) {
      if (scrollBoxRef.current) {
        let scrollX_ = scrollX + e.clientX - clientX;
        setScrollLeft(scrollX_);
        setScrollX(scrollX_);
        let clientX_ = e.clientX;
        setClientX(clientX_);
      }
    }
  };

  const formatSortTournaments = ({ tournaments }) => {
    try {
      const tournaments_ = [...tournaments].map((tournament) => {
        const obj = {
          ...tournament,
          date_range: computeDateRange(
            tournament.tournament_start,
            tournament.tournament_end
          ),
        };

        return obj;
      });

      const sortedFiltered = sortFilterTournaments(today, tournaments_).slice(
        0,
        10
      );

      return sortedFiltered;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const initializeData = ({ tourCode }) => {
    if (!Array.isArray(tournaments[tourCode])) return;
    let tournamentsList = [...tournaments[tourCode]];
    const sortedTournaments = formatSortTournaments({
      tournaments: tournamentsList,
    });
    setUpcomingTournaments(sortedTournaments);
    setScrollLeft(0);
    setInitialized(true);
  };

  const redirectToTournament = ({ tourCode }) => {
    if (!Array.isArray(tournaments[tourCode])) return;
    let tournamentsList = [...tournaments[tourCode]];

    const sortedTournaments = formatSortTournaments({
      tournaments: tournamentsList,
    });

    if (sortedTournaments.length < 1) return;
    let tournamentId = sortedTournaments[0].id;

    history.push(`/tournaments/${tournamentId}`);
  };

  useEffect(() => {
    initializeData({ tourCode: selectedTour.value });

    if (location) {
      let { pathname } = location;

      if (pathname.match(/\/tournaments\/.{8,10}/)) {
        let tournamentId = params?.id;
        let tourCode;

        if (tournamentId) tourCode = tournamentId[0];
        if (tourCode !== selectedTour.value)
          redirectToTournament({ tourCode: selectedTour.value });
      }
    }
  }, [tournaments, selectedTour]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollLeft = scrollLeft * -1;
    }
  }, [scrollLeft]);

  return (
    <ScrollingTickerView
      cells={upcomingTournaments}
      initialized={initialized}
      scrollBoxRef={scrollBoxRef}
      selectedTour={selectedTour}
      tours={tours}
      mouse={{
        mouseDown: onMouseDown,
        mouseUp: onMouseUp,
        mouseMove: onMouseMove,
        mouseWheel: onWheel,
      }}
    />
  );
}
