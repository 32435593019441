import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const catalystNotes = {
  fetchPlayers: async ({ tournamentId, token }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/catalyst_note/players/${tournamentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data } = response;
      if (!data) throw new Error("Error");

      return data;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
  fetchNotes: async ({ players, token }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/catalyst_note`,
        {
          player_ids: players,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data } = response;
      if (!data) throw new Error("Error");

      return data;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
};
