import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/controller";
import ScrollingTicker from "../components/ScollingTicker/controller";
import Footer from "../components/Footer";
import { useAuth } from "../context/AuthProvider";

export default function NotFound() {
  const authContext = useAuth();
  const { isAuthenticated } = authContext;
  return (
    <>
      {isAuthenticated && (
        <div className="pseudo__header">
          <Header />
          <ScrollingTicker />
        </div>
      )}
      <div className="page not__found">
        <div className="dialogue__container">
          <h2>404</h2>
          <h3>Page not found.</h3>
          <p>
            Unfortunately, the page you have requested has either been moved,
            deleted or simply does not exist.
          </p>
          <Link to={isAuthenticated ? "/" : "/login"}>
            Back to {isAuthenticated ? "Home" : "Login"}
          </Link>
        </div>
      </div>
      {isAuthenticated && <Footer />}
    </>
  );
}
