import React, { useRef, useEffect, useState } from "react";
import SponsorshipAgendasView from "./view";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { postSponsorshipAgendas } from "../../../../api/Resources/sponsorship_agendas";
import { useAuth } from "../../../../context/AuthProvider";

export default function SponsorshipAgendasController({ agendas, sectionRef }) {
  let computedState = {
    round1: [],
    round2: [],
    round3: [],
    round4: [],
  };
  const [computedAgendas, setComputedAgendas] = useState(computedState);
  const [currentTab, setCurrentTab] = useState("");
  const [isInitializing, setIsInitializing] = useState(false);

  const authContext = useAuth();
  const { apiKey } = authContext;

  const printRef = useRef();
  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    // Map s3 URLs to agendas
    let files_ = [...agendas];

    for (let i = 0; i < files_.length; i++) {
      let s3URL;

      // Fetch s3URL
      try {
        const { data } = await postSponsorshipAgendas.fetchS3Url({
          uid: files_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      // map over file array with S3URL
      files_[i] = {
        ...files_[i],
        fileURI: files_[i].fileURI.replace(`/${files_[i].id}`, ""),
        roundKey: `round${files_[i].round_id}`,
        s3URI: s3URL,
      };

      if (!Array.isArray(computedState[`round${files_[i].round_id}`])) {
        computedState[`round${files_[i].round_id}`] = [];
      }

      // Organize files into rounds
      computedState[`round${files_[i].round_id}`] = [
        ...computedState[`round${files_[i].round_id}`],
        files_[i],
      ];
    }

    if (isMounted.current) setComputedAgendas(computedState);
    if (isMounted.current) setIsInitializing(false);
  };

  const initializeTabData = () => {
    setCurrentTab(Object.keys(computedAgendas)[0]);
  };

  useEffect(() => {
    if (agendas && Array.isArray(agendas) && agendas.length > 0)
      initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof computedAgendas === "object" && computedAgendas !== null)
      initializeTabData();
  }, [computedAgendas]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SponsorshipAgendasView
      agendas={computedAgendas}
      currentTab={{ value: currentTab, setter: setCurrentTab }}
      initializing={isInitializing}
      printRef={printRef}
      sectionRef={sectionRef}
    />
  );
}
