import React, { useState, useEffect } from "react";
import DashboardHyperlinkView from "./view";
import { useNotification } from "../../../../context/NotificationProvider";
import { staffDashboard } from "../../../../api/AdminSettings/settings";
import { useAuth } from "../../../../context/AuthProvider";

export default function DashboardHyperlinkController() {
  const initialState = {
    url: "",
    label: "",
  };
  const [hyperlink, setHyperlink] = useState(initialState);
  const [initializing, setInitializing] = useState(false);

  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;

  const authContext = useAuth();
  const { token, apiKey } = authContext;

  const validateHyperlink = () => {
    let hyperlink_ = Object.entries(hyperlink);

    try {
      for (let i = 0; i < hyperlink_.length; i++) {
        if (!hyperlink_[i][1]) {
          throw new Error(`${hyperlink_[i][0]} cannot be empty.`);
        }
      }
      return true;
    } catch (error) {
      setNotification({
        message: error.message,
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setDisplayNotification(true);
      return false;
    }
  };

  const submitHyperlink = async () => {
    setInitializing(true);
    let isValid = validateHyperlink();
    if (!isValid) return setInitializing(false);

    try {
      const result = await staffDashboard.postHyperlink({
        url: hyperlink?.url,
        label: hyperlink?.label,
        token: token.idToken.value,
      });

      if (result?.error === true)
        throw new Error("Error updating Staffing Dashboard URL.");

      if (result?.status === 401) {
        throw new Error("Session expired, please login and retry.");
      }

      setInitializing(false);

      setNotification({
        message: "Successfully updated Staffing Dashboard URL.",
        iconName: "check",
        hex: "var(--success-1)",
      });
      setDisplayNotification(true);
    } catch (error) {
      setInitializing(false);
      console.log(error);
      setNotification({
        message: error.message,
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setDisplayNotification(true);
    }
  };

  const updateHyperlink = ({ e, key }) => {
    let hyperlink_ = { ...hyperlink };

    hyperlink_[key] = e.target.value;
    setHyperlink(hyperlink_);
  };

  const hydrateHyperlink = async () => {
    setInitializing(true);
    try {
      const result = await staffDashboard.fetchHyperlink({
        apiKey: apiKey,
      });

      const { label, url } = result?.data;

      if (result?.error === true)
        throw new Error("Error hydrating Staffing Dashboard URL");

      if (result?.status === 401) {
        throw new Error("Session expired, please login and retry.");
      }

      let hyperlink_ = { ...hyperlink };
      hyperlink_["label"] = label;
      hyperlink_["url"] = url;

      setHyperlink(hyperlink_);
      setInitializing(false);
    } catch (error) {
      setNotification({
        message: error?.message || "Error hydrating Staffing Dashboard URL",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setDisplayNotification(true);
      setInitializing(false);
    }
  };

  useEffect(() => {
    if (token?.idToken?.value) hydrateHyperlink();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token?.idToken?.value) hydrateHyperlink();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardHyperlinkView
      hyperlink={{
        setter: updateHyperlink,
        value: hyperlink,
        save: submitHyperlink,
      }}
      initializing={initializing}
    />
  );
}
