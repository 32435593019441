import React from "react";
import { useTournaments } from "../../context/TournamentsProvider";

export default function Logo({ isAlternate }) {
  const { selectedTour } = useTournaments();
  let logoSrc = "";

  const logos = {
    R: {
      def: "/assets/images/PGATOUR-Logo.svg",
      alt: "/assets/images/PGATOUR-Logo-alt.svg",
    },
    S: {
      def: "/assets/images/PGATOURChampions-Logo.svg",
      alt: "/assets/images/PGATOURChampions-Logo-alt.svg",
    },
    H: {
      def: "/assets/images/KornFerryTour-Logo.svg",
      alt: "/assets/images/KornFerryTour-Logo-alt.svg",
    },
  };

  if (!logos[selectedTour.value]) logoSrc = logos["R"];
  if (logos[selectedTour.value]) logoSrc = logos[selectedTour.value];

  return <img src={isAlternate ? logoSrc.alt : logoSrc.def} alt="logo" />;
}
