import React from "react";
import SectionHead from "../../../../../components/SectionHead";
import StaticLink from "../../../../../components/StaticLink";

export default function ImportantLinksHView({ sectionRef }) {
  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>

      <SectionHead title={"Important Links"} />
      <div className="section__body" style={{ padding: 20, height: "100%" }}>
        <div className="links__container">
          {staticLinks.map((link, index) => (
            <StaticLink key={index} url={link.url} title={link.title} />
          ))}
        </div>
      </div>
    </div>
  );
}

const staticLinks = [
  {
    title: "NBCU SHAREPOINT",
    url: "https://nbcuni.sharepoint.com/sites/LiveGolfTournamentGraphics",
  },
];
