export const ToolbarOptions = {
  options: [
    "inline",
    "fontSize",
    "fontFamily",
    "blockType",
    "textAlign",
    "list",
    "link",
    "colorPicker",
    "emoji",
  ],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  link: { inDropdown: true },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      //White
      "rgb(255, 255, 255)",
      //Blacks
      "rgb(0, 0, 0)",
      "rgb(51, 51, 51)",
      "rgb(100, 100, 100)",
      // Reds
      "rgb(186, 48, 48)",
      "rgb(219, 28, 28)",
      "rgb(255, 0, 0)",
      // Oranges
      "rgb(255, 140, 0)",
      "rgb(224, 135, 26)",
      "rgb(249, 180, 59)",
      // Yellows
      "rgb(255, 246, 0)",
      "rgb(221, 212, 44)",
      "rgb(249, 242, 104)",
      // Greens
      "rgb(15, 191, 5)",
      "rgb(68, 237, 59)",
      "rgb(70, 181, 65)",
      // Blues
      "rgb(21, 202, 219)",
      "rgb(21, 123, 219)",
      "rgb(0, 131, 255)",
      // Indigos
      "rgb(80, 0, 255)",
      "rgb(84, 38, 183)",
      "rgb(135, 91, 229)",
      // Violets
      "rgb(165, 12, 242)",
      "rgb(120, 13, 175)",
      "rgb(201, 98, 252)",
    ],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
  },
  fontFamily: {
    options: [
      "Roboto",
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  emoji: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      "😀",
      "😁",
      "😂",
      "😃",
      "😉",
      "😋",
      "😎",
      "😍",
      "😗",
      "🤗",
      "🤔",
      "😣",
      "😫",
      "😴",
      "😌",
      "🤓",
      "😛",
      "😜",
      "😠",
      "😇",
      "😷",
      "😈",
      "👻",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "🙈",
      "🙉",
      "🙊",
      "👼",
      "👮",
      "🕵",
      "💂",
      "👳",
      "🎅",
      "👸",
      "👰",
      "👲",
      "🙍",
      "🙇",
      "🚶",
      "🏃",
      "💃",
      "⛷",
      "🏂",
      "🏌",
      "🏄",
      "🚣",
      "🏊",
      "⛹",
      "🏋",
      "🚴",
      "👫",
      "💪",
      "👈",
      "👉",
      "👉",
      "👆",
      "👇",
      "🖖",
      "🤘",
      "🖐",
      "👌",
      "👍",
      "👎",
      "✊",
      "👊",
      "👏",
      "🙌",
      "🙏",
      "🐵",
      "🐶",
      "🐇",
      "🐥",
      "🐸",
      "🐌",
      "🐛",
      "🐜",
      "🐝",
      "🍉",
      "🍄",
      "🍔",
      "🍤",
      "🍨",
      "🍪",
      "🎂",
      "🍰",
      "🍾",
      "🍷",
      "🍸",
      "🍺",
      "🌍",
      "🚑",
      "⏰",
      "🌙",
      "🌝",
      "🌞",
      "⭐",
      "🌟",
      "🌠",
      "🌨",
      "🌩",
      "⛄",
      "🔥",
      "🎄",
      "🎈",
      "🎉",
      "🎊",
      "🎁",
      "🎗",
      "🏀",
      "🏈",
      "🎲",
      "🔇",
      "🔈",
      "📣",
      "🔔",
      "🎵",
      "🎷",
      "💰",
      "🖊",
      "📅",
      "✅",
      "❎",
      "💯",
    ],
  },
};
