import React from "react";
import ResourcesHView from "./view";

export default function ResourcesHController({
  resources,
  tournamentId,
  sectionRefs,
}) {
  return (
    <ResourcesHView
      sectionRefs={sectionRefs}
      tournamentId={tournamentId}
      resources={resources}
    />
  );
}
