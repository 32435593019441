import React, { useMemo } from "react";
import TournamentDocumentsView from "./view";
import { extractFileNameFromPath, unCamelCase } from "../../../../utility";

export default function TournamentDocumentsController({
  sectionRef,
  crafterTournament,
}) {
  const documents = useMemo(
    () => formatDocuments(crafterTournament),
    [crafterTournament] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function formatRoundDocuments(files) {
    try {
      let roundsData = files;

      const roundLookUp = {
        round1: "R1",
        round2: "R2",
        round3: "R3",
        round4: "R4",
      };

      // // Format round documents
      let sortedRoundDocuments = Object.entries(roundsData)
        .map(([key, value], index) => {
          let formattedKey = roundLookUp[key];
          let formattedDocuments = [];

          for (let i = 0; i < value.length; i++) {
            const type = unCamelCase(value[i].type);
            value[i].files.forEach((file) => {
              const obj = {};

              obj["type"] = `${type} ${formattedKey}`;
              obj["path"] = file;

              formattedDocuments.push(obj);
            });
          }

          const obj = formattedDocuments;
          return obj;
        })
        .sort((a, b) => {
          return a.type - b.type;
        });

      let mergedRoundDocuments = [].concat
        .apply([], sortedRoundDocuments)
        .reverse();

      return mergedRoundDocuments;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  function formatOtherDocuments(files) {
    try {
      const fileTypeLookUp = {
        preTournamentNotesFiles: "Pre-Tournament Notes",
        updatedTournamentFieldFiles: "Updated Tournament Field",
        scorecardFiles: "Score Card",
        gCAAFactsSheetFiles: "GCSAA Fact Sheets",
        factsAndFiguresFiles: "Facts and Figures",
        shotlinkAnalysisFiles: "Shot Link Analysis",
        maintainShotlinkReportsFiles: "Maintain Shotlink Reports",
        proAmInformationFiles: "Pro-Am Information",
        moreinformationFiles: "More Information",
      };

      let oDocuments = [];

      Object.entries(files).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          value.forEach((v) => {
            const obj = {
              type: fileTypeLookUp[key]
                ? fileTypeLookUp[key]
                : unCamelCase(key),
              path: v,
            };
            oDocuments = [...oDocuments, obj];
          });
        } else {
          const obj = {
            type: fileTypeLookUp[key] ? fileTypeLookUp[key] : unCamelCase(key),
            path: value,
          };
          oDocuments = [...oDocuments, obj];
        }
      });

      // Renate weather file labels
      oDocuments = oDocuments.map((document) => {
        if (document.type.toLowerCase() === "weather") {
          const fileName = extractFileNameFromPath(
            document.path.toLowerCase()
          )[0];

          const newDocument = {
            ...document,
            type: `Weather ${fileName}`,
          };

          return newDocument;
        } else {
          return document;
        }
      });

      // Sort documents
      oDocuments = [...oDocuments].sort((a, b) => {
        return a.type - b.type;
      });

      return oDocuments;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  function formatDocuments(crafterTournament) {
    if (!crafterTournament) return [];
    let { rounds, otherInformation } = crafterTournament;
    let roundDocuments = [],
      otherDocuments = [];

    if (rounds) roundDocuments = formatRoundDocuments(rounds);
    if (otherInformation)
      otherDocuments = formatOtherDocuments(otherInformation);

    return [...roundDocuments, ...otherDocuments];
  }

  return (
    <TournamentDocumentsView sectionRef={sectionRef} documents={documents} />
  );
}
