import React, { useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import { nanoid } from "nanoid";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";

export default function Login() {
  const authContext = useAuth();
  const { token, login } = authContext;

  useEffect(() => {
    if (token.state.value === null || token.nonce.value === null) {
      const nonce_ = token.nonce.value || nanoid(32);
      const state_ = token.state.value || nanoid(32);

      token.state.setter(state_);
      token.nonce.setter(nonce_);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="page login">
      <div className="login__window">
        <div className="logo__container">
          <div className="login__logo">
            <div className="logo">
              <Logo isAlternate={true} />
            </div>
          </div>
          <Icon name="lock" />
        </div>
        <div className="welcome__container">
          <button
            onClick={
              token.nonce.value && token.state.value
                ? () =>
                    login({
                      state: token.state.value,
                      nonce: token.nonce.value,
                    })
                : () => null
            }
          >
            <Icon name="sign in" /> Sign in as PGA TOUR Talent or Staff
          </button>
          <span className="prompt">
            Please&nbsp;
            <span
              onClick={
                token.nonce.value && token.state.value
                  ? () =>
                      login({
                        state: token.state.value,
                        nonce: token.nonce.value,
                      })
                  : () => null
              }
              className="link"
            >
              sign in
            </span>
            &nbsp;to continue to the application. (SSO)
          </span>
        </div>
        <div className="welcome__redirect">
          <Link to="/">
            <Icon name="arrow left" />
            <span>back to home page</span>
          </Link>
        </div>
      </div>

      <div className="copyright">
        <p>
          &copy; {year} PGA TOUR INC. All Rights Reserved. | 100 PGA TOUR BLVD,
          PONTE VEDRA BEACH, FL 32086 |{" "}
          <a href="https://pgatourmedia.pgatourhq.com/">MEDIA WEBSITE</a> |{" "}
          <a href="https://www.pgatour.com/company/contact-us.html">
            CONTACT US
          </a>
        </p>
      </div>
    </div>
  );
}
