import React, { useState, useEffect, useRef } from "react";
import CatalystNotesView from "./view";
import { catalystNotes } from "../../../../api/Resources/catalyst_notes";
import { useReactToPrint } from "react-to-print";
import { useNotification } from "../../../../context/NotificationProvider";
import { useAuth } from "../../../../context/AuthProvider";
import { useIsMounted } from "../../../../hooks/useIsMounted";

export default function CatalystNotesController({ sectionRef, tournamentId }) {
  const selectorsState = {
    currentTab: "",
    tabs: [],
    page: 1,
  };
  const [playerGroupings, setPlayerGroupings] = useState([]);
  const [selectedGrouping, setSelectedGrouping] = useState(null);
  const [selectors, setSelectors] = useState(selectorsState);

  let isMounted = useIsMounted();

  const notificationContext = useNotification();
  const { setDisplayNotification, setNotification } = notificationContext;

  const authContext = useAuth();
  const { logout, token, isStaff, isAdmin, isTalent } = authContext;

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const fetchPlayerGroupings = async () => {
    if (isStaff || isAdmin || isTalent) {
      const result = await catalystNotes.fetchPlayers({
        tournamentId: tournamentId,
        token: token.idToken.value,
      });

      if (result.status === 401) {
        logout();
        setNotification({
          message: "Session has expired. You have been logged out.",
          iconName: "ban",
          hex: "var(--danger-1)",
        });

        return setDisplayNotification(true);
      }

      if (result.error === true) {
        return setPlayerGroupings(null);
      }

      if (Array.isArray(result) && result.length === 0 && isMounted.current)
        return setPlayerGroupings(undefined);

      if (isMounted.current) setPlayerGroupings(result);
    }
  };

  const initializeTabs = () => {
    const selectorsState_ = {
      ...selectorsState,
      tabs: playerGroupings.map((grouping) => grouping.round),
      currentTab: playerGroupings.map((grouping) => grouping.round)[0],
    };

    if (isMounted.current) setSelectors(selectorsState_);
  };

  const selectTab = (tab) => {
    const selectorsState_ = {
      ...selectors,
      currentTab: tab,
      page: 1,
    };

    if (isMounted.current) setSelectors(selectorsState_);
  };

  const selectGrouping = ({ players, group_number }) => {
    setSelectedGrouping({
      group_number: group_number,
      players: players,
    });
    const selectorsState_ = { ...selectors, page: 2 };
    if (isMounted.current) setSelectors(selectorsState_);
  };

  const backToGroupings = () => {
    const selectorsState_ = { ...selectors, page: 1 };
    if (isMounted.current) setSelectors(selectorsState_);
  };

  useEffect(() => {
    if (
      tournamentId &&
      tournamentId !== null &&
      tournamentId !== undefined &&
      (isStaff || isAdmin || isTalent)
    )
      fetchPlayerGroupings();
  }, [tournamentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(playerGroupings) && playerGroupings.length > 0)
      initializeTabs();
  }, [playerGroupings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CatalystNotesView
      sectionRef={sectionRef}
      playerGroupings={playerGroupings}
      selectors={selectors}
      selectTab={selectTab}
      printRef={printRef}
      groupingActions={{
        select: selectGrouping,
        back: backToGroupings,
        value: selectedGrouping,
      }}
      selectGrouping={selectGrouping}
      backToGroupings={backToGroupings}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
    />
  );
}
