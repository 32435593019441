import React from "react";
import Tooltip from "../../../../../components/Tooltip";
import { Icon, Loader } from "semantic-ui-react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToolbarOptions } from "../../../../../data";
import draftToHtml from "draftjs-to-html";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import RichTextEditorError from "../../../../../components/RichTextEditorError";

export default function SponsorReadsView({
  sponsorReads,
  initializing,
  sectionRef,
  submitSponsorReads,
  setSponsorReads,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section scripts">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            Sponsorship Reads
          </label>
          <button className="save__btn" onClick={() => submitSponsorReads()}>
            <Icon name="save" />
            <span>Save Updates</span>
          </button>
          {initializing && (
            <span
              className="initializing__loader"
              style={{ display: "block", marginTop: 20 }}
            >
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col dbl__full">
          <ErrorBoundary
            fallback={
              <RichTextEditorError
                handleReset={() => submitSponsorReads(" ")}
              />
            }
          >
            {typeof sponsorReads === "object" &&
              sponsorReads !== null &&
              Object.values(sponsorReads).map((read, index) => (
                <div className="section__input" key={index}>
                  <label className="input__label">
                    {read.round.label}
                    <br />
                    <Tooltip
                      label={"Text only"}
                      tip={
                        "Please do not insert or paste images, files or anything other than rich-text."
                      }
                    />
                  </label>
                  <Editor
                    toolbar={ToolbarOptions}
                    editorState={
                      read.value.raw === ""
                        ? EditorState.createEmpty()
                        : read.value.draft
                    }
                    onEditorStateChange={(editorState) => {
                      const obj = {
                        raw: draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        ),
                        draft: editorState,
                      };
                      setSponsorReads({
                        obj: obj,
                        roundId: read.round.key,
                      });
                    }}
                  />
                </div>
              ))}
          </ErrorBoundary>
        </div>
      </div>
    </form>
  );
}
