import React from "react";
import SectionHead from "../../../../components/SectionHead";
import parse from "html-react-parser";
import EmptyDialogue from "../../../../components/EmptyDialogue";

export default function DailySchedulePGATEView({
  method,
  schedule,
  printRef,
  sectionRef,
}) {
  return (
    <div ref={printRef} className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Daily Schedule (PGATE)"} method={method} />

      <div className="print__title">
        <h4>Daily Schedule (PGATE)</h4>
      </div>
      <div className="section__body" style={{ position: "relative" }}>
        {schedule && typeof schedule === "object" && schedule !== null ? (
          <>
            {schedule.content !== null ? (
              <div className="rich__text" style={{ position: "relative" }}>
                {parse(schedule.content)}
              </div>
            ) : (
              <EmptyDialogue />
            )}
          </>
        ) : (
          <div className="empty__content">
            <EmptyDialogue />
          </div>
        )}
      </div>
    </div>
  );
}
