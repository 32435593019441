import React from "react";
import { Loader } from "semantic-ui-react";
import Logo from "../Logo";

export default function Preloader({ message, position, zIndex }) {
  return (
    <div
      className="preloader"
      style={{
        position: position.pos,
        top: position.top,
        left: position.left,
        zIndex: zIndex,
      }}
    >
      <div className="dialogue">
        <div className="logo__container">
          <Logo isAlternate={true} />
        </div>
        <p>{message}</p>
        <Loader active inline />
      </div>
    </div>
  );
}
