import React, { useState, useEffect } from "react";
import DailySchedule from "./view";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { postDailySchedule } from "../../../../api/Resources/daily_schedule";
import { evaluateTokenExpiry } from "../../../../api";
import { useNotification } from "../../../../context/NotificationProvider";
import { useAuth } from "../../../../context/AuthProvider";
import ErrorBoundary from "../../../../components/ErrorBoundary";

export default function DailyScheduleController({
  tournamentId,
  schedule,
  sectionRef,
}) {
  const richTextState = {
    raw: "",
    draft: EditorState.createEmpty(),
  };
  const [dailySchedule, setDailySchedule] = useState(richTextState);
  const [isInitializing, setIsInitializing] = useState(false);
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;
  const authContext = useAuth();
  const { logout, token } = authContext;

  const inputs = [
    {
      label: "Daily Schedule (On Site)",
      key: "daily_schedule",
      type: "text",
      required: false,
      setter: (obj) => {
        let obj_ = { ...obj };
        setDailySchedule(obj_);
      },
      value: dailySchedule,
    },
  ];

  const initializeData = () => {
    if (!schedule?.content) return;
    const contentBlock = htmlToDraft(schedule.content);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      const obj = {
        ...dailySchedule,
        raw: schedule.content,
        draft: !schedule.content ? EditorState.createEmpty() : editorState,
      };

      setDailySchedule(obj);
    }
  };

  useEffect(() => {
    initializeData();
  }, [schedule]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDailySchedule = async (overrideContent) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    setIsInitializing(true);
    try {
      const result = await postDailySchedule({
        tournamentId: tournamentId,
        content: overrideContent ? overrideContent : dailySchedule.raw,
        token: token.idToken.value,
      });

      if (result.status === 401) {
        logout();
        setNotification({
          message: "Authentication error. Please login.",
          iconName: "ban",
          hex: "var(--danger-1)",
        });

        return setDisplayNotification(true);
      }

      if (result.error === true) throw new Error("Error");
      setNotification({
        message: "Successfully updated the Daily Schedule (On Site)!",
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);
    } catch (error) {
      setNotification({
        message: "Could not update the Daily Schedule (On Site)!",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setDisplayNotification(true);
      console.log(error);
    }

    setIsInitializing(false);
  };

  return (
    <ErrorBoundary fallback={null}>
      <DailySchedule
        inputs={inputs}
        submitDailySchedule={submitDailySchedule}
        sectionRef={sectionRef}
        initializing={isInitializing}
      />
    </ErrorBoundary>
  );
}
