import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postStreamDetails = async ({ tournamentId, content, token }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/stream_detail`,
      {
        tournament_id: tournamentId,
        content: content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};
