import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "./styles/main.scss";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ScreenProvider } from "./context/ScreenProvider";
import ScrollTop from "./hooks/useScrollTop";
import { AuthProvider } from "./context/AuthProvider";
import { TournamentsProvider } from "./context/TournamentsProvider";
import { NotificationProvider } from "./context/NotificationProvider";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <AuthProvider>
        <NotificationProvider>
          <TournamentsProvider>
            <ScreenProvider>
              <ScrollTop>
                <App />
              </ScrollTop>
            </ScreenProvider>
          </TournamentsProvider>
        </NotificationProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
