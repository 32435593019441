import React, { useRef, useState, useEffect } from "react";
import DotComLowerThirdsView from "./view";
import { useReactToPrint } from "react-to-print";

export default function DotComLowerThirdsController({ sectionRef, promos }) {
  const [computedPromos, setComputedPromos] = useState([]);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const computePromos = () => {
    const sorted = [...promos].sort((a, b) => {
      // parse unix time to js date.
      return a.position - b.position;
    });

    setComputedPromos(sorted);
  };

  useEffect(() => {
    if (Array.isArray(promos) && promos.length > 0) computePromos();
  }, [promos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DotComLowerThirdsView
      promos={computedPromos}
      printRef={printRef}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
      sectionRef={sectionRef}
    />
  );
}
