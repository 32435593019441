import React from "react";

export default function SelectorsView({
  selectors,
  selectTab,
  mouse,
  scrollBoxRef,
}) {
  return (
    <div className="tab__selectors" style={{ minHeight: 57 }}>
      <div className="selectors__flex">
        <div
          className="selectors__container"
          ref={scrollBoxRef}
          onMouseDown={(e) => mouse.mouseDown(e)}
          onMouseUp={(e) => mouse.mouseUp(e)}
          onMouseMove={(e) => mouse.mouseMove(e)}
          onMouseLeave={(e) => mouse.mouseUp(e)}
          onWheel={(e) => mouse.mouseWheel(e)}
        >
          {selectors.tabs.map((tab, index) => (
            <button
              key={index}
              value={`Round ${tab}`}
              onClick={() => selectTab(tab)}
              className={`btn tab ${
                tab === selectors.currentTab ? "active" : ""
              }`}
            >{`Round ${tab}`}</button>
          ))}
        </div>
      </div>
    </div>
  );
}
