import React from "react";
import { Icon, Dropdown } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function TournamentsListToolbar({
  datePickerData,
  search,
  tourFilter,
}) {
  return (
    <div className="tournaments__toolbar">
      <div className="toolbar__search">
        <span className="label">Search</span>
        <div className="search__bar">
          <div className="bar">
            <Icon name="search" />

            <input
              type="text"
              value={search.value}
              onChange={(e) => search.setter(e.target.value)}
              placeholder="Search by Tournament Title"
            />
            {search.value !== "" && (
              <button onClick={() => search.setter("")} className="clear">
                <Icon name="close" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="toolbar__tour">
        <span className="label">Tour</span>
        <Dropdown
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            postition: "relative",
          }}
          defaultValue={tourFilter?.value || "R"}
          onChange={(_, data) => tourFilter.setter(data.value)}
          placeholder="All"
          fluid
          selection
          options={[
            {
              key: "R",
              text: "PGA TOUR",
              value: "R",
            },
            {
              key: "H",
              text: "KORN FERRY TOUR",
              value: "H",
            },
            {
              key: "S",
              text: "PGA TOUR CHAMPIONS",
              value: "S",
            },
          ]}
        />
      </div>
      <div className="toolbar__dates">
        <span className="label">Filter By Date</span>
        <div className="pickers">
          <div className="date__picker">
            <Icon name="calendar outline" />
            <DatePicker
              selected={datePickerData.start.value}
              onChange={(date) => datePickerData.start.setter(date)}
              selectsStart
              startDate={datePickerData.start.value}
              endDate={datePickerData.end.value}
            />
            {datePickerData.start.value.getTime() !==
              datePickerData.today.getTime() && (
              <button
                onClick={() =>
                  datePickerData.start.setter(datePickerData.today)
                }
                className="clear"
              >
                <Icon name="close" />
              </button>
            )}
          </div>
          <div className="date__picker">
            <Icon name="calendar outline" />
            <DatePicker
              selected={datePickerData.end.value}
              onChange={(date) => datePickerData.end.setter(date)}
              selectsEnd
              startDate={datePickerData.start.value}
              endDate={datePickerData.end.value}
              minDate={datePickerData.start.value}
            />
            {datePickerData.end.value !== null && (
              <button
                className="clear"
                onClick={() => datePickerData.end.setter(null)}
              >
                <Icon name="close" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
