import React from "react";
import { Loader } from "semantic-ui-react";

export default function PreloaderOverlay({ message }) {
  return (
    <div className="preloader__overlay">
      <div className="preloader__overlay__content">
        <span>
          <Loader active inline size="mini" />
        </span>{" "}
        &nbsp;
        <span>{message}</span>
      </div>
    </div>
  );
}
