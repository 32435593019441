import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import Logo from "../Logo";

export default withRouter(function Header({
  location,
  menu,
  profile,
  isAdmin,
  isStaff,
  adminLinks,
  logout,
  staffingDashboardLink,
}) {
  return (
    <>
      {(isAdmin || isStaff) && (
        <div className="admin__header">
          <div className="container">
            {profile ? (
              <span className="admin__greeting">
                Welcome, <span>{profile?.preferred_username}</span> &nbsp;
              </span>
            ) : (
              <span className="admin__greeting"></span>
            )}
            <div className="admin__menu" style={{ position: "relative" }}>
              <button className="admin__links__toggle">
                <Icon name="ellipsis horizontal" />
              </button>
              <div className="admin__links">
                {adminLinks.map((link, index) => (
                  <Link key={index} to={link.metaData.path}>
                    {link?.label?.icon ? (
                      <span>
                        <Icon name={link.label.icon} />
                      </span>
                    ) : (
                      <span className="link__icon">
                        <Icon name="arrow right" />
                      </span>
                    )}

                    <span className="label">{link.label.text}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <header className={`header__main`}>
        <div className="header__wrap">
          <div className="header__flex">
            <Link to="/" className="header__logo">
              <div className="logo">
                <Logo />
              </div>
            </Link>
            <div className="menu__container__mobile" tabIndex="0">
              <div
                className={`menu__icon ${menu.status ? "active" : ""}`}
                onClick={() => {
                  menu.toggle(!menu.status);
                }}
              >
                <div className="hamburger"></div>
              </div>
            </div>
            <div className={`menu__container ${menu.status ? "active" : ""}`}>
              <div style={{ width: "100%" }}>
                <ul className="header__menu menu">
                  <li className="item">
                    <Link to={"/"}>Home</Link>
                    <div
                      className={`active__indicator ${
                        "/" === location.pathname ? "active" : ""
                      }`}
                    ></div>
                  </li>
                  <li className="item">
                    <a
                      href={"https://www.pgatour.com/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PGATOUR.com
                    </a>
                    <div className="active__indicator external">
                      <Icon name="chevron right" />
                    </div>
                  </li>
                  {(isStaff || isAdmin) &&
                    staffingDashboardLink.url &&
                    staffingDashboardLink.label && (
                      <li className="item">
                        <a
                          href={staffingDashboardLink.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {staffingDashboardLink.label}
                        </a>
                        <div className="active__indicator external">
                          <Icon name="chevron right" />
                        </div>
                      </li>
                    )}
                  {profile && Object.keys(profile).length > 0 ? (
                    <li className="has__child item">
                      <button>
                        <div className="label">
                          <span className="item__icon">
                            <Icon name="user circle" />
                          </span>
                          <span>{profile?.preferred_username}</span>{" "}
                        </div>
                      </button>
                      <div className="active__indicator"></div>
                      <ul>
                        <li>
                          <button onClick={() => logout()}>
                            <span className="label">
                              <span className="item__icon">
                                <Icon name={"sign out"} />
                              </span>
                              Logout
                            </span>
                          </button>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li>
                      <Link to={"/login"}>
                        {" "}
                        <div className="label">
                          <span className="item__icon">
                            <Icon name="user circle" style={{ fontSize: 14 }} />
                          </span>
                          <span>Sign In</span>{" "}
                        </div>
                      </Link>
                      <div
                        className={`active__indicator ${
                          "/login" === location.pathname ? "active" : ""
                        }`}
                      ></div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
});
