import axios from "axios";

export const evaluateTokenExpiry = () => {
  const tokenGenesis = JSON.parse(
    localStorage.getItem("PGATL-tokenGenerationTime")
  );
  const tokenExpiry = JSON.parse(localStorage.getItem("PGATL-tokenExpiry"));

  if (!tokenGenesis || !tokenExpiry) return true;

  const currentTime = new Date().getTime() / 1000;
  const expireTime = tokenGenesis + parseInt(tokenExpiry);

  return currentTime > expireTime;
};

// ============================================================================
// FETCH FILE
// ============================================================================

export const fetchFile = async ({ url, name }) => {
  try {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    });

    return new File([response.data], name);
  } catch (error) {
    console.log(error);
    return {
      error: true,
    };
  }
};
