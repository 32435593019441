import React, { useEffect, useState, useRef, useMemo } from "react";
import TournamentExplorerView from "./view";
import { computeDateRange, sortFilterTournaments } from "../../utility";
import { useScreen } from "../../context/ScreenProvider";
import { useTournaments } from "../../context/TournamentsProvider";

const today = new Date();
export default function TournamentExplorerController() {
  const { screenWidth } = useScreen();
  const { tournaments, fetchTournaments, selectedTour } = useTournaments();
  // SELECTED AND CURRENT TOURNAMENT
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [currentTournament, setCurrentTournament] = useState(null);
  // TOURNAMENT LIST
  const tournamentsList = useMemo(
    () => computeTournamentsList(tournaments),
    [tournaments, selectedTour] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const previewRef = useRef();
  function computeTournamentsList(tournaments) {
    if (!tournaments || !selectedTour?.value) return [];
    let tournamentsList_ = tournaments[selectedTour?.value];
    const computed = [...tournamentsList_].map((tournament) => {
      const obj = {
        ...tournament,
        date_range: computeDateRange(
          tournament.tournament_start,
          tournament.tournament_end
        ),
      };

      return obj;
    });

    return computed;
  }

  const selectTournament = (id) => {
    let computed = computeTournamentsList(tournaments);
    const foundTournament = [...computed].filter(
      (tournament) => tournament.id === id
    );

    if (!foundTournament || foundTournament.length < 1) return;
    setSelectedTournament(foundTournament[0]);
  };

  const scrollToPreview = () => {
    if (!previewRef || !previewRef.current) return;
    if (screenWidth > 1000) return;
    previewRef.current.scrollIntoView();
  };

  useEffect(() => {
    scrollToPreview();
  }, [selectedTournament]); // eslint-disable-line react-hooks/exhaustive-deps

  // refresh tournaments on component mount
  useEffect(() => {
    fetchTournaments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filtered = sortFilterTournaments(today, tournamentsList);
    setSelectedTournament(filtered[0]);
    setCurrentTournament(filtered[0]);
  }, [tournamentsList]);

  return (
    <TournamentExplorerView
      selectTournament={{
        select: selectTournament,
        selected: selectedTournament,
        current: currentTournament,
      }}
      tournaments={tournamentsList}
      previewRef={previewRef}
      selectedTour={selectedTour}
    />
  );
}
