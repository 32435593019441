import React, { useState, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { capitalize } from "../../../../utility";

export default function ResourceNavView({ sections, scrollToSection }) {
  const [menuActive, setMenuActive] = useState(false);
  const sectionCategories = useMemo(() => {
    let categories = {};

    Object.values(sections).forEach((section) => {
      let category = section.tab || "general";
      if (!(category in categories)) categories[category] = [];
      categories[category].push(section);
    });

    return categories;
  }, [sections]);

  return (
    <div className="resource__nav">
      <nav className={`${menuActive ? "active" : "default"}`}>
        <h2>Table of Contents</h2>
        <div className="contents">
          {sectionCategories &&
            Object.keys(sectionCategories).length > 0 &&
            Object.entries(sectionCategories).map(
              ([title, sections], index) => (
                <React.Fragment key={`${title}-${index}`}>
                  {Array.isArray(sections) && sections.length > 0 && (
                    <div className="section">
                      <h3>{capitalize(title)}</h3>
                      <ul>
                        {sections.map((section, index) => (
                          <li key={index}>
                            <button
                              onClick={() => {
                                scrollToSection({
                                  tab: section.tab,
                                  ref: section.ref,
                                });

                                setMenuActive(false);
                              }}
                            >
                              {section.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </React.Fragment>
              )
            )}
        </div>
      </nav>
      <button className="toggle" onClick={() => setMenuActive(!menuActive)}>
        <Icon name={`${menuActive ? "x" : "list ul"}`} />
      </button>
    </div>
  );
}
