import React, { useState, useEffect } from "react";
import { Icon, Loader, Table, Dropdown } from "semantic-ui-react";
import { snaketoTitleCase } from "../../../../utility";
import EmptyDialogue from "../../../../components/EmptyDialogue";

export default function PersonnelView({
  initializing,
  sectionRef,
  addRow,
  deleteRow,
  rows,
  positions,
  emptyRow,
  handleOnChange,
  loadingRows,
  bulkPost,
}) {
  const exclude_cols = ["id", "tournament_id"];
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section uploads" style={{ flex: 1 }}>
        <div className="form__section__col" style={{ width: 181 }}>
          <label style={{ textTransform: "uppercase" }}>
            Tournament Personnel
          </label>

          {initializing && (
            <span
              className="initializing__loader"
              style={{ marginTop: 20, display: "block" }}
            >
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div
          className="form__section__col full"
          style={{
            width: "100%",
            minHeight: 419,
            position: "relative",
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "calc(100% - 45px)",
              overflow: "auto",
              position: "absolute",
              top: 0,
              left: 0,
              border: "1px solid #ddd",
              padding: "-1px -1px -1px -1px",
              borderRadius: 5,
              background: "#f7f7f7",
            }}
          >
            <Table>
              <Table.Header>
                <Table.Row style={{ position: "sticky", top: 0, zIndex: 501 }}>
                  <Table.HeaderCell
                    style={{
                      textAlign: "center",
                      position: "sticky",
                      left: 0,
                      background: "#f9fafb",
                      zIndex: 500,
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    &nbsp;
                  </Table.HeaderCell>
                  {emptyRow &&
                    Object.keys(emptyRow)
                      .filter((heading) => !exclude_cols.includes(heading))
                      .map((heading, index) => (
                        <Table.HeaderCell key={index}>
                          <label
                            className="input__label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {snaketoTitleCase(heading)}
                          </label>
                        </Table.HeaderCell>
                      ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Array.isArray(rows) && (
                  <>
                    {rows.length > 0 ? (
                      rows.map((row, index) => (
                        <Table.Row key={index}>
                          <Table.Cell
                            key={index}
                            style={{
                              textAlign: "center",
                              position: "sticky",
                              left: 0,
                              background: "white",
                              zIndex: 500,
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {loadingRows.includes(row.id) ? (
                              <Loader active inline size="mini" />
                            ) : (
                              <button
                                className="base"
                                onClick={() => deleteRow(row.id)}
                              >
                                <Icon name="trash alternate" />
                              </button>
                            )}
                          </Table.Cell>
                          {Object.keys(row)
                            .filter((cell) => !exclude_cols.includes(cell))
                            .map((cell, index) => (
                              <Table.Cell key={index} style={{ minWidth: 250 }}>
                                <PersonnelCell
                                  row={row}
                                  cell={cell}
                                  positions={positions}
                                  handleOnChange={handleOnChange}
                                />
                              </Table.Cell>
                            ))}
                        </Table.Row>
                      ))
                    ) : (
                      <EmptyDialogue
                        message={{
                          title: "Empty Data",
                        }}
                      />
                    )}
                  </>
                )}
                <Table.Row></Table.Row>
              </Table.Body>
            </Table>
          </div>

          <br />
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button className="base" onClick={addRow} style={{ fontSize: 14 }}>
              + Add Personnel
            </button>
            <button
              className="save__btn"
              style={{ display: "inline-block", width: 150 }}
              onClick={bulkPost}
            >
              <Icon name="save" />
              <span>Save Updates</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export function PersonnelCell({ row, cell, positions, handleOnChange }) {
  const [toggleFreeText, setToggleFreeText] = useState(false);

  useEffect(() => {
    if (
      (!!row[cell] && !positions.includes(row[cell])) ||
      (!!row[cell] && row[cell] === "Other")
    )
      setToggleFreeText(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch (cell) {
    case "personnel_position":
      return (
        <>
          {toggleFreeText ? (
            <div
              class="field__set"
              style={{ position: "relative", paddingRight: 35 }}
            >
              <input
                type="text"
                name={cell}
                placeholder=""
                value={row[cell] || ""}
                style={{ margin: 0 }}
                onChange={(e) =>
                  handleOnChange({
                    rowId: row.id,
                    key: cell,
                    value: e.target.value,
                  })
                }
              />

              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "0",
                  transform: "translateY(-50%)",
                  fontSize: 12,
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOnChange({
                    rowId: row.id,
                    key: cell,
                    value: "",
                  });

                  setToggleFreeText(false);
                }}
              >
                <Icon name="eraser" />
              </button>
            </div>
          ) : (
            <Dropdown
              placeholder="Select Position"
              fluid
              selection
              options={positions.map((position) => ({
                key: position,
                text: position,
                value: position,
              }))}
              defaultValue={row[cell]}
              onChange={(_, data) => {
                handleOnChange({
                  rowId: row.id,
                  key: cell,
                  value: data.value,
                });
                if (data.value === "Other") setToggleFreeText(true);
              }}
            />
          )}
        </>
      );
    default:
      return (
        <div class="field__set">
          <input
            type="text"
            name={cell}
            placeholder=""
            value={row[cell] || ""}
            onChange={(e) =>
              handleOnChange({
                rowId: row.id,
                key: cell,
                value: e.target.value,
              })
            }
          />
        </div>
      );
  }
}
