import React from "react";
import DashboardHyperlink from "./Components/DashboardHyperlink/controller";
import Slideshow from "./Components/Slideshow/controller";

export default function StaffDashboardView() {
  return (
    <div className="staff__dashboard">
      <DashboardHyperlink />
      <Slideshow />
    </div>
  );
}
