import React from "react";
import DashboardSidebarView from "./view";

export default function DashboardSidebarController() {
  const links = [
    {
      metaData: {
        type: "link",
        isExternal: false,
        path: "/dashboard/tournaments",
      },
      label: {
        text: "Tournaments",
        icon: "trophy",
      },
    },
    {
      metaData: {
        type: "link",
        isExternal: false,
        path: "/dashboard/settings",
      },
      label: {
        text: "Settings",
        icon: "cog",
      },
    },
    // {
    //   metaData: {
    //     type: "link",
    //     isExternal: false,
    //     path: "/dashboard/schedules",
    //   },
    //   label: {
    //     text: "Staffing Schedules",
    //     icon: "calendar outline",
    //   },
    // },
    // {
    //   metaData: {
    //     type: "link",
    //     isExternal: false,
    //     path: "/dashboard/personnel",
    //   },
    //   label: {
    //     text: "Personnel",
    //     icon: "address card outline",
    //   },
    // },
  ];

  return <DashboardSidebarView links={links} />;
}
