import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Icon, Loader } from "semantic-ui-react";
import { ToolbarOptions } from "../../../../../data";
import Tooltip from "../../../../../components/Tooltip";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import RichTextEditorError from "../../../../../components/RichTextEditorError";

export default function ScriptsView({
  scripts,
  setScripts,
  submitScripts,
  initializing,
  sectionRef,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section scripts">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>Scripts</label>
          <button className="save__btn" onClick={() => submitScripts()}>
            <Icon name="save" />
            <span>Save Updates</span>
          </button>

          {initializing && (
            <span
              className="initializing__loader"
              style={{ display: "block", marginTop: 20 }}
            >
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col dbl__full">
          <ErrorBoundary
            fallback={
              <RichTextEditorError handleReset={() => submitScripts(" ")} />
            }
          >
            {typeof scripts === "object" &&
              scripts !== null &&
              Object.values(scripts).map((script, index) => (
                <div className="section__input" key={index}>
                  <label className="input__label">
                    {script.script.label} <br />
                    <Tooltip
                      label={"Text only"}
                      tip={
                        "Please do not insert or paste images, files or anything other than rich-text."
                      }
                    />
                  </label>

                  <Editor
                    toolbar={ToolbarOptions}
                    editorState={
                      script.value.raw === ""
                        ? EditorState.createEmpty()
                        : script.value.draft
                    }
                    onEditorStateChange={(editorState) => {
                      const obj = {
                        raw: draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        ),
                        draft: editorState,
                      };
                      setScripts({ obj: obj, scriptId: script.script.key });
                    }}
                  />
                </div>
              ))}
          </ErrorBoundary>
        </div>
      </div>
    </form>
  );
}
