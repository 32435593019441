import React, { useEffect, useState } from "react";
import CameraAssignmentsView from "./view";
import { postCameraAssignments } from "../../../../api/Resources/camera_assignments";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { useAuth } from "../../../../context/AuthProvider";

export default function CameraAssignmentsController({
  cameraAssignments,
  sectionRef,
}) {
  const [isInitializing, setIsInitializing] = useState(false);
  const [computedCameraAssignments, setComputedCameraAssignments] = useState(
    []
  );

  const authContext = useAuth();
  const { apiKey } = authContext;

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let assignments_ = [...cameraAssignments];

    for (let i = 0; i < assignments_.length; i++) {
      let s3URL;
      try {
        const { data } = await postCameraAssignments.fetchS3Url({
          uid: assignments_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      assignments_[i] = {
        ...assignments_[i],
        fileURI: assignments_[i].fileURI.replace(`/${assignments_[i].id}`, ""),
        s3URI: s3URL,
      };
    }
    if (isMounted.current) setIsInitializing(false);
    if (isMounted.current) setComputedCameraAssignments(assignments_);
  };

  useEffect(() => {
    if (Array.isArray(cameraAssignments) && cameraAssignments.length > 0)
      initializeData();
  }, [cameraAssignments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CameraAssignmentsView
      cameraAssignments={computedCameraAssignments}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
