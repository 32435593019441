import React from "react";
import { Icon } from "semantic-ui-react";

export default function RichTextEditorError({ handleReset }) {
  return (
    <div className="rich-text-error">
      <h5>ERROR</h5>
      <p>
        <Icon name="exclamation triangle" />
        The content in this rich text editor is corrupted. Please reset the
        content and reload the page.
      </p>
      <button className="default" onClick={handleReset ? handleReset : null}>
        <Icon name="refresh" />
        Reset Content
      </button>
    </div>
  );
}
