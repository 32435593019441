import React, { useState, useEffect } from "react";
import MemorandumsLinkView from "./view";
import {
  deleteMemorandumLink,
  postMemorandumLink,
} from "../../../../api/Resources/memorandums";
import { evaluateTokenExpiry } from "../../../../api";
import { useNotification } from "../../../../context/NotificationProvider";
import { useAuth } from "../../../../context/AuthProvider";
import { v4 as uuid } from "uuid";

export default function MemorandumsLinkController({ tournamentId, links }) {
  const initialLinkData = {
    url: "",
    label: "",
    loading: false,
  };
  const [resourceLinks, setResourceLinks] = useState([]);
  const [linkData, setLinkData] = useState(initialLinkData);
  const [isLoading, setIsLoading] = useState(false);

  const { logout, token } = useAuth();
  const { setNotification, setDisplayNotification } = useNotification();

  const handleLinkData = (e) => {
    let { name, value } = e.target,
      linkData_ = { ...linkData };

    linkData_[name] = value;

    setLinkData(linkData_);
  };

  const submitLink = async () => {
    const tokenIsExpired = evaluateTokenExpiry();
    if (tokenIsExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    setIsLoading(true);
    if (!linkData?.url || !linkData.label) {
      setNotification({
        message: "Please fill in the required fields.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      return setDisplayNotification(true);
    }
    try {
      let link = {
        ...linkData,
        id: uuid(),
      };

      const result = await postMemorandumLink({
        ...link,
        tournamentId,
        token: token.idToken.value,
      });

      if (result.status === 401) {
        logout();
        setNotification({
          message: "Authentication error. Please login.",
          iconName: "ban",
          hex: "var(--danger-1)",
        });

        return setDisplayNotification(true);
      }

      if (result.error === true) throw new Error("Error");

      setNotification({
        message: "Successfully saved the link!",
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);
      setResourceLinks([...resourceLinks, link]);
      setLinkData(initialLinkData);
    } catch (error) {
      setNotification({
        message: "Could not save the link.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setDisplayNotification(true);
    }

    setIsLoading(false);
  };

  const hydrateLinks = () => {
    let cleanedLinks = links
      .map((l) => ({
        id: l.id,
        url: l.url,
        label: l.label,
        loading: false,
      }))
      .filter((l) => ![...resourceLinks].map((r) => r.id).includes(l.id));
    setResourceLinks([...resourceLinks, ...cleanedLinks]);
  };

  const deleteLink = async (id) => {
    const tokenIsExpired = evaluateTokenExpiry();
    if (tokenIsExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    let resourceLinks_ = [...resourceLinks];
    const foundLink = resourceLinks_.filter((l) => l.id === id);

    if (!Array.isArray(foundLink) || foundLink.length !== 1) {
      setNotification({
        message: "Error deleting link. Please try again.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    foundLink[0].loading = true;
    setResourceLinks(resourceLinks_);

    try {
      let result = await deleteMemorandumLink({
        id,
        token: token.idToken.value,
      });

      if (result.status === 401) {
        logout();
        setNotification({
          message: "Authentication error. Please login.",
          iconName: "ban",
          hex: "var(--danger-1)",
        });

        return setDisplayNotification(true);
      }

      if (result.error === true) throw new Error("Error");
      resourceLinks_ = resourceLinks_.filter((l) => l.id !== id);

      setNotification({
        message: "Successfully deleted link.",
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);
    } catch (error) {
      console.log(error);
      setNotification({
        message: "Could not delete the link.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setDisplayNotification(true);
    }

    setResourceLinks(resourceLinks_);
  };

  useEffect(() => {
    if (Array.isArray(links) && links.length > 0) hydrateLinks();
  }, [links]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MemorandumsLinkView
      resourceLinks={resourceLinks}
      loading={isLoading}
      linkData={{
        value: linkData,
        set: handleLinkData,
      }}
      submitLink={submitLink}
      deleteLink={deleteLink}
    />
  );
}
