import React from "react";
import Tabs from "../../../../../components/Tabs/controller";
import TabContent from "../../../../../components/Tabs/TabContent";
import parse from "html-react-parser";
import EmptyDialogue from "../../../../../components/EmptyDialogue";

export default function ScriptsView({ scripts, method, printRef, sectionRef }) {
  return (
    <div className="section__container" ref={printRef}>
      <div className="section__anchor__main" ref={sectionRef}></div>
      {typeof scripts === "object" &&
        scripts !== null &&
        Object.keys(scripts).length > 0 && (
          <Tabs
            title={"Scripts"}
            is_alternate={false}
            update={JSON.stringify(scripts)}
            method={method}
            defaultTab={"title Script"}
          >
            {Object.entries(scripts).map(([key, value], index) => (
              <TabContent key={index} title={`${key} Script`}>
                {typeof value.content === "string" &&
                value.content !== "" &&
                value.content !== undefined ? (
                  <div className="section__body no__border">
                    <div className="rich__text">{parse(value.content)}</div>
                  </div>
                ) : (
                  <div className="scripts__container empty__content">
                    <EmptyDialogue />
                  </div>
                )}
              </TabContent>
            ))}
          </Tabs>
        )}
    </div>
  );
}
