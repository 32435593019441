import React from "react";
import TournamentPreview from "./Components/TournamentPreview";
import TournamentsList from "./Components/TournamentsList/controller";
import { useTournaments } from "../../context/TournamentsProvider";
import ErrorDialogue from "../../components/ErrorDialogue";

export default function TournamentExplorerView({
  selectTournament,
  previewRef,
  selectedTour,
  tournaments,
}) {
  const { tours } = useTournaments();

  return (
    <div className="tournament__explorer__container">
      <div className={`tab__container is_alternate`}>
        <div className={`tab__selectors is_alternate`}>
          <div className="selectors__flex">
            <div className="selectors__container">
              {tours.map((tour, index) => (
                <button
                  onClick={(e) => selectedTour.setter(e.target.value)}
                  key={index}
                  value={tour.key}
                  className={`btn tab is_alternate ${
                    selectedTour.value === tour.key ? "active" : "inactive"
                  }`}
                >
                  {tour.label}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="tab__body">
          {!Array.isArray(tournaments) && (
            <ErrorDialogue
              message={"Error fetching tournament data."}
              position={{
                pos: "absolute",
                top: "0",
                left: "0",
              }}
              zIndex="500"
            />
          )}
          <div className="tab__content active">
            <div className="explorer">
              <div className="explorer__left" style={{ position: "relative" }}>
                {!selectTournament.selected && (
                  <ErrorDialogue
                    message={"Error fetching tournament data."}
                    position={{
                      pos: "absolute",
                      top: "0",
                      left: "0",
                    }}
                    zIndex="500"
                  />
                )}
                <TournamentPreview
                  previewRef={previewRef}
                  selectTournament={selectTournament}
                />
              </div>
              <div className="explorer__right">
                <TournamentsList
                  tournamentsList={tournaments}
                  selectTournament={selectTournament}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
