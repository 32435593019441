import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postCameraPositions = {
  getUploadConfig: async ({
    tournamentId,
    label,
    uid,
    fileName,
    token,
    fileType,
  }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/camera_starting_position/presigned_url`,
        {
          tournament_id: tournamentId,
          label: label,
          id: uid,
          file_name: fileName,
          file_type: fileType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
  uploadFileToS3: async ({ s3Config, file }) => {
    try {
      let formData = new FormData();
      Object.keys(s3Config.fields).forEach((key) => {
        formData.append(key, s3Config.fields[key]);
      });

      formData.append("file", file);

      const response = await axios.post(s3Config.url, formData, {
        headers: {
          "x-amz-meta-Content-Type": file.type,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
      return {
        error: true,
      };
    }
  },
  saveToDB: async ({ uid, tournamentId, s3Key, label, fileName, token }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/camera_starting_position`,
        {
          id: uid,
          tournament_id: tournamentId,
          s3_key: s3Key,
          label: label,
          file_name: fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
  fetchS3Url: async ({ uid, apiKey }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/camera_starting_position/presigned_url/${uid}`,
        {
          headers: {
            "X-API-KEY": apiKey,
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
};

export const deleteCameraPositions = async ({ uid, token }) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/camera_starting_position/${uid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};
