import React from "react";
import Document from "../../../../components/Document";
import { extractFileNameFromPath } from "../../../../utility";
import { Loader } from "semantic-ui-react";
import StaticLink from "../../../../components/StaticLink";

export default function MemorandumsView({
  memos,
  isInitializing: initializing,
  containerRef,
  isScrolling,
  links,
}) {
  return (
    <div
      className={`memorandums ${isScrolling ? "scrolling" : ""}`}
      ref={containerRef}
    >
      <div className="document__container memorandums">
        {initializing ? (
          <span className="initializing__loader">
            <Loader active inline size="mini" /> &nbsp; Fetching memos
          </span>
        ) : (
          <>
            {((memos && Array.isArray(memos) && memos.length > 0) ||
              (links && Array.isArray(links) && links.length > 0)) && (
              <>
                {Array.isArray(memos) &&
                  memos.map((memo, index) => (
                    <Document
                      key={index}
                      name={
                        memo.label
                          ? memo.label
                          : extractFileNameFromPath(memo.file_name)
                      }
                      path={{
                        prepend: null,
                        path: memo.fileURI,
                      }}
                      externalURL={memo.s3URI}
                    />
                  ))}
                {Array.isArray(links) &&
                  links.map((link, index) => (
                    <StaticLink key={index} title={link.label} url={link.url} />
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
