import React, { useRef, useState, useEffect } from "react";
import Slideshow from "./view";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  fetchSlideshowImages,
  postSlideshowImage,
} from "../../api/AdminSettings/slideshow";
import { useAuth } from "../../context/AuthProvider";
import { fetchFile } from "../../api";
import { useIsMounted } from "../../hooks/useIsMounted";

export default function SlideshowController() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { apiKey } = useAuth();
  const slider = useRef();

  let isMounted = useIsMounted();

  const getImages = async () => {
    setIsLoading(true);
    const result = await fetchSlideshowImages({ apiKey });
    if (result.error) {
      setIsLoading(false);
      return setImages(null);
    }

    let images_ = result.data;

    if (!images_ || !Array.isArray(images_)) {
      setImages(null);
      return setIsLoading(false);
    }

    images_ = shuffle(images_);

    for (let i = 0; i < images_.length; i++) {
      if (!isMounted.current) return;
      try {
        const fetchRes = await postSlideshowImage.fetchS3Url({
          uid: images_[i].id,
          apiKey: apiKey,
        });

        if (fetchRes && fetchRes.data) {
          const uploadedFile = await fetchFile({
            url: fetchRes.data.s3_url,
            name: fetchRes.data.file_name,
          });

          if (!isMounted.current) return;

          const formattedFile = {
            file_name: uploadedFile.name,
            file_url: fetchRes.data.s3_url,
            file: uploadedFile,
            file_label: fetchRes.data.label,
            id: images_[i].id,
          };

          setImages((prev) => [...prev, formattedFile]);
          if ((isLoading) => isLoading) setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getImages();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Slideshow sliderRef={slider} images={images} loading={isLoading} />;
}

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
