import React from "react";
import SectionHead from "../SectionHead";
import TabSelectors from "./TabSelectors/controller";

export default function TabsView({
  title,
  method,
  tabs,
  printRef,
  printMode,
  is_alternate,
}) {
  if (is_alternate)
    return (
      <div
        className={`tab__container is_alternate ${printMode ? "print" : ""}`}
        ref={printRef}
      >
        <TabSelectors
          tabs={tabs}
          is_alternate={is_alternate}
          title={title}
          method={method}
        />

        <div className="tab__body">
          {typeof tabs.sections === "object" &&
            Object.keys(tabs.sections).length > 0 &&
            Object.entries(tabs.sections).map(([title_, child], index) => (
              <div
                className={`tab__content ${
                  title_ === tabs.current ? "active" : ""
                } `}
                key={index}
              >
                <h2 className="tab__print__title">
                  {title} – {title_}
                </h2>
                {React.cloneElement(child, { current: tabs.current })}
                {/* {child} */}
              </div>
            ))}
        </div>
      </div>
    );
  return (
    <div
      className={`tab__container ${printMode ? "print" : ""}`}
      ref={printRef}
    >
      {title && <SectionHead title={title} method={method} />}
      <TabSelectors tabs={tabs} />

      <div className="tab__body section__body no__padding">
        {typeof tabs.sections === "object" &&
          Object.keys(tabs.sections).length > 0 &&
          Object.entries(tabs.sections).map(([subTitle, child], index) => (
            <div
              className={`tab__content ${
                subTitle === tabs.current ? "active" : ""
              } `}
              key={index}
            >
              <h2 className="tab__print__title">
                <span className="title__parent">{title}</span>
                {subTitle}
              </h2>
              {React.cloneElement(child, { current: tabs.current })}
              {/* {child} */}
            </div>
          ))}
      </div>
    </div>
  );
}
