import React from "react";
import { Icon } from "semantic-ui-react";

export default function Tooltip({ label, tip }) {
  return (
    <span className="tooltip" tabIndex={0}>
      <Icon name="info circle" />
      {label}
      <span className="tooltip__tip">{tip}</span>
    </span>
  );
}
