import SponsorReadsView from "./view";
import React, { useState, useEffect } from "react";
import { EditorState, ContentState } from "draft-js";
import { postSponsorshipReads } from "../../../../../api/Resources/sponsorship_reads";
import { evaluateTokenExpiry } from "../../../../../api";
import htmlToDraft from "html-to-draftjs";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useAuth } from "../../../../../context/AuthProvider";

export default function SponsorReadsController({
  tournamentId,
  sponsorReads,
  sectionRef,
}) {
  const richTextState = {
    raw: "",
    draft: EditorState.createEmpty(),
  };

  const rounds = {
    round1: {
      key: "round1",
      label: "Round 1",
    },
    round2: {
      key: "round2",
      label: "Round 2",
    },
    round3: {
      key: "round3",
      label: "Round 3",
    },
    round4: {
      key: "round4",
      label: "Round 4",
    },
  };

  const streams = {
    main_feed: {
      key: "main_feed",
      label: "Main feed",
    },
    marquee_group: {
      key: "marquee_group",
      label: "Marquee Group",
    },
    featured_group: {
      key: "featured_group",
      label: "Featured Group",
    },
    featured_holes: {
      key: "featured_holes",
      label: "Featured Holes",
    },
  };

  const stateObj = {};
  const streamsArray = Object.values(streams);
  const roundsArray = Object.values(rounds);

  for (let i = 0; i < roundsArray.length; i++) {
    stateObj[roundsArray[i].key] = {};

    for (let j = 0; j < streamsArray.length; j++) {
      stateObj[roundsArray[i].key][streamsArray[j].key] = {
        round: rounds[roundsArray[i].key],
        stream: streams[streamsArray[j].key],
        value: richTextState,
      };
    }
  }

  const [sponsorReadsState, setSponsorReadsState] = useState(stateObj);
  const [isInitializing, setIsInitializing] = useState(false);
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;
  const authContext = useAuth();
  const { logout, token } = authContext;

  const setSponsorReads = ({ obj, streamId, roundId }) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    const currentState = { ...sponsorReadsState };
    currentState[roundId][streamId].value = obj;

    setSponsorReadsState(currentState);
  };

  const submitSponsorReads = async (overrideContent) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    const readsByRound = Object.values(sponsorReadsState);

    readsByRound.forEach(async (readByRound) => {
      const readsByRoundStream = Object.values(readByRound);

      readsByRoundStream.forEach(async (readByRoundStream) => {
        try {
          setIsInitializing(true);
          const result = await postSponsorshipReads({
            tournamentId: tournamentId,
            content: overrideContent
              ? overrideContent
              : readByRoundStream.value.raw,
            streamId: streamIdLookup[readByRoundStream.stream.key],
            roundId: roundIdLookup[readByRoundStream.round.key],
            token: token.idToken.value,
          });

          if (result.status === 401) {
            logout();
            setNotification({
              message: "Authentication error. Please login.",
              iconName: "ban",
              hex: "var(--danger-1)",
            });

            return setDisplayNotification(true);
          }

          // incrementRequestCount();
          if (result.error === true) throw new Error("Error");
          setNotification({
            message: `Successfully updated Sponsorship Reads`,
            iconName: "check",
            hex: "var(--success-1)",
          });
          setDisplayNotification(true);
        } catch (error) {
          setIsInitializing(false);
          console.log(error);

          setNotification({
            message: `Could not update 1 or more Sponsorship Reads`,
            iconName: "ban",
            hex: "var(--danger-1)",
          });
          setDisplayNotification(true);
        }
        setIsInitializing(false);
      });
    });
  };

  const hydrateSponsorReads = () => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    if (!sponsorReads) return;
    if (!Array.isArray(sponsorReads)) return;
    if (sponsorReads.length < 1) return;
    setIsInitializing(true);
    const currentState = JSON.parse(JSON.stringify(sponsorReadsState));

    sponsorReads
      .filter((read) => read.round !== "graphics" || read.round !== "talent")
      .forEach((readsByRound) => {
        const roundId = roundAliasLookup[readsByRound.round];

        if (
          Array.isArray(readsByRound.streams) &&
          readsByRound.streams.length > 0
        ) {
          readsByRound.streams.forEach((readsByRoundStream) => {
            const streamId = streamAliasLookup[readsByRoundStream.stream_name];
            const content = readsByRoundStream.content;

            if (!currentState[roundId] || !currentState[roundId][streamId])
              return;

            let contentBlock = htmlToDraft(content);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              const editorState = EditorState.createWithContent(contentState);

              currentState[roundId][streamId].value = {
                ...currentState[roundId][streamId].value,
                raw: content,
                draft:
                  !content || content === ""
                    ? EditorState.createEmpty()
                    : editorState,
              };

              setSponsorReadsState(currentState);
            }
          });
        }
      });

    setIsInitializing(false);
  };

  useEffect(() => {
    hydrateSponsorReads();
  }, [sponsorReads]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SponsorReadsView
      sponsorReads={sponsorReadsState}
      setSponsorReads={setSponsorReads}
      submitSponsorReads={submitSponsorReads}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}

const roundIdLookup = {
  round1: "1",
  round2: "2",
  round3: "3",
  round4: "4",
  round5: "5",
};

const roundAliasLookup = {
  "Round 1": "round1",
  "Round 2": "round2",
  "Round 3": "round3",
  "Round 4": "round4",
};

const streamIdLookup = {
  main_feed: "4",
  marquee_group: "1",
  featured_group: "2",
  featured_holes: "3",
};

const streamAliasLookup = {
  Marquee: "marquee_group",
  "Main feed": "main_feed",
  "Featured Groups": "featured_group",
  "Featured Holes": "featured_holes",
};
