import React, { useState, useEffect, useRef } from "react";
import TabSelectorsView from "./view";

export default function TabSelectorsController({
  selectors,
  currentTab,
  title,
  method,
}) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scrollBoxRef = useRef();

  const onMouseDown = (e) => {
    setIsScrolling(true);
    setClientX(e.clientX);
  };

  const onMouseUp = (e) => {
    setIsScrolling(false);
  };

  const onWheel = (e) => {
    if (scrollBoxRef.current) {
      const scrollPos = scrollBoxRef.current.scrollLeft;
      const scrollX_ = scrollPos + e.deltaY;
      scrollBoxRef.current.scrollTo({
        top: 0,
        left: scrollX_,
      });
      setScrollX(scrollBoxRef.current.scrollLeft * -1);
      setClientX(scrollBoxRef.current.scrollLeft * -1);
    }
  };

  const onMouseMove = (e) => {
    if (isScrolling) {
      if (scrollBoxRef.current) {
        let scrollX_ = scrollX + e.clientX - clientX;
        setScrollLeft(scrollX_);
        setScrollX(scrollX_);
        let clientX_ = e.clientX;
        setClientX(clientX_);
      }
    }
  };

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollLeft = scrollLeft * -1;
    }
  }, [scrollLeft]);

  return (
    <TabSelectorsView
      selectors={selectors}
      scrollBoxRef={scrollBoxRef}
      currentTab={currentTab}
      title={title}
      method={method}
      mouse={{
        mouseDown: onMouseDown,
        mouseUp: onMouseUp,
        mouseMove: onMouseMove,
        mouseWheel: onWheel,
      }}
    />
  );
}
