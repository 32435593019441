import React from "react";
import TournamentDetailsS from "./Tours/S";
import TournamentDetailsR from "./Tours/R";
import TournamentDetailsH from "./Tours/H";
import ErrorDialogue from "../../components/ErrorDialogue";

export default function TournamentDetailsView({ tourCode, tournamentProps }) {
  switch (tourCode) {
    case "R":
      return <TournamentDetailsR {...tournamentProps} />;
    case "S":
      return <TournamentDetailsS {...tournamentProps} />;
    case "H":
      return <TournamentDetailsH {...tournamentProps} />;
    default:
      return (
        <div style={{ width: "100vw", height: "100vh" }}>
          <ErrorDialogue
            message={"Error fetching tournament data."}
            position={{
              pos: "fixed",
              top: "0",
              left: "0",
            }}
            zIndex="500000"
          />
        </div>
      );
  }
}
