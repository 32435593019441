import React from "react";
import { snaketoTitleCase } from "../../utility";

export default function PersonnelList({ personnel }) {
  return (
    <div className="tournament__personnel">
      {Array.isArray(personnel) &&
        personnel.length > 0 &&
        personnel.map((person) => {
          let formattedPerson = {
            personnel_name: person?.personnel_name,
            email_address: person?.email_address,
            phone_number: person?.phone_number,
          };

          if (Object.values(formattedPerson).some((attr) => !!attr)) {
            return (
              <div>
                {person?.personnel_position && (
                  <h4>{snaketoTitleCase(person.personnel_position)}</h4>
                )}

                {Object.values(formattedPerson)
                  .filter((attr) => !!attr)
                  .map((attr) => (
                    <p>{attr}</p>
                  ))}
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}
