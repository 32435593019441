import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const staffDashboard = {
  postHyperlink: async ({ url, label, token }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/staffing_dashboard`,
        {
          url: url,
          label: label,
          id: "1",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
  fetchHyperlink: async ({ apiKey }) => {
    try {
      const response = await axios.get(`${BASE_URL}/staffing_dashboard/1`, {
        headers: {
          "X-API-KEY": apiKey,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        status: error?.response?.status,
      };
    }
  },
};
