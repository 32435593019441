import React from "react";
import ResourcesView from "./view";

export default function ResourcesRController({
  resources,
  tournamentId,
  tabs,
  sectionRefs,
}) {
  return (
    <ResourcesView
      tab={{ active: tabs.value, toggle: tabs.setter }}
      resources={resources}
      tournamentId={tournamentId}
      sectionRefs={sectionRefs}
    />
  );
}
