import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postSponsorshipReads = async ({
  tournamentId,
  content,
  streamId,
  roundId,
  token,
}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sponsorship_read`,
      {
        tournament_id: tournamentId,
        content: content,
        stream_id: streamId,
        round_id: roundId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};

export const postSponsorshipReadsRounds = async ({
  tournamentId,
  content,
  roundId,
  token,
}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sponsorship_read_round`,
      {
        tournament_id: tournamentId,
        content: content,
        round_id: roundId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};
