import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../pages/Home";
import Tournament from "../pages/Tournament";
import Login from "../pages/Login";
import Auth from "../pages/Auth";
import NotFound from "../pages/NotFound";
import DashboardTournaments from "../pages/DashboardTournaments";
import DashboardEditTournament from "../pages/DashboardEditTournament";
import DashboardSettings from "../pages/DashboardSettings";
import BrowseDocument from "../pages/BrowseDocument";
import { useAuth } from "../context/AuthProvider";
import GuestCheckpoint from "../pages/GuestCheckpoint";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const { isGuest } = authContext;

  return (
    <Route
      {...rest}
      render={(props) =>
        isGuest === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/checkpoint" />
        )
      }
    />
  );
};

const AnonymousRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const { isAuthenticated } = authContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const AnonymousGuestRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const { isGuest, isAdmin, isStaff, isTalent } = authContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        isGuest === true ||
        isAdmin === true ||
        isStaff === true ||
        isTalent === true ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const GuestRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const { isGuest, isAdmin, isStaff, isTalent } = authContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        isGuest === true ||
        isAdmin === true ||
        isStaff === true ||
        isTalent === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/checkpoint" />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const { isAuthenticated, isAdmin } = authContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin === true && isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const StaffRoute = ({ component: Component, ...rest }) => {
  const authContext = useAuth();
  const { isAuthenticated, isAdmin, isStaff } = authContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        (isAdmin === true || isStaff === true) && isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default function Navigation() {
  return (
    <Switch>
      <StaffRoute
        path="/dashboard/tournaments"
        component={DashboardTournaments}
      />
      <StaffRoute
        path="/dashboard/tournament/edit/:id"
        component={DashboardEditTournament}
      />
      <StaffRoute path="/dashboard/settings" component={DashboardSettings} />
      <GuestRoute path="/tournaments/:id" component={Tournament} />
      <GuestRoute exact path="/" component={Home} />
      <GuestRoute path="/document" component={BrowseDocument} />
      <AnonymousRoute exact path="/login" component={Login} />
      <AnonymousRoute exact path="/auth" component={Auth} />
      <AnonymousGuestRoute
        exact
        path="/checkpoint"
        component={GuestCheckpoint}
      />
      <Route component={NotFound} />
    </Switch>
  );
}
