import React from "react";
import { Icon } from "semantic-ui-react";

export default function TournamentDetails({
  date_range,
  tournament_location,
  golf_course,
}) {
  return (
    <ul className="tournament__quick__details">
      {date_range && (
        <li>
          <Icon name="calendar outline" /> {date_range}
        </li>
      )}
      {tournament_location && (
        <li>
          <Icon name="map marker alternate" /> {tournament_location}
        </li>
      )}
      {golf_course && (
        <li>
          <Icon name="flag" /> {golf_course}
        </li>
      )}
    </ul>
  );
}
