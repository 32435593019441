import React, { useState, useEffect } from "react";
import PromoListView from "./view";

export default function PromoListController({
  promos,
  isDeleting,
  deletePromo,
  edit,
}) {
  const initialRoundPromos = {
    1: [],
    2: [],
    3: [],
    4: [],
  };
  const [roundPromos, setRoundPromos] = useState(initialRoundPromos);
  const [currentRound, setCurrentRound] = useState(1);

  const organizePromosIntoRounds = () => {
    let rounds = { ...initialRoundPromos };

    promos.forEach((p) => {
      if (!rounds[p.round_id]) rounds[p.round_id] = [];
      rounds[p.round_id].push(p);
    });

    setRoundPromos(rounds);
  };

  useEffect(() => {
    organizePromosIntoRounds();
  }, [promos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PromoListView
      promos={promos}
      isDeleting={isDeleting}
      deletePromo={deletePromo}
      edit={edit}
      roundPromos={roundPromos}
      currentRound={{
        setter: setCurrentRound,
        value: currentRound,
      }}
    />
  );
}
