import React, { useRef } from "react";
import ScriptsView from "./view";
import { useReactToPrint } from "react-to-print";

export default function ScriptsController({ scripts, sectionRef }) {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <ScriptsView
      scripts={scripts}
      printRef={printRef}
      sectionRef={sectionRef}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
    />
  );
}
