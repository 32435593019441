import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { useIsMounted } from "../../hooks/useIsMounted";

export default function Notification({ message, iconName, hex }) {
  const [active, setActive] = useState(false);
  let isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) setActive(true);
    setTimeout(() => {
      if (isMounted.current) setActive(false);
    }, 3000);

    return () => setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ border: `3px solid ${hex}` }}
      className={active ? "uinotification active" : "uinotification"}
    >
      <div className="message">
        <Icon style={{ color: hex }} name={iconName} />
        <p style={{ color: hex }}>{message}</p>
      </div>
    </div>
  );
}
