import React from "react";

export default function SmartImageView({ url, alt }) {
  return (
    <img
      src={url ? url : "/assets/images/placeholder.png"}
      alt={alt}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = "/assets/images/placeholder.png";
      }}
    />
  );
}
