import React from "react";
import SectionHead from "../../../../components/SectionHead";
import ErrorOverlay from "../../../../components/ErrorOverlay";
import TabSelectors from "./Tabs/Selectors/controller";
import PlayerGroupingNotes from "./PlayerGroupingNotes/controller";
import { Icon } from "semantic-ui-react";
import PreloaderOverlay from "../../../../components/PreloaderOverlay";
import { useAuth } from "../../../../context/AuthProvider";
import { useHistory } from "react-router-dom";
import EmptyDialogue from "../../../../components/EmptyDialogue";

export default function CatalystNotesView({
  method,
  selectors,
  selectTab,
  groupingActions,
  sectionRef,
  playerGroupings,
  printRef,
}) {
  const authContext = useAuth();
  const { isStaff, isAdmin, isTalent } = authContext;
  const history = useHistory();

  return (
    <div
      className="overview__catalyst__notes section__container"
      ref={printRef}
    >
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Catalyst Notes"} method={method} />
      {isStaff || isAdmin || isTalent ? (
        <>
          {Array.isArray(playerGroupings) && playerGroupings.length > 0 ? (
            <>
              <TabSelectors selectors={selectors} selectTab={selectTab} />

              <div className="tab__body" style={{ maxHeight: 500 }}>
                <div
                  className={`player__lists ${
                    selectors.page === 1 ? "active" : "hidden"
                  }`}
                >
                  {Array.isArray(playerGroupings) &&
                    playerGroupings.map((grouping, index) => (
                      <div
                        key={index}
                        className={`tab__content ${
                          grouping.round === selectors.currentTab
                            ? "active"
                            : ""
                        }`}
                      >
                        <h2 className="tab__print__title">
                          <span className="title__parent">Catalyst Notes</span>
                          Round {grouping.round} Groupings
                        </h2>
                        {Array.isArray(grouping.groups) &&
                          grouping.groups.length > 0 && (
                            <ul>
                              {grouping.groups
                                .sort((a, b) => a.group_num - b.group_num)
                                .map((group, index) => (
                                  <li key={index} className="grouping__card">
                                    <button
                                      onClick={() =>
                                        groupingActions.select({
                                          players: group.players,
                                          group_number: group.group_num,
                                        })
                                      }
                                    >
                                      <div className="card__content">
                                        <h4>Group #{group.group_num}</h4> <br />
                                        {Array.isArray(group.players) &&
                                          group.players.length > 0 &&
                                          group.players.map((player, index) => (
                                            <span key={index}>
                                              {player?.first_name &&
                                                player.first_name}{" "}
                                              {player?.last_name &&
                                                player.last_name}
                                              {index + 1 ===
                                              group.players.length
                                                ? ""
                                                : ", "}
                                            </span>
                                          ))}
                                      </div>
                                      <span className="card__prompt">
                                        <Icon name="chevron right" />
                                      </span>
                                    </button>
                                  </li>
                                ))}
                            </ul>
                          )}
                      </div>
                    ))}
                </div>
                {selectors.page === 2 && (
                  <PlayerGroupingNotes groupingActions={groupingActions} />
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                height: "100%",
                position: "relative",
                minHeight: 300,
                border: "1px solid var(--elevation-6)",
              }}
            >
              {Array.isArray(playerGroupings) ? (
                <PreloaderOverlay message="Fetching player list" />
              ) : playerGroupings === undefined ? (
                <EmptyDialogue />
              ) : playerGroupings === null ? (
                <ErrorOverlay message={"Failed to fetch player list."} />
              ) : (
                <PreloaderOverlay message="Fetching player list" />
              )}
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            height: "100%",
            position: "relative",
            minHeight: 300,
            border: "1px solid var(--elevation-6)",
          }}
        >
          <ErrorOverlay
            message={"You are currently unauthorized to view Catalyst Notes."}
            method={{
              action: () => history.push("/login"),
              message: "sign-in as Talent or Staff",
            }}
          />
        </div>
      )}
    </div>
  );
}
