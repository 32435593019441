import React, { useRef, useState, useEffect } from "react";
import SponsorshipReadsView from "./view";
import { useReactToPrint } from "react-to-print";

export default function SponsorshipReadsController({
  sponsorshipReads,
  sectionRef,
}) {
  const [computedReads, setComputedReads] = useState([]);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (Array.isArray(sponsorshipReads) && sponsorshipReads.length > 0) {
      setComputedReads(
        sponsorshipReads.filter(
          (read) => read.round !== "graphics" && read.round !== "talent"
        )
      );
    }
  }, [sponsorshipReads]);
  return (
    <SponsorshipReadsView
      sponsorshipReads={computedReads}
      printRef={printRef}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
      sectionRef={sectionRef}
    />
  );
}
