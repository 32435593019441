import React, { useEffect, useState } from "react";
import { postTimesheets } from "../../../../api/Resources/timesheets";
import TimesheetsView from "./view";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { useAuth } from "../../../../context/AuthProvider";

export default function TimesheetsController({ files, sectionRef }) {
  const [computedFiles, setComputedCharts] = useState([]);
  const [isInitializing, setIsInitializing] = useState(false);

  const authContext = useAuth();
  const { apiKey } = authContext;

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let files_ = [...files];

    for (let i = 0; i < files_.length; i++) {
      let s3URL;
      try {
        const { data } = await postTimesheets.fetchS3Url({
          uid: files_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      files_[i] = {
        ...files_[i],
        fileURI: files_[i].fileURI.replace(`/${files_[i].id}`, ""),
        s3URI: s3URL,
      };
    }
    if (isMounted.current) setIsInitializing(false);
    if (isMounted.current) setComputedCharts(files_);
  };

  useEffect(() => {
    if (Array.isArray(files) && files.length > 0) initializeData();
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TimesheetsView
      files={computedFiles}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
