import React from "react";
import { Icon } from "semantic-ui-react";

export default function LoadingDialogue({ message }) {
  return (
    <div className="loading__dialogue">
      <div className="dialogue__container">
        <Icon name="hourglass half" />
        <h3>{message?.title ? message.title : "Loading..."}</h3>
        <p>
          {message?.subtitle
            ? message.subtitle
            : "Please wait while we fetch data!"}
        </p>
      </div>
    </div>
  );
}
