import React from "react";
import parse from "html-react-parser";
import { Icon } from "semantic-ui-react";
import EmptyDialogue from "../../../../../components/EmptyDialogue";
import Tabs from "../../../../../components/Tabs/controller";
import TabContent from "../../../../../components/Tabs/TabContent";
import SectionHead from "../../../../../components/SectionHead";

export default function DotComLowerThirdsView({
  promos,
  method,
  printRef,
  sectionRef,
}) {
  return (
    <div ref={printRef} className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      {typeof promos === "object" &&
      promos &&
      Object.keys(promos).length > 0 ? (
        <Tabs
          title={"DOTCOM LOWER THIRDS"}
          update={JSON.stringify(promos)}
          method={method}
        >
          {typeof promos === "object" &&
            promos &&
            Object.entries(promos).map(([key, value], index) => (
              <TabContent key={`${key}-${index}`} title={`Round ${key}`}>
                {Array.isArray(value) && value.length > 0 ? (
                  <ul className="promos__container">
                    {value.map((promo, index) => (
                      <li key={index} className="dotcom__promo">
                        <div className="dotcom__promo__head">
                          <div className="promo__title">
                            <div className="position__container">
                              <span className="position">{promo.position}</span>
                            </div>
                            <div className="title">
                              <span className="sub">Promo</span>
                              <h5>{promo.promo_title}</h5>
                            </div>
                          </div>
                          <div className="promo__url">
                            {promo.graphics_url && (
                              <a
                                href={promo.graphics_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                View Graphic <Icon name="linkify" />{" "}
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="dotcom__promo__content">
                          <h6>Script</h6>
                          <div className="rich__text">
                            {parse(promo.script)}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="empty__content">
                    <EmptyDialogue />
                  </div>
                )}
              </TabContent>
            ))}
        </Tabs>
      ) : (
        <>
          <SectionHead method={method} title={"DOTCOM LOWER THIRDS"} />
          <div className="section__body">
            <div className="empty__content">
              <EmptyDialogue />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
