import React, { useContext, useEffect, useState } from "react";
import { stringify } from "query-string";
import useLocalStorage from "../hooks/useLocalStorage";
const AuthContext = React.createContext();

let authority = process.env.REACT_APP_ONE_LOGIN_AUTHORITY,
  client_id = process.env.REACT_APP_ONE_LOGIN_CLIENT_ID,
  redirect_uri = process.env.REACT_APP_ONE_LOGIN_REDIRECT_URI;

let guestPassword = process.env.REACT_APP_GUEST_PASSWORD,
  apiKey = process.env.REACT_APP_API_KEY;

const oidcConfiguration = {
  authority: authority,
  client_id: client_id,
  redirect_uri: redirect_uri,
  response_type: "id_token token",
  scope: "openid profile groups",
};

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  // TOKEN STATES
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  const [idToken, setIdToken] = useLocalStorage("idToken", null);
  const [state_, setState_] = useLocalStorage("state_", null);
  const [nonce, setNonce] = useLocalStorage("nonce", null);
  const [tokenExpiry, setTokenExpiry] = useLocalStorage("tokenExpiry", null);
  const [tokenGenerationTime, setTokenGenerationTime] = useLocalStorage(
    "tokenGenerationTime",
    null
  );
  // PROFILE STATES
  const [profile, setProfile] = useLocalStorage("profile", null);
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    "isAuthenticated",
    false
  );
  const [isAdmin, setIsAdmin] = useLocalStorage("isAdmin", false);
  const [isStaff, setIsStaff] = useLocalStorage("isStaff", false);
  const [isTalent, setIsTalent] = useLocalStorage("isTalent", false);
  // GUEST CHECKPOINT STATE
  const [isGuest, setIsGuest] = useLocalStorage("isGuest", false);
  // API KEY
  const [APIKey, setAPIKey] = useState(apiKey);

  const login = ({ state, nonce }) => {
    const params = stringify({
      ...oidcConfiguration,
      state: state,
      nonce: nonce,
    });
    const authURL = `${authority}/auth?${params}`;
    window.location.assign(authURL);
  };

  const logout = () => {
    setAccessToken(null);
    setIdToken(null);
    setState_(null);
    setNonce(null);
    setProfile(null);
  };

  const guestLogin = (string) => {
    if (string === guestPassword) return setIsGuest(true);
    return {
      error: true,
      message: "Incorrect access password.",
    };
  };

  const value = {
    token: {
      accessToken: {
        setter: setAccessToken,
        value: accessToken,
      },
      idToken: {
        setter: setIdToken,
        value: idToken,
      },
      state: {
        setter: setState_,
        value: state_,
      },
      nonce: {
        setter: setNonce,
        value: nonce,
      },
      tokenExpiry: {
        setter: setTokenExpiry,
        value: tokenExpiry,
      },
      tokenGenerationTime: {
        setter: setTokenGenerationTime,
        value: tokenGenerationTime,
      },
    },
    profile: {
      setter: setProfile,
      value: profile,
    },
    apiKey: APIKey,
    isAuthenticated: isAuthenticated,
    isAdmin: isAdmin,
    isStaff: isStaff,
    isTalent: isTalent,
    isGuest: isGuest,
    login: login,
    logout: logout,
    guestLogin: guestLogin,
  };

  useEffect(() => {
    if (
      (typeof profile === "object" && profile === null) ||
      profile === null ||
      profile === undefined ||
      !idToken
    ) {
      setIsAuthenticated(false);
      setIsAdmin(false);
      setIsStaff(false);
      setIsTalent(false);
    } else {
      if (profile && Array.isArray(profile?.groups)) {
        profile.groups.map((str) => str.toLowerCase()).includes("live_admin")
          ? setIsAdmin(true)
          : setIsAdmin(false);
        profile.groups.map((str) => str.toLowerCase()).includes("live_staff")
          ? setIsStaff(true)
          : setIsStaff(false);
        profile.groups.map((str) => str.toLowerCase()).includes("live_talent")
          ? setIsTalent(true)
          : setIsTalent(false);
      } else {
        setIsAdmin(false);
        setIsStaff(false);
        setIsTalent(false);
      }

      setIsAuthenticated(true);
    }
  }, [profile]); // eslint-disable-line react-hooks/exhaustive-deps

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
