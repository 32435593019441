import React, { useState, useEffect, useRef } from "react";
import MemorandumsView from "./view";
import { postMemorandums } from "../../../../api/Resources/memorandums";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { useAuth } from "../../../../context/AuthProvider";
import { useScreen } from "../../../../context/ScreenProvider";

export default function MemorandumsController({ memos, links }) {
  const [computedMemos, setComputedMemos] = useState([]);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const authContext = useAuth();
  const { apiKey } = authContext;
  const screenContext = useScreen();
  const { screenWidth, screenHeight } = screenContext;

  const containerRef = useRef(null);
  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let memos_ = [...memos];

    for (let i = 0; i < memos_.length; i++) {
      let s3URL;
      try {
        const { data } = await postMemorandums.fetchS3Url({
          uid: memos_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      memos_[i] = {
        ...memos_[i],
        fileURI: memos_[i].fileURI.replace(`/${memos_[i].id}`, ""),
        s3URI: s3URL,
      };
    }

    if (isMounted.current) {
      setIsInitializing(false);
      setComputedMemos(memos_);
    }
  };

  const evaluateScrolling = () => {
    if (!containerRef?.current) return;

    const { current: el } = containerRef;
    el.offsetHeight >= 275 ? setIsScrolling(true) : setIsScrolling(false);
  };

  useEffect(() => {
    if (Array.isArray(memos) && memos.length > 0) {
      initializeData();
    } else {
      setComputedMemos([]);
    }
  }, [memos]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    evaluateScrolling();
  }, [screenWidth, screenHeight, memos, computedMemos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MemorandumsView
      memos={computedMemos}
      isInitializing={isInitializing}
      containerRef={containerRef}
      isScrolling={isScrolling}
      links={links}
    />
  );
}
