import React, { useState, useEffect } from "react";
import UrgentMessage from "./view";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { postUrgentMessage } from "../../../../api/Resources/urgent_message";
import { evaluateTokenExpiry } from "../../../../api";
import { useNotification } from "../../../../context/NotificationProvider";
import { useAuth } from "../../../../context/AuthProvider";

export default function UrgentMessageController({
  tournamentId,
  message,
  sectionRef,
}) {
  const richTextState = {
    raw: "",
    draft: EditorState.createEmpty(),
  };
  const [urgentMessage, setUrgentMessage] = useState(richTextState);
  const [isInitializing, setIsInitializing] = useState(false);
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;
  const authContext = useAuth();
  const { logout, token } = authContext;

  const inputs = [
    {
      label: "Urgent Message",
      key: "urgent_message",
      type: "text",
      required: false,
      setter: (obj) => {
        let obj_ = { ...obj };
        setUrgentMessage(obj_);
      },
      value: urgentMessage,
    },
  ];

  const initializeData = () => {
    if (!message?.content) return;
    const contentBlock = htmlToDraft(message.content);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      const obj = {
        ...urgentMessage,
        raw: message.content,
        draft: !message.content ? EditorState.createEmpty() : editorState,
      };

      setUrgentMessage(obj);
    }
  };

  useEffect(() => {
    initializeData();
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitUrgentMessage = async (overrideContent) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    setIsInitializing(true);
    try {
      let content = urgentMessage.raw;
      if (content.startsWith("<p></")) content = "";

      const result = await postUrgentMessage({
        tournamentId: tournamentId,
        content: overrideContent ? overrideContent : content,
        token: token.idToken.value,
      });

      if (result.status === 401) {
        logout();
        setNotification({
          message: "Authentication error. Please login.",
          iconName: "ban",
          hex: "var(--danger-1)",
        });

        return setDisplayNotification(true);
      }

      if (result.error === true) throw new Error("Error");
      setNotification({
        message: "Successfully updated the Urgent Message!",
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);
    } catch (error) {
      setNotification({
        message: "Could not update the Urgent Message",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setDisplayNotification(true);
      console.log(error);
    }

    setIsInitializing(false);
  };

  return (
    <UrgentMessage
      inputs={inputs}
      submitUrgentMessage={submitUrgentMessage}
      sectionRef={sectionRef}
      initializing={isInitializing}
    />
  );
}
