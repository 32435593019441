import React from "react";

export default function RoundSelectorsView({
  scrollBoxRef,
  mouse,
  rounds,
  currentRound,
  selectRound,
}) {
  return (
    <div className="tab__selectors">
      <div
        ref={scrollBoxRef}
        onMouseDown={(e) => mouse.mouseDown(e)}
        onMouseUp={(e) => mouse.mouseUp(e)}
        onMouseMove={(e) => mouse.mouseMove(e)}
        onMouseLeave={(e) => mouse.mouseUp(e)}
        onWheel={(e) => mouse.mouseWheel(e)}
        className="selectors__container"
      >
        {rounds &&
          rounds.length > 0 &&
          rounds.map((selector, index) => (
            <button
              key={index}
              value={selector}
              className={`btn tab ${selector === currentRound ? "active" : ""}`}
              onClick={(e) => selectRound(e.target.value)}
            >
              Round {selector}
            </button>
          ))}
      </div>
    </div>
  );
}
