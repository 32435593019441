import React from "react";
import SectionHead from "../../../../../../components/SectionHead";
import { Loader, Icon } from "semantic-ui-react";
import parse from "html-react-parser";
import EmptyDialogue from "../../../../../../components/EmptyDialogue";
import RoundSelectors from "../RoundSelectors/controller";

export default function PromoListView({
  promos,
  isDeleting,
  deletePromo,
  edit,
  roundPromos,
  currentRound,
}) {
  return (
    <div>
      <SectionHead title={"DOTCOM LOWER THIRDS"} />
      <RoundSelectors
        rounds={Object.keys(roundPromos)}
        syncSelectedRound={currentRound.setter}
      />
      <div className="section__body no__padding clamp__height">
        {Array.isArray(roundPromos[currentRound.value]) &&
        roundPromos[currentRound.value].length > 0 ? (
          <ul className="promos__container preview">
            {roundPromos[currentRound.value].map((promo, index) => (
              <li className="dotcom__promo preview" key={index}>
                {edit.value.value === true && edit.value.id === promo.id && (
                  <div className="overlay">
                    <p>
                      Editing in progress. <br />{" "}
                      <button
                        onClick={() => edit.setter({ value: false, id: "" })}
                      >
                        Cancel?
                      </button>
                    </p>
                  </div>
                )}

                <div className="dotcom__promo__head">
                  {isDeleting.active === true && isDeleting.id === promo.id && (
                    <span className="initializing__loader">
                      <Loader active inline size="mini" />
                    </span>
                  )}
                  <div className="promo__title">
                    <div className="position__container">
                      <span className="position">{promo.position}</span>
                    </div>
                    <div className="title">
                      <span className="sub">Promo</span>
                      <h5>{promo.promo_title}</h5>
                    </div>
                  </div>
                  <div className="promo__url">
                    {promo.graphics_url && (
                      <a
                        href={promo.graphics_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Graphic <Icon name="linkify" />{" "}
                      </a>
                    )}
                  </div>
                </div>
                <div className="dotcom__promo__content">
                  <h6>Script</h6>
                  <div className="rich__text">{parse(promo.script)}</div>
                </div>

                <div className="item__actions">
                  <button onClick={() => deletePromo({ id: promo.id })}>
                    <Icon name="trash" />
                  </button>
                  <button
                    onClick={() => {
                      edit.setter({
                        value: true,
                        id: promo.id,
                      });
                    }}
                  >
                    <Icon name="edit" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div style={{ minHeight: 200, position: "relative" }}>
            <EmptyDialogue />
          </div>
        )}
      </div>
    </div>
  );
}
