import { Icon } from "semantic-ui-react";

export default function Pagination({ pagination }) {
  return (
    <div className="pagination">
      <button className="prev" onClick={() => pagination.prevPage()}>
        <span>
          <Icon name="chevron left" />
        </span>
      </button>
      {pagination.totalPages > 3 ? (
        <>
          {pagination.currentPage > 1 && (
            <>
              <button onClick={() => pagination.setPage(1)}>
                <span>{1}</span>
              </button>
              <span className="page__skip">...</span>
            </>
          )}
          {pagination.currentPage < pagination.totalPages - 2 && (
            <button
              className="current__page"
              onClick={() => pagination.setPage(pagination.currentPage)}
            >
              <span>{pagination.currentPage}</span>
            </button>
          )}

          {pagination.currentPage < pagination.totalPages - 2
            ? [...Array(pagination.totalPages).keys()]
                .slice(pagination.currentPage, pagination.currentPage + 2)
                .map((page, index) => (
                  <button
                    onClick={() => pagination.setPage(page + 1)}
                    key={index}
                  >
                    <span>{page + 1}</span>
                  </button>
                ))
            : [...Array(pagination.totalPages).keys()]
                .slice(pagination.totalPages - 3, pagination.totalPages)
                .map((page, index) => (
                  <button
                    onClick={() => pagination.setPage(page + 1)}
                    className={`${
                      page + 1 === pagination.currentPage ? "current__page" : ""
                    }`}
                    key={index}
                  >
                    <span>{page + 1}</span>
                  </button>
                ))}

          {pagination.currentPage < pagination.totalPages - 2 && (
            <>
              <span className="page__skip">...</span>
              <button onClick={() => pagination.setPage(pagination.totalPages)}>
                <span>{pagination.totalPages}</span>
              </button>
            </>
          )}
        </>
      ) : (
        [...Array(pagination.totalPages)].map((_, index) => (
          <button
            key={index}
            className={`${
              pagination.currentPage === index + 1 ? "current__page" : ""
            }`}
            onClick={() => pagination.setPage(index + 1)}
          >
            <span>{index + 1}</span>
          </button>
        ))
      )}

      <button className="next" onClick={() => pagination.nextPage()}>
        <span>
          <Icon name="chevron right" />
        </span>
      </button>
    </div>
  );
}
