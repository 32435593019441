import React from "react";
import SectionHead from "../../../../components/SectionHead";
import EmptyDialogue from "../../../../components/EmptyDialogue";
import LoadingDialogue from "../../../../components/LoadingDialogue";
import Document from "../../../../components/Document";
import { extractFileNameFromPath } from "../../../../utility";
import { useAuth } from "../../../../context/AuthProvider";
import { useHistory } from "react-router-dom";
import ErrorOverlay from "../../../../components/ErrorOverlay";

export default function PlayerNotesView({
  playerNotes,
  initializing,
  sectionRef,
}) {
  const authContext = useAuth();
  const { isStaff, isAdmin, isTalent, isGuest } = authContext;
  const history = useHistory();

  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Player Notes"} />
      <div
        className="section__body"
        style={{ position: "relative", height: "100%" }}
      >
        {isStaff || isAdmin || isTalent || isGuest ? (
          initializing ? (
            <div className="empty__content">
              <LoadingDialogue />
            </div>
          ) : (
            <>
              {playerNotes &&
                Array.isArray(playerNotes) &&
                (playerNotes.length > 0 ? (
                  <div
                    className="document__container"
                    style={{ position: "relative", height: "100%" }}
                  >
                    {playerNotes.map((note, index) => (
                      <Document
                        key={index}
                        name={
                          note.label
                            ? note.label
                            : extractFileNameFromPath(note.file_name)
                        }
                        path={{
                          prepend: null,
                          path: note.fileURI,
                        }}
                        externalURL={note.s3URI}
                        lastUpdated={"2d Ago"}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty__content">
                    <EmptyDialogue />
                  </div>
                ))}
            </>
          )
        ) : (
          <div className="empty__content">
            <ErrorOverlay
              message={"You are currently unauthorized to view Catalyst Notes."}
              method={{
                action: () => history.push("/login"),
                message: "sign-in as Guest, Talent or Staff",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
