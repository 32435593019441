import React, { useMemo } from "react";
import DashboardSidebar from "../components/DashboardSidebar/controller";
import DashboardHeader from "../components/DashboardHeader/controller";
import TournamentsList from "../features/TournamentsList/controller";
import { useTournaments } from "../context/TournamentsProvider";
import { formatDate } from "../utility";

export default function DashboardTournaments() {
  const { tournaments } = useTournaments();
  const tournamentData = useMemo(
    () => initializeTournamentData(tournaments),
    [tournaments]
  );

  function initializeTournamentData(tournaments) {
    if (!tournaments || Object.keys(tournaments).length === 0) return null;
    let tournamentData_ = {};

    Object.entries(tournaments).forEach(([tour, tournaments]) => {
      if (!Array.isArray(tournaments)) return;

      let computedTournaments = tournaments
        .map((tournament) => {
          const startDate = formatDate(new Date(tournament.tournament_start));
          const endDate = formatDate(new Date(tournament.tournament_end));

          const obj = {
            ...tournament,
            start: `${startDate.month} ${startDate.day}, ${startDate.year}`,
            end: `${endDate.month} ${endDate.day}, ${endDate.year}`,
          };

          return obj;
        })
        .sort((a, b) => {
          return new Date(a.tournament_start) - new Date(b.tournament_start);
        });

      tournamentData_[tour] = computedTournaments;
    });

    return tournamentData_;
  }

  return (
    <div className="page admin">
      <div className="dashboard__container">
        <DashboardSidebar />
        <DashboardHeader />
        <div className="dashboard__main">
          <div className="dashboard__body">
            <TournamentsList tournamentData={tournamentData} />
          </div>
        </div>
      </div>
    </div>
  );
}
