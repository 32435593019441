import ScriptsView from "./view";
import React, { useState, useEffect } from "react";
import { EditorState, ContentState } from "draft-js";
import { postScripts } from "../../../../../api/Resources/scripts";
import { evaluateTokenExpiry } from "../../../../../api";
import { useNotification } from "../../../../../context/NotificationProvider";
import { useAuth } from "../../../../../context/AuthProvider";
import htmlToDraft from "html-to-draftjs";

export default function ScriptsController({
  tournamentId,
  scripts: propScripts,
  sectionRef,
}) {
  const richTextState = {
    raw: "",
    draft: EditorState.createEmpty(),
  };

  const scripts = {
    title: {
      key: "title",
      label: "Title Script",
    },
    tease: {
      key: "tease",
      label: "Tease Script",
    },
  };

  const stateObj = {};
  const scriptsArray = Object.values(scripts);

  for (let i = 0; i < scriptsArray.length; i++) {
    stateObj[scriptsArray[i].key] = {
      script: scripts[scriptsArray[i].key],
      value: richTextState,
    };
  }

  const [scriptsState, setScriptsState] = useState(stateObj);
  const [isInitializing, setIsInitializing] = useState(false);

  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;
  const authContext = useAuth();
  const { logout, token } = authContext;

  const setScripts = ({ obj, scriptId }) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    const currentState = { ...scriptsState };
    currentState[scriptId].value = obj;
    setScriptsState(currentState);
  };

  const submitScripts = async (overrideContent) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    setIsInitializing(true);
    const scripts_ = Object.values({ ...scriptsState });
    let errorCount = 0;
    for (let i = 0; i < scripts_.length; i++) {
      try {
        const result = await postScripts({
          tournamentId: tournamentId,
          content: overrideContent
            ? overrideContent
            : scriptsState[scripts_[i].script.key].value.raw,
          type: scripts_[i].script.key,
          token: token.idToken.value,
        });

        if (result.status === 401) {
          logout();
          setNotification({
            message: "Authentication error. Please login.",
            iconName: "ban",
            hex: "var(--danger-1)",
          });

          return setDisplayNotification(true);
        }

        if (result.error === true) throw new Error("Error");
      } catch (error) {
        errorCount += 1;
        console.log(error);
      }
    }

    if (errorCount > 0) {
      setNotification({
        message: `Error updating scripts`,
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setDisplayNotification(true);
    } else {
      setNotification({
        message: `Successfully updated scripts`,
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);
    }

    setIsInitializing(false);
  };

  const hydrateScripts = () => {
    const scripts_ = Object.entries(propScripts);
    const currentState = { ...scriptsState };
    scripts_.forEach(([key, value]) => {
      let contentBlock = htmlToDraft(value.content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);

        currentState[key].value = {
          ...currentState[key].value,
          raw: value.content,
          draft: !value.content ? EditorState.createEmpty() : editorState,
        };

        setScriptsState(currentState);
      }
    });
  };

  useEffect(() => {
    if (typeof propScripts === "object" && propScripts !== null) {
      const scriptContents = Object.values(propScripts).map((script) => {
        return script.content;
      });
      const hasContent = scriptContents.some(
        (s) => s !== null && s !== undefined && s !== ""
      );

      if (hasContent) hydrateScripts();
    }
  }, [propScripts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ScriptsView
      scripts={scriptsState}
      setScripts={setScripts}
      submitScripts={submitScripts}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
