import React from "react";
import SectionHead from "../../../../components/SectionHead";
import Document from "../../../../components/Document";
import EmptyDialogue from "../../../../components/EmptyDialogue";

export default function TournamentDocuments({ documents, sectionRef }) {
  return (
    <div className="section__container">
      <div ref={sectionRef} className="section__anchor__main" />
      <SectionHead title="Tournament Documents" />
      <div
        className="section__body"
        style={
          Array.isArray(documents) && documents.length < 1
            ? {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }
            : null
        }
      >
        <div
          className={`document__container ${
            Array.isArray(documents) && documents.length > 0 ? "col2__wide" : ""
          }`}
        >
          {Array.isArray(documents) && documents.length > 0 ? (
            documents.map((document, index) => (
              <Document
                key={index}
                name={document.type.replace("Files", "")}
                path={{
                  prepend: "https://pgatourmedia.pgatourhq.com",
                  path: document.path,
                }}
                lastUpdated={"2d Ago"}
              />
            ))
          ) : (
            <div className="empty__content">
              <EmptyDialogue />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
