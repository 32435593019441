import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function DashboardHeaderView({ profile, logout }) {
  return (
    <div className="dashboard__header">
      <Link to="/" className="view__live">
        <Icon name="eye" />
        View Live Website
      </Link>

      <ul className="header__menu">
        {profile && (
          <li className="has__child">
            <button>
              <label className="user">
                <span className="item__icon">
                  <Icon name="user circle" />
                </span>
                <span>{profile.preferred_username}</span>
              </label>
            </button>

            <ul>
              <li>
                <button onClick={() => logout()}>
                  <span className="label">
                    <Icon name="sign out" />
                    <span>Logout</span>
                  </span>
                </button>
              </li>
              {/* <li>
                <button>
                  <span className="label">
                    <Icon name="info circle" />
                    <span>Account</span>
                  </span>
                </button>
              </li> */}
            </ul>
          </li>
        )}
      </ul>
    </div>
  );
}
