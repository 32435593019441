import React from "react";
import Slider from "react-slick";
import SmartImage from "../SmartImage/controller";
import ErrorDialogue from "../ErrorDialogue";
import Preloader from "../Preloader";

export default function Slideshow({ sliderRef, images, loading }) {
  return (
    <div className="slideshow">
      {Array.isArray(images) && images.length > 0 && (
        <Slider ref={(c) => (sliderRef.current = c)} {...sliderSettings}>
          {images.map((image, index) => (
            <div key={image.id} data-index={index} className="slide__container">
              <SmartImage
                url={image.file_url}
                alt={`slideshow - ${image.id}`}
              />
            </div>
          ))}
        </Slider>
      )}

      {loading === false &&
        (!Array.isArray(images) || images.length < 1 || !images) && (
          <ErrorDialogue
            message={"Error fetching slideshow images"}
            position={{ pos: "absolute", top: "0", left: "0" }}
          />
        )}

      {loading === true && (
        <Preloader
          position={{ pos: "absolute", top: "0", left: "0" }}
          message={"fetching slideshow images..."}
        />
      )}
    </div>
  );
}

const sliderSettings = {
  dots: false,
  autoplay: true,
  infinite: true,
  arrows: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
};
