import React from "react";
import Tabs from "../../../../../components/Tabs/controller";
import TabContent from "../../../../../components/Tabs/TabContent";
import parse from "html-react-parser";
import EmptyDialogue from "../../../../../components/EmptyDialogue";

export default function SponsorshipReadsView({
  sponsorshipReads,
  method,
  printRef,
  sectionRef,
}) {
  return (
    <div
      className="resource__sponsorship__reads"
      ref={printRef}
      style={{ position: "relative" }}
    >
      <div className="section__anchor__main" ref={sectionRef}></div>
      {Array.isArray(sponsorshipReads) && sponsorshipReads.length > 0 ? (
        <Tabs
          title={"Sponsorship Reads"}
          method={method}
          update={JSON.stringify(sponsorshipReads)}
        >
          {sponsorshipReads.map((read, index) => (
            <TabContent key={index} title={read.round}>
              <div className="section__body no__border">
                <div className="rich__text">
                  {(() => {
                    const test = new RegExp("^<p></p>\\n$");
                    if (
                      read.content !== "" &&
                      read.content !== undefined &&
                      read.content !== null &&
                      !test.test(read.content)
                    ) {
                      return parse(read.content);
                    } else {
                      return (
                        <div
                          style={{
                            minHeight: 300,
                            position: "relative",
                          }}
                        >
                          <EmptyDialogue />
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </TabContent>
          ))}
        </Tabs>
      ) : (
        <Tabs
          title={"Sponsorship Reads"}
          method={method}
          update={JSON.stringify(sponsorshipReads)}
        >
          {placeholder.map((read, index) => (
            <TabContent key={index} title={read.round}>
              <div className="empty__content" style={{ position: "relative" }}>
                <EmptyDialogue />
              </div>
            </TabContent>
          ))}
        </Tabs>
      )}
    </div>
  );
}

const placeholder = [
  { round: "round 1" },
  { round: "round 2" },
  { round: "round 3" },
  { round: "round 4" },
];
