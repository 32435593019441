import React from "react";
import { Icon } from "semantic-ui-react";

export default function EmptyDialogue({ message }) {
  return (
    <div className="empty__dialogue">
      <div className="dialogue__container">
        <Icon name="folder open outline" />
        <h3>{message?.title ? message.title : "Empty Data"}</h3>
        <p>
          {message?.subtitle
            ? message.subtitle
            : "Sorry, this data doesn't exist yet!"}
        </p>
      </div>
    </div>
  );
}
