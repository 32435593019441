import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import Logo from "../Logo";

export default withRouter(function DashboardSidebarView({ location, links }) {
  return (
    <div className="dashboard__sidebar">
      <div className="sidebar__header">
        <Link to="/" className="header__logo">
          <div className="logo">
            <Logo isAlternate={true} />
          </div>
        </Link>
      </div>
      <div className="sidebar__menu">
        <ul>
          {links.map((link, index) => (
            <li
              key={index}
              className={
                link.metaData.path === location.pathname ? "active" : ""
              }
            >
              {link.metaData.type === "link" && (
                <>
                  {link.metaData.isExternal === true && (
                    <>
                      <a
                        href={link.metaData.path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link.label.icon && (
                          <span className="item__icon">
                            <Icon name={link.label.icon} />
                          </span>
                        )}

                        {link.label.text}
                      </a>
                      <div className="active__indicator external">
                        <Icon name="chevron right" />
                      </div>
                    </>
                  )}
                  {link.metaData.isExternal === false && (
                    <>
                      <Link to={link.metaData.path}>
                        {link.label.icon && (
                          <span className="item__icon">
                            <Icon name={link.label.icon} />
                          </span>
                        )}
                        {link.label.text}
                      </Link>
                      <div
                        className={`active__indicator ${
                          link.metaData.path === location.pathname
                            ? "active"
                            : ""
                        }`}
                      ></div>
                    </>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
