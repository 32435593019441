import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postDotComLowerThirds = async ({
  token,
  id,
  position,
  tournamentId,
  promo_title,
  script,
  graphics_url,
}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/dotcom_lower_third`,
      {
        id: id,
        position: position,
        tournament_id: tournamentId,
        promo_title: promo_title,
        script: script,
        graphics_url: graphics_url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};

export const deleteDotComLowerThirds = async ({ token, id }) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/dotcom_lower_third/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};

export const postDotComLowerThirdsRounds = async ({
  token,
  id,
  position,
  tournamentId,
  round_id,
  promo_title,
  script,
  graphics_url,
}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/dotcom_lower_third_round`,
      {
        id: id,
        tournament_id: tournamentId,
        round_id: round_id,
        promo_title: promo_title,
        position: position,
        graphics_url: graphics_url,
        script: script,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};

export const deleteDotComLowerThirdsRounds = async ({ token, id }) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/dotcom_lower_third_round/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};
