import React from "react";
import Document from "../../../../components/Document";
import EmptyDialogue from "../../../../components/EmptyDialogue";
import SectionHead from "../../../../components/SectionHead";
import { extractFileNameFromPath } from "../../../../utility";
import LoadingDialogue from "../../../../components/LoadingDialogue";

export default function CameraStartingPositionsView({
  charts,
  initializing,
  sectionRef,
}) {
  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Camera Starting Positions"} method={null} />
      <div
        className="section__body clamp__height"
        style={{ position: "relative" }}
      >
        {initializing ? (
          <div className="empty__content">
            <LoadingDialogue />
          </div>
        ) : (
          <>
            {charts &&
              Array.isArray(charts) &&
              (charts.length > 0 ? (
                <div className="document__container">
                  {charts.map((chart, index) => (
                    <Document
                      key={index}
                      name={
                        chart.label
                          ? chart.label
                          : extractFileNameFromPath(chart.file_name)
                      }
                      path={{
                        prepend: null,
                        path: chart.fileURI,
                      }}
                      externalURL={chart.s3URI}
                      lastUpdated={"2d Ago"}
                    />
                  ))}
                </div>
              ) : (
                <div className="empty__content">
                  <EmptyDialogue />
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
