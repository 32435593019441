import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllTournaments = async ({ apiKey, tourCode }) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/tournaments?tour_code=${tourCode}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      error: true,
    };
  }
};

export const getOneTournament = async ({ id, apiKey }) => {
  try {
    const response = await axios.get(`${BASE_URL}/tournament/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": apiKey,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      error: true,
    };
  }
};
