import React, { useState, useEffect } from "react";
import TabsView from "./view";

export default function TabsController({
  title,
  method,
  children,
  printMode,
  is_alternate,
  update,
  defaultTab,
}) {
  const [tabSections, setTabSections] = useState({});
  const [currentTab, setCurrentTab] = useState("");
  const [tabSelectors, setTabSelectors] = useState([]);

  const configureTabs = () => {
    if (!children || children.length < 1) return;
    const tabSections_ = tabSections;

    switch (Array.isArray(children)) {
      case false:
        defaultTab
          ? setCurrentTab(defaultTab)
          : setCurrentTab(children.props.title);

        setTabSelectors([children.props.title]);
        tabSections_[children.props.title] = children;
        setTabSections(tabSections_);
        break;
      default:
        defaultTab
          ? setCurrentTab(defaultTab)
          : setCurrentTab(children[0].props.title);
        setTabSelectors(children.map((child) => child.props.title));
        children.forEach((child) => {
          tabSections_[child.props.title] = child;
        });
        setTabSections(tabSections_);
        break;
    }
  };

  const selectTab = (selector) => {
    if (!selector) return;
    setCurrentTab(selector);
  };

  useEffect(() => {
    configureTabs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    configureTabs();
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TabsView
      title={title}
      method={method}
      children={children}
      printMode={printMode}
      is_alternate={is_alternate}
      tabs={{
        sections: tabSections,
        current: currentTab,
        selectors: tabSelectors,
        select: selectTab,
      }}
    />
  );
}
