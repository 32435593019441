import React from "react";
import { Icon, Loader } from "semantic-ui-react";

export default function DashboardHyperlinkView({ initializing, hyperlink }) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="form__section uploads">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            Staffing Dashboard URL
            {initializing && (
              <span
                className="initializing__loader"
                style={{ margin: "10px 0", display: "block" }}
              >
                <Loader active inline size="mini" /> &nbsp; Loading
              </span>
            )}
            <span
              style={{
                fontSize: 12,
                display: "block",
                fontWeight: "normal",
                textTransform: "none",
                color: "var(--text-3)",
                lineHeight: "1.3em",
                marginTop: 5,
              }}
            >
              <Icon name="info circle" />
              Appears as a link in the website's header. Staff and Admins only.
            </span>
          </label>
          <button className="save__btn" onClick={() => hyperlink.save()}>
            <Icon name="save" />
            <span>Save Updates</span>
          </button>
        </div>
        <div className="form__section__col dbl">
          <div className="section__input">
            <div className="field__set">
              <label className="field__label">
                Label<span className="req">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => hyperlink.setter({ e, key: "label" })}
                value={hyperlink.value?.label}
                name={"label"}
                placeholder=""
              />
            </div>
          </div>
          <div className="section__input"></div>
          <div className="section__input">
            <div className="field__set">
              <label className="field__label">
                Smartsheet URL<span className="req">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => hyperlink.setter({ e, key: "url" })}
                value={hyperlink.value?.url}
                name={"hyperlink"}
                placeholder=""
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
