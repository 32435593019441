import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Icon, Loader } from "semantic-ui-react";
import { ToolbarOptions } from "../../../../../data";
import Tooltip from "../../../../../components/Tooltip";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import RichTextEditorError from "../../../../../components/RichTextEditorError";

export default function SponsorReadsView({
  sponsorReads,
  setSponsorReads,
  submitSponsorReads,
  initializing,
  sectionRef,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section scripts">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            Sponsorship Reads
          </label>
          <button className="save__btn" onClick={() => submitSponsorReads()}>
            <Icon name="save" />
            <span>Save Updates</span>
          </button>
          {initializing && (
            <span
              className="initializing__loader"
              style={{ display: "block", marginTop: 20 }}
            >
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col dbl__full">
          <ErrorBoundary
            fallback={
              <RichTextEditorError
                handleReset={() => submitSponsorReads(" ")}
              />
            }
          >
            {typeof sponsorReads === "object" &&
              sponsorReads !== null &&
              Object.values(sponsorReads).map((read, index) => (
                <React.Fragment key={index}>
                  {typeof read === "object" &&
                    read !== null &&
                    Object.values(read).map((readInput, index) => (
                      <div className="section__input" key={index}>
                        <label className="input__label">
                          {readInput.round.label} - {readInput.stream.label}
                          <br />
                          <Tooltip
                            label={"Text only"}
                            tip={
                              "Please do not insert or paste images, files or anything other than rich-text."
                            }
                          />
                        </label>
                        <Editor
                          toolbar={ToolbarOptions}
                          editorState={
                            readInput.value.raw === ""
                              ? EditorState.createEmpty()
                              : readInput.value.draft
                          }
                          onEditorStateChange={(editorState) => {
                            const obj = {
                              raw: draftToHtml(
                                convertToRaw(editorState.getCurrentContent())
                              ),
                              draft: editorState,
                            };
                            setSponsorReads({
                              obj: obj,
                              streamId: readInput.stream.key,
                              roundId: readInput.round.key,
                            });
                          }}
                        />
                      </div>
                    ))}
                </React.Fragment>
              ))}
          </ErrorBoundary>
        </div>
      </div>
    </form>
  );
}
