import React from "react";
import SectionHead from "../../../../../components/SectionHead";
import EmptyDialogue from "../../../../../components/EmptyDialogue";
import Document from "../../../../../components/Document";
import { extractFileNameFromPath } from "../../../../../utility";
import LoadingDialogue from "../../../../../components/LoadingDialogue";

export default function ScriptsView({ scripts, initializing, sectionRef }) {
  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Scripts"} />
      <div
        className="section__body clamp__height"
        style={{ position: "relative" }}
      >
        {initializing ? (
          <div className="empty__content">
            <LoadingDialogue />
          </div>
        ) : (
          <>
            {scripts &&
              Array.isArray(scripts) &&
              (scripts.length > 0 ? (
                <div
                  className="document__container"
                  style={{ position: "relative", height: "100%" }}
                >
                  {scripts.map((script, index) => (
                    <Document
                      key={index}
                      name={
                        script.label
                          ? script.label
                          : extractFileNameFromPath(script.file_name)
                      }
                      path={{
                        prepend: null,
                        path: script.fileURI,
                      }}
                      externalURL={script.s3URI}
                      lastUpdated={"2d Ago"}
                    />
                  ))}
                </div>
              ) : (
                <div className="empty__content">
                  <EmptyDialogue />
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
