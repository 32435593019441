import React, { useState, useEffect, useMemo } from "react";
import DashboardSidebar from "../components/DashboardSidebar/controller";
import DashboardHeader from "../components/DashboardHeader/controller";
import EditTournament from "../features/EditTournament/controller";
import { getOneTournament } from "../api/Tournaments/tournament";
import { useAuth } from "../context/AuthProvider";
import { useTournaments } from "../context/TournamentsProvider";
import Preloader from "../components/Preloader";

export default function DashboardEditTournament({ match }) {
  const [tournamentData, setTournamentData] = useState(null);
  const tournamentId = useMemo(() => match?.params?.id, [match]);
  const tourCode = useMemo(() => tournamentId[0], [tournamentId]);

  const { token, apiKey } = useAuth();
  const { selectedTour } = useTournaments();

  const getTournament = async () => {
    const tournamentId = match.params.id;
    if (!tournamentId) return;
    const { idToken } = token;

    const result = await getOneTournament({
      id: tournamentId,
      token: idToken.value,
      apiKey: apiKey,
    });

    setTournamentData(result);
  };

  useEffect(() => {
    getTournament();
  }, [tournamentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectedTour.setter(tourCode);
  }, [tourCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page admin edit__tournament">
      <div className="dashboard__container">
        <DashboardSidebar />
        <DashboardHeader />
        <div className="dashboard__main">
          <div className="dashboard__body">
            <EditTournament tournament={tournamentData} tourCode={tourCode} />
          </div>
          {tournamentData === null && (
            <Preloader
              message={"Fetching tournament..."}
              position={{
                pos: "absolute",
                top: "0",
                left: "0",
              }}
              zIndex="500"
            />
          )}
        </div>
      </div>
    </div>
  );
}
