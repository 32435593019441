import React, { useState, useEffect } from "react";
import DotComLowerThirdsView from "./view";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { v4 as uuid } from "uuid";
import {
  postDotComLowerThirds,
  deleteDotComLowerThirds,
} from "../../../../../api/Resources/dotcom_lowerthirds";
import { useAuth } from "../../../../../context/AuthProvider";
import { useNotification } from "../../../../../context/NotificationProvider";

export default function DotComLowerThirdsController({
  tournamentId,
  sectionRef,
  promos: promotions,
}) {
  const richTextState = {
    raw: "",
    draft: EditorState.createEmpty(),
  };
  const editState = {
    id: "",
    value: false,
  };

  const warningData_ = {
    positionExists: {
      message: () => (
        <span>
          There is an existing promo with the same
          <span className="code">&nbsp;Position</span>.
        </span>
      ),
      active: false,
    },
    nullTitle: {
      message: () => (
        <span>
          <span className="code">Promo title</span> cannot be empty.
        </span>
      ),
      active: false,
    },
    nullScript: {
      message: () => (
        <span>
          <span className="code">Scipt</span> cannot be empty.
        </span>
      ),
      active: false,
    },
    nullGraphicsURL: {
      message: () => (
        <span>
          <span className="code">Graphics URL</span> cannot be empty.
        </span>
      ),
      active: false,
    },
  };

  const [position, setPosition] = useState(1);
  const [title, setTitle] = useState("");
  const [graphicsURL, setGraphicsURL] = useState("");
  const [script, setScript] = useState(richTextState);
  const [promos, setPromos] = useState([]);
  const [edit, setEdit] = useState(editState);
  const [warningData, setWarningData] = useState(warningData_);
  const [isLoading, setIsLoading] = useState(false);
  const [isHydrating, setIsHydrating] = useState(false);
  const [isDeleting, setIsDeleting] = useState({
    id: "",
    active: false,
  });

  const authContext = useAuth();
  const { token, logout } = authContext;
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;

  const resetInputs = () => {
    setPosition(1);
    setTitle("");
    setGraphicsURL("");
    setScript(richTextState);
  };

  const validateEntries = ({ position_, title_, script_, graphicsURL_ }) => {
    setWarningData(warningData_);
    const newWarningData = { ...warningData_ };

    const warnings = [
      {
        warning: "positionExists",
        value: (() => [...promos].map((p) => p.position).includes(position_))(),
      },
      {
        warning: "nullTitle",
        value: (() => !title_)(),
      },
      {
        warning: "nullScript",
        value: (() => !script_)(),
      },
      {
        warning: "nullGraphicsURL",
        value: (() => !graphicsURL_)(),
      },
    ];

    if (edit.value === true) {
      const currentEdit = [...promos].filter((p) => p.id === edit.id)[0];
      if (currentEdit.position === position_) {
        const foundWarningIndex = [...warnings].findIndex(
          (warning) => warning.warning === "positionExists"
        );

        warnings[foundWarningIndex] = {
          ...warnings[foundWarningIndex],
          value: false,
        };
      }
    }
    const filteredWarnings = warnings.filter(
      (warning) => warning.value !== false
    );

    filteredWarnings.forEach((warning) => {
      newWarningData[warning.warning] = {
        ...newWarningData[warning.warning],
        active: true,
      };
    });

    setWarningData(newWarningData);
    if (filteredWarnings.length > 0) return false;
    return true;
  };

  const createPromo = async (overrideContent) => {
    setIsLoading(true);
    const promo_ = {
      id: uuid(),
      position: parseInt(position),
      promo_title: title,
      script: script.raw,
      graphics_url: graphicsURL,
    };

    const validEntries = validateEntries({
      position_: promo_.position,
      title_: promo_.promo_title,
      script_: promo_.script,
      graphicsURL_: promo_.graphics_url,
    });

    if (validEntries === false) return setIsLoading(false);

    const response = await postDotComLowerThirds({
      token: token.idToken.value,
      id: promo_.id,
      position: promo_.position,
      tournamentId: tournamentId,
      promo_title: promo_.promo_title,
      script: overrideContent ? overrideContent : promo_.script,
      graphics_url: promo_.graphics_url,
    });

    if (response.status === 401) {
      logout();
      setNotification({
        message: "Authentication error. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setIsLoading(false);
      return setDisplayNotification(true);
    }

    if (response.error === true) {
      setNotification({
        message: "Error creating promo. Please try again.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setIsLoading(false);
      return setDisplayNotification(true);
    }

    if (response.status === 200) {
      let promos_ = [...promos, promo_].sort((a, b) => {
        return a.position - b.position;
      });
      resetInputs();
      setPromos(promos_);
      setIsLoading(false);
      setNotification({
        message: "Successfully created promo.",
        iconName: "check",
        hex: "var(--success-1)",
      });
      setDisplayNotification(true);
    }
  };

  const updatePromo = async (overrideContent) => {
    setIsLoading(true);
    const promo_ = {
      id: edit.id,
      position: parseInt(position),
      tournament_id: tournamentId,
      promo_title: title,
      script: script.raw,
      graphics_url: graphicsURL,
    };

    const validEntries = validateEntries({
      position_: promo_.position,
      title_: promo_.promo_title,
      script_: promo_.script,
      graphicsURL_: promo_.graphics_url,
    });

    if (validEntries === false) return setIsLoading(false);

    const response = await postDotComLowerThirds({
      token: token.idToken.value,
      id: promo_.id,
      position: promo_.position,
      tournamentId: tournamentId,
      promo_title: promo_.promo_title,
      script: overrideContent ? overrideContent : promo_.script,
      graphics_url: promo_.graphics_url,
    });

    if (response.status === 401) {
      logout();
      setNotification({
        message: "Authentication error. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setIsLoading(false);
      return setDisplayNotification(true);
    }

    if (response.error === true) {
      setNotification({
        message: "Error creating promo. Please try again.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setIsLoading(false);
      return setDisplayNotification(true);
    }

    if (response.status === 200) {
      let promos_ = [...promos].filter((p) => p.id !== promo_.id);
      promos_ = [...promos_, promo_].sort((a, b) => {
        return a.position - b.position;
      });

      setPromos(promos_);
      setEdit({ id: "", value: false });
      setIsLoading(false);

      setNotification({
        message: "Successfully updated promo.",
        iconName: "check",
        hex: "var(--success-1)",
      });
      setDisplayNotification(true);
    }
  };

  const deletePromo = async ({ id }) => {
    setWarningData(warningData_);
    setIsDeleting({
      active: true,
      id: id,
    });
    const response = await deleteDotComLowerThirds({
      token: token.idToken.value,
      id: id,
    });

    if (response.status === 401) {
      logout();
      setNotification({
        message: "Authentication error. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setIsDeleting({
        active: false,
        id: "",
      });

      return setDisplayNotification(true);
    }

    if (response.error === true) {
      setNotification({
        message: "Error creating promo. Please try again.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setIsDeleting({
        active: false,
        id: "",
      });

      return setDisplayNotification(true);
    }

    if (response.status === 200) {
      let promos_ = [...promos]
        .filter((p) => p.id !== id)
        .sort((a, b) => {
          return a.position - b.position;
        });

      setPromos(promos_);

      setNotification({
        message: "Successfully deleted promo.",
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);

      setIsDeleting({
        active: false,
        id: "",
      });
    }
  };

  const hydratePromos = () => {
    setIsHydrating(true);
    const formattedPromos = promotions.map((p) => {
      const obj = {
        id: p.id,
        position: p.position,
        promo_title: p.promo_title,
        script: p.script,
        graphics_url: p.graphics_url,
      };

      return obj;
    });
    setIsHydrating(false);
    setPromos(formattedPromos);
  };

  const hydrateEditValues = () => {
    const foundPromo = [...promos].filter((p) => p.id === edit.id)[0];

    setPosition(foundPromo.position);
    setTitle(foundPromo.promo_title);
    setGraphicsURL(foundPromo.graphics_url);

    // set scripts here.
    let contentBlock = htmlToDraft(foundPromo.script);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      const obj = {
        raw: foundPromo.script,
        draft: !foundPromo.script ? EditorState.createEmpty() : editorState,
      };

      setScript(obj);
    }
  };

  useEffect(() => {
    setWarningData(warningData_);
    if (edit.value === false) resetInputs();
    if (edit.value === true && edit.id) hydrateEditValues();
  }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(promotions) && promotions.length > 0) hydratePromos();
  }, [promotions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DotComLowerThirdsView
      position={{
        setter: setPosition,
        value: position,
      }}
      title={{
        setter: setTitle,
        value: title,
      }}
      graphicsURL={{
        setter: setGraphicsURL,
        value: graphicsURL,
      }}
      script={{
        setter: setScript,
        value: script,
      }}
      edit={{
        setter: setEdit,
        value: edit,
      }}
      createPromo={createPromo}
      updatePromo={updatePromo}
      deletePromo={deletePromo}
      warningData={warningData}
      sectionRef={sectionRef}
      isLoading={isLoading}
      isHydrating={isHydrating}
      isDeleting={isDeleting}
      promos={promos}
    />
  );
}
