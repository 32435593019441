import React from "react";
import SectionHead from "../../../../../components/SectionHead";
import StaticLink from "../../../../../components/StaticLink";

export default function TechDirAudioWorkbooks({ sectionRef }) {
  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>

      <SectionHead title={"Technical Dir. & Audio Workbooks"} />
      <div className="section__body clamp__height">
        <div className="links__container">
          {staticLinks.map((link, index) => (
            <StaticLink key={index} url={link.url} title={link.title} />
          ))}
        </div>
      </div>
    </div>
  );
}

const staticLinks = [
  {
    title: "ON-SITE",
    url: "https://drive.google.com/drive/folders/1ezVw5cL8R5HHMxGjCQFTDqB--mnKZ35d",
  },
  {
    title: "STREAM 1",
    url: "https://drive.google.com/drive/folders/1JTh6oqq0-SIMmeFCqDnmn006FIPhK_c3",
  },
  {
    title: "STREAM 2",
    url: "https://drive.google.com/drive/folders/11FPeRyfyzkJ-qHP7_K51wSPIFoJCx-yd",
  },
  {
    title: "STREAM 3",
    url: "https://drive.google.com/drive/folders/1gjckIauVeSB3CJ1jkI0Q1OQiQRWh7xAm",
  },
  {
    title: "STREAM 4",
    url: "https://drive.google.com/drive/folders/1fBsWMClfORu-wIsYTLYTIbF46Vc07x8P",
  },
  {
    title: "STREAM 5",
    url: "https://drive.google.com/drive/folders/1gyDxfpFj4ELJ3MpFwNxtQlScC83u13CP",
  },
  {
    title: "STREAM 6",
    url: "https://drive.google.com/drive/folders/1rLoEfIEcXNWAOoVESbZMsUYpe04t640e",
  },
];
