import React, { useRef } from "react";

import Header from "../../../../components/Header/controller";
import Footer from "../../../../components/Footer";
import ScrollingTicker from "../../../../components/ScollingTicker/controller";
import Preloader from "../../../../components/Preloader";
import ErrorDialogue from "../../../../components/ErrorDialogue";

import TournamentBreadcrumbs from "../../Components/Breadcrumbs/controller";
import TournamentOverview from "../../Components/Overview/H/controller";
import TournamentResources from "../../Components/Resources/H/controller";
import TournamentNavigator from "../../Components/Navigator";
import ResourceNav from "../../Components/ResourceNav";

export default function TournamentPageH({
  hasError,
  tournamentData,
  tournamentPrevNext,
  fetched,
}) {
  const scrollToSection = ({ ref }) => {
    if (ref && ref.current) ref.current.scrollIntoView();
  };

  const sectionRefs = {
    overview: {
      key: "overview",
      label: "Overview",
      ref: useRef(null),
      tab: null,
    },
    timesheets: {
      key: "timesheets",
      label: "Timesheets",
      ref: useRef(null),
      tab: null,
    },
    daily_schedule: {
      key: "daily_schedule",
      label: "Daily Schedule (On Site)",
      ref: useRef(null),
      tab: null,
    },
    daily_schedule_pgate: {
      key: "daily_schedule_pgate",
      label: "Daily Schedule (PGATE)",
      ref: useRef(null),
      tab: null,
    },
    stream_details: {
      key: "stream_details",
      label: "Broadcast Details",
      ref: useRef(null),
      tab: null,
    },
    player_notes: {
      key: "player_notes",
      label: "Player Notes",
      ref: useRef(null),
      tab: null,
    },
    tournament_documents: {
      key: "tournament_documents",
      label: "Tournament Documents",
      ref: useRef(null),
      tab: null,
    },
    important_links: {
      key: "important_links",
      label: "Important Links",
      ref: useRef(null),
      tab: null,
    },
    camera_flowcharts: {
      key: "camera_flowcharts",
      label: "Camera Plans",
      ref: useRef(null),
      tab: null,
    },
    camera_positions: {
      key: "camera_positions",
      label: "Camera Starting Positions",
      ref: useRef(null),
      tab: null,
    },
    camera_assignments: {
      key: "camera_assignments",
      label: "Director Documents",
      ref: useRef(null),
      tab: null,
    },
    scripts: {
      key: "scripts",
      label: "Scripts",
      ref: useRef(null),
      tab: null,
    },
    dotcom_thirds_crew: {
      key: "dotcom_thirds_crew",
      label: "DOTCOM Lower Thirds",
      ref: useRef(null),
      tab: null,
    },
    tech_dir_audio_workbooks: {
      key: "tech_dir_audio_workbooks",
      label: "Additional Documents",
      ref: useRef(null),
      tab: null,
    },
    sponsor_agendas: {
      key: "sponsor_agendas",
      label: "Sponsorship Agendas",
      ref: useRef(null),
      tab: null,
    },
    sponsor_reads: {
      key: "sponsor_reads",
      label: "Sponsorship Reads",
      ref: useRef(null),
      tab: null,
    },
  };

  return (
    <>
      <div className="pseudo__header">
        <Header />
        <ScrollingTicker />
      </div>
      <div
        className="page"
        style={{
          background: "var(--elevation-2)",
          position: "relative",
          minHeight: "var(--vh)",
        }}
      >
        {tournamentData &&
          typeof tournamentData === "object" &&
          tournamentData !== null &&
          Object.keys(tournamentData).length > 1 && (
            <>
              <TournamentBreadcrumbs tournament={tournamentData} />
              <TournamentOverview
                tournament={tournamentData}
                tournamentId={tournamentData.id}
                sectionRefs={sectionRefs}
              />
              <TournamentResources
                resources={tournamentData.resources}
                tournamentId={tournamentData.id}
                sectionRefs={sectionRefs}
              />
              <TournamentNavigator prevNext={tournamentPrevNext} />
            </>
          )}
        {fetched === false && (
          <Preloader
            message={"Fetching tournament..."}
            position={{
              pos: "absolute",
              top: "0",
              left: "0",
            }}
            zIndex="500"
          />
        )}
        {hasError && (
          <ErrorDialogue
            message={"Error fetching tournament data."}
            position={{
              pos: "absolute",
              top: "0",
              left: "0",
            }}
            zIndex="5000"
          />
        )}
      </div>
      <ResourceNav sections={sectionRefs} scrollToSection={scrollToSection} />
      <Footer />
    </>
  );
}
