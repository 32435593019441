import React, { useState, useEffect } from "react";
import AccordionsView from "./view";

export default function AccordionsController({ children, printMode, update }) {
  const [accordionSections, setAccordionSections] = useState({});
  const [currentAccordion, setCurrentAccordion] = useState("");

  const configureAccordions = () => {
    if (!children || children.length < 1) return;
    setCurrentAccordion(children[0].props.title);
    const accordionSections_ = { ...accordionSections };
    children.forEach((child) => {
      accordionSections_[child.props.title] = child;
    });
    setAccordionSections(accordionSections_);
  };

  const selectAccordion = (selector) => {
    if (!selector) return;
    setCurrentAccordion(selector);
  };

  useEffect(() => {
    configureAccordions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    configureAccordions();
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccordionsView
      children={children}
      printMode={printMode}
      accordions={{
        sections: accordionSections,
        current: currentAccordion,
        select: selectAccordion,
      }}
    />
  );
}
