import React, { useState, useMemo } from "react";
import TournamentsListView from "./view";
import { useScreen } from "../../context/ScreenProvider";
import { useTournaments } from "../../context/TournamentsProvider";
import useLocalStorage from "../../hooks/useLocalStorage";

const today = new Date();
export default function TournamentsListController({ tournamentData }) {
  const { screenHeight } = useScreen();
  const { selectedTour } = useTournaments();
  // FILTERS
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(new Date(today));
  const [endDate, setEndDate] = useState(null);
  // FILTERED TOURNAMENTS
  const filteredTournaments = useMemo(
    () => applyFilter(tournamentData),
    [tournamentData, selectedTour, searchQuery, startDate, endDate] // eslint-disable-line react-hooks/exhaustive-deps
  );
  // PAGE DATA AND PAGINATION
  const [pagination, setPagination] = useState({
    currentPage: 1,
  });
  const pages = useMemo(
    () => configurePages(),
    [filteredTournaments, screenHeight] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function applyFilter(tournamentData) {
    if (!tournamentData || Object.keys(tournamentData).length === 0) return [];

    // SELECT TOURNAMENTS BY TOUR
    let tournaments_ = tournamentData[selectedTour.value] || [];

    // FILTERS FOR SEARCH QUERY
    if (searchQuery) {
      let sQuery = searchQuery.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
        ""
      );

      const regex = new RegExp(`${sQuery}`, "gi");
      tournaments_ = tournaments_.filter((tournament) =>
        regex.test(tournament.tournament_name)
      );
    }

    // FILTERS FOR START DATE
    tournaments_ = tournaments_.filter(
      (tournament) =>
        new Date(tournament.tournament_end).setHours(23, 30, 0, 0) > startDate
    );

    // FILTERS FOR END DATE
    if (endDate !== null) {
      tournaments_ = tournaments_.filter(
        (tournament) =>
          new Date(tournament.tournament_end) < endDate.setHours(23, 30, 0, 0)
      );
    }

    // SORT TOURNAMENTS BY START DATE
    tournaments_ = tournaments_.sort((a, b) => {
      return new Date(a.tournament_start) - new Date(b.tournament_start);
    });

    return tournaments_;
  }

  function configurePages() {
    let rows = 6;
    if (screenHeight > 940) rows = 9;
    if (screenHeight < 940 && screenHeight > 838) rows = 7;

    if (!Array.isArray(filteredTournaments)) return [];

    if (filteredTournaments && filteredTournaments.length <= rows) {
      setPagination({
        currentPage: 1,
      });
      return [[...filteredTournaments]];
    }

    // split array into pages;
    let pages = filteredTournaments
      .map((mapping, index) => (mapping = { ...mapping, index: index }))
      .reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rows);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);

    if (pagination.currentPage > pages.length)
      setPagination({
        currentPage: 1,
      });

    return pages;
  }

  const setPage = (int) => {
    if (!int) return;
    if (isNaN(+int)) return;
    let pagination_ = { ...pagination };
    if (+int > 0 && +int < pages.length + 1) {
      pagination_.currentPage = +int;
    } else {
      pagination_.currentPage = 1;
    }
    setPagination(pagination_);
  };

  const nextPage = () => {
    let pagination_ = { ...pagination };
    if (pagination_.currentPage === pages.length) return;
    pagination_.currentPage += 1;
    setPagination(pagination_);
  };

  const prevPage = () => {
    let pagination_ = { ...pagination };
    if (pagination_.currentPage === 1) return;
    pagination_.currentPage -= 1;
    setPagination(pagination_);
  };

  return (
    <TournamentsListView
      datePickerData={{
        today: new Date(today),
        start: {
          value: startDate,
          setter: setStartDate,
        },
        end: {
          value: endDate,
          setter: setEndDate,
        },
      }}
      search={{
        value: searchQuery,
        setter: setSearchQuery,
      }}
      tourFilter={selectedTour}
      pages={pages}
      pagination={{
        ...pagination,
        totalPages: pages.length,
        totalItems: filteredTournaments.length,
        nextPage,
        prevPage,
        setPage,
      }}
    />
  );
}
