import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function EditToolbarView({ tournament }) {
  return (
    <div className="edit__toolbar">
      <div className="breadcrumbs">
        <Link to="/dashboard/tournaments">
          <span>
            <Icon name="home" /> Tournaments
          </span>
        </Link>
        &nbsp;
        <Icon className="chevron" name="chevron right" />
        {tournament?.tournament_name && (
          <span>{tournament.tournament_name}</span>
        )}
      </div>
      <div className="tools">
        <Link className="preview" to={`/tournaments/${tournament.id}`}>
          <Icon name="arrow right" /> View Live Tournament
        </Link>
      </div>
    </div>
  );
}
