import React from "react";

export default function PageIndicator({ pagination, pages }) {
  return (
    <>
      {pagination.totalPages > 1 ? (
        <span className="total">
          {" "}
          <b>Tournaments</b>: {pages[pagination.currentPage - 1][0].index + 1} –{" "}
          {pages[pagination.currentPage - 1][
            pages[pagination.currentPage - 1].length - 1
          ].index + 1}{" "}
          of&nbsp;
          {pagination.totalItems ? pagination.totalItems : 0}{" "}
        </span>
      ) : (
        <span className="total">
          <b>Tournaments</b>: {pagination.totalItems}
        </span>
      )}
    </>
  );
}
