import React, { useState } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";

export default function DragDrop({ handleDropped, children, file }) {
  const [dragging, setDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  let isMounted = useIsMounted();

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((dragCounter) => (dragCounter += 1));
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      if (isMounted.current) setDragging(true);
    }
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(0);
    if (dragCounter > 0) return;
    if (isMounted.current) setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      handleDropped({ files: e.dataTransfer.files });
      e.dataTransfer.clearData();
      if (isMounted.current) setDragCounter(0);
    }
  };

  return (
    <div
      className="drag__drop"
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      style={{ position: "relative" }}
    >
      {dragging && (
        <div className="drop__prompt">
          <p>{file ? "drop file to replace" : "drop file to upload."}</p>
        </div>
      )}
      {children}
    </div>
  );
}
