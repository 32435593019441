import React from "react";
import Tabs from "../../../../../components/Tabs/controller";
import TabContent from "../../../../../components/Tabs/TabContent";
import Accordions from "../../../../../components/Accordions/controller";
import AccordionContent from "../../../../../components/Accordions/AccordionContent";
import parse from "html-react-parser";
import EmptyDialogue from "../../../../../components/EmptyDialogue";

export default function SponsorshipReadsView({
  sponsorshipReads,
  method,
  printRef,
  sectionRef,
}) {
  return (
    <div
      style={{ position: "relative" }}
      ref={printRef}
      key={sponsorshipReads.length}
    >
      <div className="section__anchor__main" ref={sectionRef}></div>
      {Array.isArray(sponsorshipReads) && sponsorshipReads.length > 0 ? (
        <Tabs
          title={"Sponsorship Reads"}
          method={method}
          update={JSON.stringify(sponsorshipReads)}
        >
          {sponsorshipReads.map((read, index) => (
            <TabContent key={index} title={read.round}>
              {Array.isArray(read.streams) && read.streams.length > 0 ? (
                <Accordions update={JSON.stringify(sponsorshipReads)}>
                  {read.streams.map((stream, index) => (
                    <AccordionContent
                      key={`${stream.stream_name} - ${index}`}
                      title={stream.stream_name}
                    >
                      <div className="rich__text">
                        {(() => {
                          const test = new RegExp("^<p></p>\\n$");
                          if (
                            stream.content !== "" &&
                            stream.content !== undefined &&
                            stream.content !== null &&
                            !test.test(stream.content)
                          ) {
                            return parse(stream.content);
                          } else {
                            return (
                              <div
                                style={{
                                  minHeight: 300,
                                  position: "relative",
                                }}
                              >
                                <EmptyDialogue />
                              </div>
                            );
                          }
                        })()}
                      </div>
                    </AccordionContent>
                  ))}
                </Accordions>
              ) : (
                <div
                  className="empty__content"
                  style={{ position: "relative" }}
                >
                  <EmptyDialogue />
                </div>
              )}
            </TabContent>
          ))}
        </Tabs>
      ) : (
        <Tabs
          title={"Sponsorship Reads"}
          method={method}
          update={JSON.stringify(sponsorshipReads)}
        >
          {placeholder.map((read, index) => (
            <TabContent key={index} title={read.round}>
              <div className="empty__content" style={{ position: "relative" }}>
                <EmptyDialogue />
              </div>
            </TabContent>
          ))}
        </Tabs>
      )}
    </div>
  );
}

const placeholder = [
  { round: "round 1" },
  { round: "round 2" },
  { round: "round 3" },
  { round: "round 4" },
];
