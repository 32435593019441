import axios from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const postPersonnel = async ({
  id,
  tournament_id,
  email_address,
  personnel_name,
  personnel_position,
  phone_number,
  token,
}) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/personnel`,
      {
        id,
        tournament_id,
        email_address,
        personnel_name,
        personnel_position,
        phone_number,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};

export const deletePersonnel = async ({ id, token }) => {
  try {
    const response = await axios.delete(`${BASE_URL}/personnel/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
    return {
      error: true,
      status: error?.response?.status,
    };
  }
};
