import React from "react";

export default function BrowseDocument() {
  return (
    <div>
      <iframe
        title="browse document"
        src="https://view.officeapps.live.com/op/embed.aspx?src=https://patrick-test-files.s3.amazonaws.com/2022+Test+Excel+File+Upload+-+Agilitek+Solutions+Ltd..xlsx"
        // src="https://view.officeapps.live.com/op/embed.aspx?src=https://pgatour-livewebsitefiles-staging.s3.amazonaws.com/H2022123/timesheet/b1cbcae1-bb8d-41a8-af52-30c4920c17fc/2022%20Test%20Excel%20File%20Upload%20-%20Agilitek%20Solutions%20Ltd..xlsx?AWSAccessKeyId=ASIAVOVNL3PVIKSHPS4N&Signature=eMWfWjfGH7NMFnZhYmsFpyYRN1g%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEHAaCXVzLWVhc3QtMSJIMEYCIQDCuz4HYlry1u16Mnn9tc7Gx1HSXu0U%2FlQ8srhOTMu7YgIhAJ2Bxnui1RAVuGnKdjDHXQr7msmHuR2iPaUX10jBzhWTKpwDCNn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQARoMMzc1MDk5NDgxMDY2Igw5FEtKnhsH2kzcIT0q8AJMTFC3ZFV9FxfjN5bZQXzsASB%2BANf%2BMA5Ao1BGxayua2zzHrLckZNu7iHy0jylFE%2FL5aOkB36prWTJ6PlRsSt9BpMniQ5STYNAb4I13Y2I1dTaHe8ssmUL2vOjwMexLFgteylLk6%2BLQeEW46s2DX5bFYEM%2BqNT3o8alRUsbxNZqTr9EBZCIdak24LWI9xMuDIru%2Fdnl3NH0N4QH5l1nWDU%2BxLX0OuTU1eOZtP%2BxcrOsxkRH0Rp1uwii%2FOZybDUcrEdX18heprAzRfqpsSAml%2FhvXxtMym16cjDr3xonNIFnsRRg66OPujPF1yAodv0N0u2WhRNLB4P%2F%2FEDkvAlkoHw3ILh9kNRjgJsLmr7VdDYUc%2BI%2FlMzDmKoS7qqAon39ohQiu7KcoAI3X%2BG4OfjI8cATT%2BXB%2FCHrXH%2BkaweFv6cpTLRLGHBHXQVpZTMoYv5%2F%2FSVG%2BHWHemUmniy4BYZqFOvOE2kYmKpQSAyvlBzhA7vbDCJ7u6XBjqcAWzqGNxTv4ZNrh6OuqGDq3Bv%2FDsXHi2APIvkgbdHlkj7gphMFqJFf7N8xrgnW80xyT4gNzMpwZBwVG1ARWnb0t5OIN9IXJzBI1BYej3qO11L11iYrzHmc6HGtQNw579LVc8Xqm1%2BaBAc3XZdXJQUkEz4cxSZI75kpDeTED9oMv%2F9htMTFYr%2BmvMdC8CndhWBRVGs63HD%2F4nog%2BN%2B7g%3D%3D&Expires=1660667763"
        width="1366px"
        height="623px"
        frameborder="0"
      >
        {/* This is an embedded{" "}
        <a target="_blank" href="http://office.com">
          Microsoft Office
        </a>{" "}
        document, powered by{" "}
        <a target="_blank" href="http://office.com/webapps">
          Office Online
        </a>
        . */}
      </iframe>
    </div>
  );
}
