import React from "react";
import DashboardSidebar from "../components/DashboardSidebar/controller";
import DashboardHeader from "../components/DashboardHeader/controller";
import DashboardSettings from "../features/AdminSettings/controller";

export default function DashboardSchedules() {
  return (
    <div className="page admin">
      <div className="dashboard__container">
        <DashboardSidebar />
        <DashboardHeader />
        <div className="dashboard__main">
          <div className="dashboard__body">
            <DashboardSettings />
          </div>
        </div>
      </div>
    </div>
  );
}
