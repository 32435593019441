import React, { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { useNotification } from "../context/NotificationProvider";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";

export default function GuestCheckpoint() {
  const [gPassword, setGPassword] = useState("");

  const authContext = useAuth();
  const { guestLogin } = authContext;
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;

  const date = new Date();
  const year = date.getFullYear();

  const submitGuestPassword = () => {
    const result = guestLogin(gPassword);

    if (result?.error) {
      setNotification({
        message: `${result.message}`,
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      return setDisplayNotification(true);
    }
  };

  return (
    <div className="page login checkpoint">
      <div className="login__window">
        <div className="logo__container">
          <div className="login__logo">
            <div className="logo">
              <Logo isAlternate={true} />
            </div>
          </div>
          <Icon name="lock" />
        </div>
        <div className="welcome__container">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              submitGuestPassword();
            }}
          >
            <input
              type="password"
              value={gPassword}
              placeholder="Enter access password"
              onChange={(e) => setGPassword(e.target.value)}
            />
          </form>
          <button onClick={() => submitGuestPassword()}>
            <Icon name="sign in" /> Enter as Guest
          </button>
          <span className="prompt" style={{ lineHeight: "1.3em" }}>
            Please enter the access password to continue to the application as a
            guest user. Or, sign in as talent or staff below.
          </span>
        </div>
        <div className="welcome__redirect forward">
          <Link to="/login">
            <span>Go to Talent/Staff sign-in</span>
            <Icon name="arrow right" />
          </Link>
        </div>
      </div>

      <div className="copyright">
        <p>
          &copy; {year} PGA TOUR INC. All Rights Reserved. | 100 PGA TOUR BLVD,
          PONTE VEDRA BEACH, FL 32086 |{" "}
          <a href="https://pgatourmedia.pgatourhq.com/">MEDIA WEBSITE</a> |{" "}
          <a href="https://www.pgatour.com/company/contact-us.html">
            CONTACT US
          </a>
        </p>
      </div>
    </div>
  );
}
