import React, { useState, useMemo, useEffect } from "react";
import TournamentsListView from "./view";
import { useScreen } from "../../../../context/ScreenProvider";
import { sortTournaments } from "../../../../utility";

const today = new Date();
export default function TournamentsListController({
  tournamentsList,
  selectTournament,
}) {
  const { screenHeight } = useScreen();
  const [startDate, setStartDate] = useState(new Date(today));
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredTournamentsList = useMemo(
    () => applyFilters(tournamentsList),
    [startDate, endDate, searchQuery, tournamentsList] // eslint-disable-line react-hooks/exhaustive-deps
  );
  // PAGINATION DATA
  const [pagination, setPagination] = useState({
    currentPage: 1,
  });
  const pages = useMemo(
    () => configurePages(filteredTournamentsList),
    [filteredTournamentsList, screenHeight] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function applyFilters(tournamentsList) {
    let filteredTournaments_ = [...tournamentsList];

    // FILTER FOR SEARCH QUERY
    if (searchQuery) {
      let sQuery = searchQuery.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
        ""
      );
      const regex = new RegExp(`${sQuery}`, "gi");
      filteredTournaments_ = filteredTournaments_.filter((tournament) =>
        regex.test(tournament.tournament_name)
      );
    }

    // FILTER FOR START DATE
    filteredTournaments_ = filteredTournaments_.filter(
      (tournament) =>
        (startDate >
          new Date(tournament.tournament_start).setHours(0, 0, 0, 0) &&
          startDate <
            new Date(tournament.tournament_end).setHours(23, 30, 0, 0) &&
          tournament.id !== "R2023500") ||
        (startDate <
          new Date(tournament.tournament_start).setHours(0, 0, 0, 0) &&
          tournament.id !== "R2023500")
    );

    // FILTER FOR END DATE
    if (endDate !== null) {
      filteredTournaments_ = filteredTournaments_.filter(
        (tournament) =>
          new Date(tournament.tournament_end) < endDate.setHours(23, 30, 0, 0)
      );
    }

    filteredTournaments_ = sortTournaments(filteredTournaments_);
    return filteredTournaments_;
  }

  function configurePages(filteredTournaments) {
    let rows = 5;
    if (screenHeight > 940) rows = 7;
    if (screenHeight < 940 && screenHeight > 838) rows = 6;

    if (!Array.isArray(filteredTournaments)) return [];

    if (filteredTournaments && filteredTournaments.length <= rows) {
      setPagination({
        currentPage: 1,
      });
      return [[...filteredTournaments]];
    }

    // split array into pages;
    let pages = filteredTournaments
      .map((mapping, index) => (mapping = { ...mapping, index: index }))
      .reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rows);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);

    if (pagination.currentPage > pages.length)
      setPagination({
        currentPage: 1,
      });

    return pages;
  }

  const setPage = (int) => {
    if (!int) return;
    if (isNaN(+int)) return;
    let pagination_ = { ...pagination };
    if (+int > 0 && +int < pages.length + 1) {
      pagination_.currentPage = +int;
    } else {
      pagination_.currentPage = 1;
    }
    setPagination(pagination_);
  };

  const nextPage = () => {
    let pagination_ = { ...pagination };
    if (pagination_.currentPage === pages.length) return;
    pagination_.currentPage += 1;
    setPagination(pagination_);
  };

  const prevPage = () => {
    let pagination_ = { ...pagination };
    if (pagination_.currentPage === 1) return;
    pagination_.currentPage -= 1;
    setPagination(pagination_);
  };

  return (
    <TournamentsListView
      selectTournament={selectTournament}
      datePickerData={{
        today: new Date(today),
        start: {
          value: startDate,
          setter: setStartDate,
        },
        end: {
          value: endDate,
          setter: setEndDate,
        },
      }}
      search={{
        value: searchQuery,
        setter: setSearchQuery,
      }}
      pages={pages}
      pagination={{
        ...pagination,
        totalPages: pages.length,
        totalItems: filteredTournamentsList.length,
        nextPage,
        prevPage,
        setPage,
      }}
    />
  );
}
