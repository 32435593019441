import React from "react";
import CameraPlanFlowchart from "../../CameraPlanFlowcharts/controller";
import CameraStartingPositions from "../../CameraStartingPositions/controller";
import CameraAssignments from "../../CameraAssignments/controller";
import DotComLowerThirds from "../../DotComLowerThirds/H/controller";
import TechDirAudioWorkbooks from "../../TechDirAudioWorkbooks/H/controller";
import SponsorshipAgendas from "../../SponsorshipAgendas/controller";
import SponsorshipReads from "../../SponsorshipReads/H/controller";
import ImportantLinks from "../../ImportantLinks/H";
import Scripts from "../../Scripts/H/controller";

export default function ResourcesHView({ resources, sectionRefs }) {
  return (
    <div className="tournament__resources__container">
      <div className="tab__container">
        <div className="section__resource">
          <CameraPlanFlowchart
            charts={resources?.crew?.cameraPlanFlowcharts}
            sectionRef={sectionRefs.camera_flowcharts.ref}
          />
          <Scripts
            scripts={resources?.talent?.script_file}
            sectionRef={sectionRefs.scripts.ref}
          />

          <CameraStartingPositions
            charts={resources?.crew?.cameraStartingPositions}
            sectionRef={sectionRefs.camera_positions.ref}
          />
          <SponsorshipAgendas
            agendas={resources?.crew?.sponsorshipAgendas}
            sectionRef={sectionRefs.sponsor_agendas.ref}
          />
          <CameraAssignments
            cameraAssignments={resources?.crew?.cameraAssignment}
            sectionRef={sectionRefs.camera_assignments.ref}
          />
          <SponsorshipReads
            sponsorshipReads={resources?.talent.sponsorship_read_round}
            sectionRef={sectionRefs.sponsor_reads.ref}
          />

          <TechDirAudioWorkbooks
            workbooks={resources?.crew?.technical_workbook}
            sectionRef={sectionRefs.tech_dir_audio_workbooks.ref}
          />
          <DotComLowerThirds
            promos={resources?.talent?.dotComLowerThirds_round}
            sectionRef={sectionRefs.dotcom_thirds_crew.ref}
          />

          <ImportantLinks sectionRef={sectionRefs.important_links.ref} />
        </div>
      </div>
    </div>
  );
}
