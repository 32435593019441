import React, { useState, useEffect } from "react";
import CameraFlowcharts from "./view";
import {
  postCameraFlowcharts,
  deleteCameraFlowcharts,
} from "../../../../api/Resources/camera_plans";
import { evaluateTokenExpiry, fetchFile } from "../../../../api";
import { useNotification } from "../../../../context/NotificationProvider";
import { v4 as uuid } from "uuid";
import { parseExtension, extractFileNameFromPath } from "../../../../utility";
import { useAuth } from "../../../../context/AuthProvider";

export default function CameraFlowchartsController({
  tournamentId,
  cameraFlowcharts,
  sectionRef,
}) {
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;
  const authContext = useAuth();
  const { logout, token, apiKey } = authContext;
  const bucket = {
    key: "flowcharts",
    label: "Upload",
    accepted: [".pdf", ".docx", ".doc", ".xls", ".xlsx", ".xlsm", ".csv"],
  };

  const initialState = {
    uploaded: [],
    selected: [],
  };

  const [fileBucket, setFileBucket] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);

  const selectFiles = ({ files }) => {
    const fileList = Object.values(files);
    let selectedFiles = [];
    let currentState = { ...fileBucket };

    const cleanFileList = fileList.filter((file) => {
      const extension = `.${parseExtension(file.name)}`;
      if (!bucket.accepted.includes(extension)) {
        return null;
      } else {
        return file;
      }
    });

    if (fileList.length !== cleanFileList.length) {
      setNotification({
        message:
          "Some file formats are not accepted, could not select all files.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      setDisplayNotification(true);
    }

    for (let i = 0; i < cleanFileList.length; i++) {
      selectedFiles = [
        ...selectedFiles,
        ...fileBucket.selected,
        {
          file: cleanFileList[i],
          id: uuid(),
          label: {
            active: false,
            value: extractFileNameFromPath(cleanFileList[i].name)[0],
          },
        },
      ];
    }

    currentState.selected = selectedFiles;
    setFileBucket(currentState);
  };

  const deselectFile = ({ id }) => {
    let currentState = { ...fileBucket };
    const { selected } = currentState;
    let filteredFiles = [];

    for (let i = 0; i < selected.length; i++) {
      if (selected[i].id !== id) {
        filteredFiles = [...filteredFiles, selected[i]];
      }
    }

    currentState.selected = filteredFiles;
    setFileBucket(currentState);
  };

  const uploadFiles = async () => {
    const tokenIsExpired = evaluateTokenExpiry();
    if (tokenIsExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    if (
      fileBucket.selected &&
      Array.isArray(fileBucket.selected) &&
      fileBucket.selected.length > 0
    ) {
      setIsLoading(true);
      let currentState = { ...fileBucket };
      const { selected } = fileBucket;
      for (let i = 0; i < selected.length; i++) {
        try {
          const uploadConfigResult = await postCameraFlowcharts.getUploadConfig(
            {
              tournamentId: tournamentId,
              label: selected[i].label.value || "",
              uid: selected[i].id,
              fileName: selected[i].file.name,
              fileType: selected[i].file.type,
              token: token.idToken.value,
            }
          );

          if (uploadConfigResult.status === 401) {
            logout();
            setNotification({
              message: "Authentication error. Please login.",
              iconName: "ban",
              hex: "var(--danger-1)",
            });

            return setDisplayNotification(true);
          }

          if (uploadConfigResult.error === true) throw new Error("Error");

          const s3Config = uploadConfigResult.data;
          const s3Key = s3Config.fields.key;

          const s3Res = await postCameraFlowcharts.uploadFileToS3({
            s3Config: s3Config,
            file: selected[i].file,
          });

          if (s3Res.error === true) throw new Error("Error");

          const dbRes = await postCameraFlowcharts.saveToDB({
            uid: selected[i].id,
            tournamentId: tournamentId,
            s3Key: s3Key,
            label: selected[i].label.value || "",
            fileName: selected[i].file.name,
            token: token.idToken.value,
          });

          if (dbRes.status === 401) {
            logout();
            setNotification({
              message: "Authentication error. Please login.",
              iconName: "ban",
              hex: "var(--danger-1)",
            });

            return setDisplayNotification(true);
          }

          if (dbRes.error === true) throw new Error("Error");

          const fetchRes = await postCameraFlowcharts.fetchS3Url({
            uid: selected[i].id,
            apiKey: apiKey,
          });

          if (fetchRes.error === true) throw new Error("Error");

          const uploadedFile = await fetchFile({
            url: fetchRes.data.s3_url,
            name: fetchRes.data.file_name,
          });

          const formattedFile = {
            file_name: uploadedFile.name,
            file_url: fetchRes.data.s3_url,
            file: uploadedFile,
            file_label: fetchRes.data.label,
            id: selected[i].id,
            loading: false,
          };

          currentState.uploaded = [...currentState.uploaded, formattedFile];
          currentState.selected = [...currentState.selected].filter(
            (file) => file.id !== selected[i].id
          );
        } catch (error) {
          setNotification({
            message: "Some file(s) could not be uploaded. Please try again.",
            iconName: "ban",
            hex: "var(--danger-1)",
          });

          setDisplayNotification(true);
          console.log(error);
        }
      }
      setFileBucket(currentState);
      setIsLoading(false);
    }
  };

  const editLabel = ({ id, operation, newValue }) => {
    const currentState = { ...fileBucket };
    const { selected } = currentState;

    const foundFile = selected.filter((file) => file.id === id)[0];

    switch (operation) {
      case "toggle":
        foundFile.label = {
          active: !foundFile.label.active,
          value: foundFile.label.value || foundFile.file.name,
        };
        setFileBucket(currentState);
        break;
      case "update":
        foundFile.label = {
          ...foundFile.label,
          value: newValue,
        };
        setFileBucket(currentState);
        break;
      case "delete":
        foundFile.label = {
          ...foundFile.label,
          active: false,
          value: "",
        };
        setFileBucket(currentState);
        break;
      default:
        break;
    }
  };

  const hydrateFiles = async () => {
    const tokenIsExpired = evaluateTokenExpiry();
    if (tokenIsExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    setIsInitializing(true);

    if (
      !cameraFlowcharts ||
      (Array.isArray(cameraFlowcharts) && cameraFlowcharts.length < 1)
    )
      return setIsInitializing(false);

    let currentState = { ...fileBucket };

    for (let i = 0; i < cameraFlowcharts.length; i++) {
      const fetchRes = await postCameraFlowcharts.fetchS3Url({
        uid: cameraFlowcharts[i].id,
        apiKey: apiKey,
      });

      if (fetchRes && fetchRes.data) {
        const uploadedFile = await fetchFile({
          url: fetchRes.data.s3_url,
          name: fetchRes.data.file_name,
        });

        const formattedFile = {
          file_name: uploadedFile.name,
          file_url: fetchRes.data.s3_url,
          file: uploadedFile,
          file_label: fetchRes.data.label,
          id: cameraFlowcharts[i].id,
          loading: false,
        };

        // check if file has already been hydrated.
        const uploadedIds = currentState.uploaded.map((file) => file.id);
        if (!uploadedIds.includes(formattedFile.id)) {
          currentState.uploaded = [...currentState.uploaded, formattedFile];
        }
      }
    }

    setFileBucket(currentState);
    setIsInitializing(false);
  };

  const deleteFile = async ({ id }) => {
    const tokenIsExpired = evaluateTokenExpiry();
    if (tokenIsExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }
    let currentState = { ...fileBucket };
    const { uploaded } = currentState;

    const foundFileIndex = uploaded.findIndex((file) => file.id === id);
    currentState.uploaded[foundFileIndex] = {
      ...currentState.uploaded[foundFileIndex],
      loading: true,
    };

    setFileBucket(currentState);

    try {
      const dRes = await deleteCameraFlowcharts({
        uid: id,
        token: token.idToken.value,
      });

      if (dRes.status === 401) {
        logout();
        setNotification({
          message: "Authentication error. Please login.",
          iconName: "ban",
          hex: "var(--danger-1)",
        });

        return setDisplayNotification(true);
      }

      if (dRes.error === true) throw new Error("Error");

      const newUploaded = uploaded.filter((file) => file.id !== id);
      currentState.uploaded = newUploaded;

      setFileBucket(currentState);

      setNotification({
        message: "Successfully deleted file.",
        iconName: "check",
        hex: "var(--success-1)",
      });

      setDisplayNotification(true);
    } catch (error) {
      console.log(error);
      setNotification({
        message: "Could not delete the file.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });

      setDisplayNotification(true);
    }
  };

  useEffect(() => {
    hydrateFiles();
  }, [cameraFlowcharts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CameraFlowcharts
      loading={isLoading}
      initializing={isInitializing}
      sectionRef={sectionRef}
      bucket={{
        bucket: bucket,
        state: fileBucket,
        selector: selectFiles,
        deselector: deselectFile,
        uploader: uploadFiles,
        labeller: editLabel,
        deleter: deleteFile,
      }}
    />
  );
}
