import React from "react";
import { Icon } from "semantic-ui-react";

export default function TabSelectorsView({
  tabs,
  scrollBoxRef,
  mouse,
  is_alternate,
  method,
}) {
  if (is_alternate)
    return (
      <div className="tab__selectors is_alternate">
        <div className="selectors__flex">
          <div className="selectors__container">
            {tabs.selectors &&
              tabs.selectors.length > 0 &&
              tabs.selectors.map((selector, index) => (
                <button
                  key={index}
                  value={selector}
                  className={`btn tab is_alternate ${
                    selector === tabs.current ? "active" : ""
                  }`}
                  onClick={(e) => tabs.select(e.target.value)}
                >
                  {selector}
                </button>
              ))}
          </div>
          {method && (
            <button
              onClick={() => method.function()}
              className="btn alt no-label print"
            >
              {method.icon && <Icon name={method.icon} />}
            </button>
          )}
        </div>
      </div>
    );
  return (
    <div className="tab__selectors">
      <div
        ref={scrollBoxRef}
        onMouseDown={(e) => mouse.mouseDown(e)}
        onMouseUp={(e) => mouse.mouseUp(e)}
        onMouseMove={(e) => mouse.mouseMove(e)}
        onMouseLeave={(e) => mouse.mouseUp(e)}
        onWheel={(e) => mouse.mouseWheel(e)}
        className="selectors__container"
      >
        {tabs.selectors &&
          tabs.selectors.length > 0 &&
          tabs.selectors.map((selector, index) => (
            <button
              key={index}
              value={selector}
              className={`btn tab ${selector === tabs.current ? "active" : ""}`}
              onClick={(e) => tabs.select(e.target.value)}
            >
              {selector}
            </button>
          ))}
      </div>
    </div>
  );
}
