import React, { useRef } from "react";
import OverviewView from "./view";
import { useReactToPrint } from "react-to-print";

export default function OverviewSController({
  tournament,
  tournamentId,
  sectionRefs,
}) {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <OverviewView
      tournament={tournament}
      printRef={printRef}
      tournamentId={tournamentId}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
      sectionRefs={sectionRefs}
    />
  );
}
