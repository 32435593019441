import React from "react";
import { Icon } from "semantic-ui-react";

export default function ErrorOverlay({ message, method }) {
  return (
    <div className="preloader__overlay">
      <div className="preloader__overlay__content">
        <div>
          <span>
            <Icon name="exclamation triangle" />
          </span>{" "}
          &nbsp;
          <span>{message}</span>{" "}
          {method && (
            <>
              <br />
              <div className="overlay__method">
                <button onClick={() => method.action()}>
                  {method.message}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
