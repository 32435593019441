export const groupItemBy = (array, property) => {
  var hash = {},
    props = property.split(".");
  for (var i = 0; i < array.length; i++) {
    var key = props.reduce(function (acc, prop) {
      return acc && acc[prop];
    }, array[i]);
    if (!hash[key]) hash[key] = [];
    hash[key].push(array[i]);
  }
  return hash;
};

export const formatTime = (date) => {
  try {
    let formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return formattedTime;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const formatDate = (date) => {
  try {
    let year = date.getFullYear();
    let month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    let day = date.getDate();
    if (day < 10) day = `0${day}`;
    let dayOfWeek = new Intl.DateTimeFormat("en-US", {
      weekday: "long",
    }).format(date);

    return {
      year,
      month,
      day,
      dayOfWeek,
    };
  } catch (error) {
    console.log(error);
    return {
      year: "Error",
      month: "Error",
      day: "Error",
      dayOfWeek: "Error",
    };
  }
};

export const formatFeaturedGroups = (featuredGroups) => {
  let featuredGroups_ = [...featuredGroups];

  const grouped = Object.entries(
    groupItemBy(featuredGroups_, "round.round_name")
  ).map(([key, value]) => {
    const roundObj = {};

    roundObj["round"] = key;

    const streamsData = value.map((stream) => {
      const streamObj = {};

      const { year, month, day, dayOfWeek } = formatDate(
        new Date(stream.round.round_date)
      );

      streamObj["date"] = `${dayOfWeek}, ${month} ${day}, ${year}`;
      streamObj["stream_name"] = stream.stream.stream_name;
      streamObj["content"] = stream.content;

      return streamObj;
    });

    roundObj["streams"] = streamsData;

    return roundObj;
  });
  return grouped;
};

// Computes when an item was last updated into weeks, days, hours, or minutes.
export const computeUpdatedTime = (today, datetime) => {
  const updateTime = new Date(datetime).getTime();
  const today_ = new Date(today).getTime();

  // get total seconds between the times
  let delta = Math.abs(today_ - updateTime) / 1000;

  // calculate (and subtract) whole days
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // check if the updateTime is in the future
  if (updateTime > today_) return `${Math.floor(days)}d in the future`;

  // return total weeks if days is greater than 7.
  if (days > 7) return `${Math.floor(days / 7)}w ago`;

  // return total hours if days is less than 1, and hours is greater than 0
  if (days < 1 && hours > 0) return `${Math.floor(hours)}h ago`;

  // return total minutes if days is less than 1 and hours is less than 1
  if (days < 1 && hours < 1) return `${Math.floor(minutes)}m ago`;

  return `${Math.floor(days)}d ago`;
};

// Computes and formats date range
export const computeDateRange = (startDate, endDate) => {
  const start = formatDate(new Date(startDate));
  const end = formatDate(new Date(endDate));

  if (start.month === end.month && start.year === end.year) {
    return `${start.month} ${start.day} – ${end.day}, ${end.year}`;
  } else {
    return `${start.month} ${start.day}, ${start.year} – ${end.month} ${end.day}, ${end.year}`;
  }
};

export const sortFilterTournaments = (today, tournaments) => {
  // 1. Sort tournaments by date
  const sorted = [...tournaments].sort((a, b) => {
    // parse unix time to js date.
    return new Date(a.tournament_start) - new Date(b.tournament_start);
  });

  // 2. Filter tournaments; show tournaments with end time > today
  // add 2 days buffer for filtering
  let bufferedDate = new Date(today);
  bufferedDate.setHours(0, 0, 0, 0);
  bufferedDate.setDate(bufferedDate.getDate() - 2);

  const filtered = sorted.filter((tournament) => {
    if (
      new Date(tournament.tournament_end).setHours(23, 30, 0, 0) >
        bufferedDate &&
      tournament.id !== "R2023500"
    )
      return tournament;
    return null;
  });

  return filtered;
};

export const sortTournaments = (tournaments) => {
  // 1. Sort tournaments by date
  const sorted = [...tournaments].sort((a, b) => {
    // parse unix time to js date.
    return new Date(a.tournament_start) - new Date(b.tournament_start);
  });

  return sorted;
};

export const unCamelCase = (string) => {
  try {
    return (
      string
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  } catch (error) {
    return string;
  }
};

export const spaceBeforeInteger = (string) => {
  try {
    let str = capitalize(string);
    str = str.replace(/(\d+)/g, function (_, num) {
      return " " + num + " ";
    });
    str = str.trim();
    return str;
  } catch (error) {
    return string;
  }
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const extractFileNameFromPath = (string) => {
  try {
    return string.match(/[^\\/]+?(?=\.\w+$)/g);
  } catch (error) {
    return "";
  }
};

export const extractFileNameWithExtFromPath = (string) => {
  try {
    return string.match(/[^/]*$/g);
  } catch (error) {
    return "";
  }
};

export const extractFileExtFromPath = (string) => {
  try {
    return string.match(/\.([^.]+)$/)[1];
  } catch (error) {
    return "";
  }
};

export const parseExtension = (string) => {
  try {
    const extension = string.match(/\.([^.]+)$/)[1];
    return extension;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const parseDate = (dateString) => {
  try {
    if (!dateString) return "Pending Run";
    const d = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      dateStyle: "short",
      timeStyle: "long",
    }).format(d);

    return formattedDate;
  } catch (error) {
    console.log(error);
    try {
      let formattedDate = new Date(`${dateString}`.split(" ").join("T"));

      const opts = {
        dateStyle: "short",
        timeStyle: "long",
      };

      formattedDate = formattedDate.toLocaleString("en-US", opts);

      if (formattedDate === "Invalid Date" || !formattedDate)
        throw new Error("Invalid Date");

      return formattedDate;
    } catch (error) {
      return "";
    }
  }
};

export const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const snaketoTitleCase = (s) => {
  try {
    return s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_
  } catch (error) {
    return s;
  }
};
