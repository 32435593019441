import React from "react";
import Navigation from "./navigation";
import { useAuth } from "./context/AuthProvider";
import { useTournaments } from "./context/TournamentsProvider";

export default function App() {
  const authContext = useAuth();
  const { isAdmin, isStaff } = authContext;
  const { selectedTour } = useTournaments();

  return (
    <div
      className={`App ${
        isAdmin || isStaff ? "admin__user" : "default__user"
      }  ${selectedTour?.value === "R" ? "ribbon__active" : ""}`}
    >
      <main>
        <Navigation />
      </main>
    </div>
  );
}
