import React, { useState, useEffect } from "react";
import EditTournamentHeaderView from "./view";
import { computeDateRange } from "../../../../utility";

export default function EditTournamentSidebarController({
  tournament,
  sectionRefs,
  sidebarToggle,
}) {
  const [tournamentData, setTournamentData] = useState(null);

  const initializeData = () => {
    const obj = {
      ...tournament,
      date_range: computeDateRange(
        tournament.tournament_start,
        tournament.tournament_end
      ),
    };

    setTournamentData(obj);
  };

  useEffect(() => {
    initializeData();
  }, [tournament]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EditTournamentHeaderView
      tournament={tournamentData}
      sectionRefs={sectionRefs}
      sidebarToggle={sidebarToggle}
    />
  );
}
