import React from "react";
import parse from "html-react-parser";
import { Icon } from "semantic-ui-react";
import EmptyDialogue from "../../../../../components/EmptyDialogue";
import SectionHead from "../../../../../components/SectionHead";

export default function DotComLowerThirdsView({
  promos,
  method,
  printRef,
  sectionRef,
}) {
  return (
    <div ref={printRef} className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead method={method} title={"DOTCOM LOWER THIRDS"} />
      <div className="section__body no__padding clamp__height">
        <div className="print__title">
          <h4>DOTCOM LOWER THIRDS</h4>
        </div>
        {Array.isArray(promos) && promos.length > 0 ? (
          <ul className="promos__container">
            {promos.map((promo, index) => (
              <li key={index} className="dotcom__promo">
                <div className="dotcom__promo__head">
                  <div className="promo__title">
                    <div className="position__container">
                      <span className="position">{promo.position}</span>
                    </div>
                    <div className="title">
                      <span className="sub">Promo</span>
                      <h5>{promo.promo_title}</h5>
                    </div>
                  </div>
                  <div className="promo__url">
                    {promo.graphics_url && (
                      <a
                        href={promo.graphics_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Graphic <Icon name="linkify" />{" "}
                      </a>
                    )}
                  </div>
                </div>
                <div className="dotcom__promo__content">
                  <h6>Script</h6>
                  <div className="rich__text">{parse(promo.script)}</div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="empty__content">
            <EmptyDialogue />
          </div>
        )}
      </div>
    </div>
  );
}
