import React from "react";
import { Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import Pagination from "../../../../components/Pagination";
import PageIndicator from "../../../../components/Pagination/PageIndicator";
import SmartImage from "../../../../components/SmartImage/controller";
import "react-datepicker/dist/react-datepicker.css";

export default function TournamentsList({
  datePickerData,
  search,
  selectTournament,
  pages,
  pagination,
}) {
  return (
    <>
      <span className="panel__title">Upcoming Events</span>
      <div className="explorer__toolbar">
        <div className="pickers">
          <div className="date__picker">
            <Icon name="calendar outline" />
            <DatePicker
              selected={datePickerData.start.value}
              onChange={(date) => datePickerData.start.setter(date)}
              selectsStart
              startDate={datePickerData.start.value}
              endDate={datePickerData.end.value}
            />
            {datePickerData.start.value.getTime() !==
              datePickerData.today.getTime() && (
              <button
                onClick={() =>
                  datePickerData.start.setter(datePickerData.today)
                }
                className="clear"
              >
                <Icon name="close" />
              </button>
            )}
          </div>
          <div className="date__picker">
            <Icon name="calendar outline" />
            <DatePicker
              selected={datePickerData.end.value}
              onChange={(date) => datePickerData.end.setter(date)}
              selectsEnd
              startDate={datePickerData.start.value}
              endDate={datePickerData.end.value}
              minDate={datePickerData.start.value}
            />
            {datePickerData.end.value !== null && (
              <button
                className="clear"
                onClick={() => datePickerData.end.setter(null)}
              >
                <Icon name="close" />
              </button>
            )}
          </div>
        </div>

        <div className="search__bar">
          <div className="bar">
            <Icon name="search" />

            <input
              type="text"
              value={search.value}
              onChange={(e) => search.setter(e.target.value)}
              placeholder="Search by Tournament Title"
            />
            {search.value !== "" && (
              <button onClick={() => search.setter("")} className="clear">
                <Icon name="close" />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="explorer__list">
        <div>
          <PageIndicator pages={pages} pagination={pagination} />
          <ul>
            {Array.isArray(pages) && pages.length > 0 && (
              <>
                {pages[pagination.currentPage - 1].map((tournament, index) => (
                  <li className="tournament__card" key={index}>
                    <button
                      onClick={() => selectTournament.select(tournament.id)}
                    >
                      <div className="tournament__logo list">
                        <SmartImage
                          url={tournament.tournament_logo_url}
                          alt="tournament logo"
                        />
                      </div>
                      <div className="tournament__info">
                        <h4>{tournament.tournament_name}</h4>
                        <p className="date__range">
                          <Icon name="calendar outline" />{" "}
                          <span>{tournament.date_range}</span>{" "}
                        </p>
                      </div>
                    </button>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
        {Array.isArray(pages) && pages.length > 1 && (
          <Pagination pagination={pagination} />
        )}
      </div>
    </>
  );
}
