import React, { useEffect, useState } from "react";
import ScriptsView from "./view";
import { postScriptsFiles } from "../../../../../api/Resources/scripts";
import { useIsMounted } from "../../../../../hooks/useIsMounted";
import { useAuth } from "../../../../../context/AuthProvider";

export default function ScriptsController({ scripts, sectionRef }) {
  const [isInitializing, setIsInitializing] = useState(false);
  const [computedScripts, setComputedScripts] = useState([]);

  const authContext = useAuth();
  const { apiKey } = authContext;

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let scripts_ = [...scripts];

    for (let i = 0; i < scripts_.length; i++) {
      let s3URL;
      try {
        const { data } = await postScriptsFiles.fetchS3Url({
          uid: scripts_[i].id,
          apiKey: apiKey,
        });
        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      scripts_[i] = {
        ...scripts_[i],
        fileURI: scripts_[i].fileURI.replace(`/${scripts_[i].id}`, ""),
        s3URI: s3URL,
      };
    }
    if (isMounted.current) setIsInitializing(false);
    if (isMounted.current) setComputedScripts(scripts_);
  };

  useEffect(() => {
    if (Array.isArray(scripts) && scripts.length > 0) initializeData();
  }, [scripts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ScriptsView
      scripts={computedScripts}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
