import React from "react";
import { Icon } from "semantic-ui-react";
import {
  extractFileNameWithExtFromPath,
  extractFileExtFromPath,
} from "../../utility";

export default function Document({ name, path, externalURL }) {
  return (
    <span className={`document`}>
      <span className="document__icon">
        <span className="icon">
          {extractFileExtFromPath(path.path) && (
            <span className="document__extension">
              {extractFileExtFromPath(path.path)}
            </span>
          )}

          <Icon name="file" />
        </span>
      </span>
      <a
        href={!externalURL ? `${path.prepend}${path.path}` : `${externalURL}`}
        target="blank_"
        rel="noreferrer"
        className="document__meta"
      >
        <h5>{name}</h5>
        {extractFileNameWithExtFromPath(path.path) && (
          <span className="file__name">
            {extractFileNameWithExtFromPath(path.path)}
          </span>
        )}
      </a>
    </span>
  );
}
