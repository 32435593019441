import React from "react";
import EmptyDialogue from "../../../../components/EmptyDialogue";
import { Loader, Icon } from "semantic-ui-react";
import StaticLink from "../../../../components/StaticLink";
export default function MemorandumsLinkView({
  initializing,
  resourceLinks,
  loading,
  linkData,
  submitLink,
  deleteLink,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="form__section uploads">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            Production Memos (Links)
            <span
              style={{
                fontSize: 12,
                display: "block",
                fontWeight: "normal",
                textTransform: "none",
                color: "var(--text-3)",
              }}
            >
              <Icon name="info circle" />
              Links to Production Memos and Miscellaneous Memos
            </span>
          </label>
          {initializing && (
            <span className="initializing__loader">
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col dbl__full reverse__wrap">
          <div className="section__input">
            <div className="field__set">
              <label className="field__label">
                Label <span className="req">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => linkData.set(e)}
                value={linkData.value.label}
                name="label"
              />
            </div>
            <div className="field__set">
              <label className="field__label">
                URL <span className="req">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => linkData.set(e)}
                value={linkData.value.url}
                name="url"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="save__btn"
                style={{ maxWidth: 175, marginTop: 20 }}
                onClick={() => submitLink()}
              >
                <Icon name="save" />
                Save Link
                {loading && (
                  <span className="initializing__loader">
                    <Loader active inline size="mini" inverted />
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className="section__input">
            <div className="uploaded__files bucket">
              <span className="box__label">
                Links{" "}
                <span className="count">
                  <span>({resourceLinks.length})</span>
                </span>
              </span>
              <div className="bucket__container">
                {Array.isArray(resourceLinks) && resourceLinks.length > 0 ? (
                  <div className="links__container edit">
                    {resourceLinks.map((link) => (
                      <StaticLink
                        url={link.url}
                        title={link.label}
                        onDelete={() => deleteLink(link.id)}
                        loading={link.loading}
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyDialogue
                    message={{
                      title: "No links yet",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
