import React from "react";
import SmartImage from "../../components/SmartImage/controller";
import TournamentsListToolbar from "./Components/Toolbar";
import Pagination from "../../components/Pagination";
import PageIndicator from "../../components/Pagination/PageIndicator";
import { Link } from "react-router-dom";

export default function TournamentsListView({
  datePickerData,
  search,
  tourFilter,
  pages,
  pagination,
}) {
  return (
    <div className="tournaments__list">
      <TournamentsListToolbar
        datePickerData={datePickerData}
        search={search}
        tourFilter={tourFilter}
      />
      <div className="meta__bar">
        <PageIndicator pages={pages} pagination={pagination} />
        {Array.isArray(pages) && pages.length > 1 && (
          <Pagination pages={pages} pagination={pagination} />
        )}
      </div>
      <div className="tournaments__table">
        <div className="table__headings">
          <div className="heading title">
            <h2>Tournament</h2>
          </div>
          <div className="heading start">
            <h2>Start</h2>
          </div>
          <div className="heading end">
            <h2>End</h2>
          </div>
          <div className="heading location">
            <h2>Location</h2>
          </div>
          <div className="heading course">
            <h2>Course</h2>
          </div>
        </div>
        <div className="table__body">
          {Array.isArray(pages) &&
            pages.length > 0 &&
            pages[pagination.currentPage - 1].map((tournament, index) => (
              <Link
                className="table__row"
                key={index}
                to={`/dashboard/tournament/edit/${tournament.id}`}
              >
                <div className="cell title">
                  <div className="tournament__logo list">
                    <SmartImage
                      url={tournament.tournament_logo_url}
                      alt="tournament logo"
                    />
                  </div>
                  <span>{tournament.tournament_name}</span>
                </div>
                <div className="cell start">{tournament.start}</div>
                <div className="cell end">{tournament.end}</div>
                <div className="cell location">
                  <span>{tournament.tournament_location}</span>
                </div>
                <div className="cell course">
                  <span>{tournament.golf_course}</span>
                </div>
              </Link>
            ))}
        </div>
      </div>
      <div className="meta__bar">
        <PageIndicator pages={pages} pagination={pagination} />
        {Array.isArray(pages) && pages.length > 1 && (
          <Pagination pagination={pagination} />
        )}
      </div>
    </div>
  );
}
