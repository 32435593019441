import React from "react";
import { Icon } from "semantic-ui-react";

export default function AccordionsView({ accordions, printMode }) {
  return (
    <div>
      {accordions &&
        accordions.sections &&
        typeof accordions.sections === "object" &&
        Object.entries(accordions.sections).map(([title, child]) => (
          <div
            key={title}
            className={`accordion__section ${
              title === accordions.current ? "expand" : "collapse"
            }`}
          >
            <button
              className="accordion__head"
              value={title}
              onClick={() => accordions.select(title)}
            >
              <h3>{title}</h3> <Icon name="caret up" />
            </button>
            <div className="accordion__body">{child}</div>
          </div>
        ))}
    </div>
  );
}
