import React from "react";
import { spaceBeforeInteger } from "../../../../../utility";

export default function TabSelectorsView({
  selectors,
  currentTab,
  scrollBoxRef,
  mouse,
}) {
  return (
    <div className="tab__selectors">
      <div
        ref={scrollBoxRef}
        onMouseDown={(e) => mouse.mouseDown(e)}
        onMouseUp={(e) => mouse.mouseUp(e)}
        onMouseMove={(e) => mouse.mouseMove(e)}
        onMouseLeave={(e) => mouse.mouseUp(e)}
        onWheel={(e) => mouse.mouseWheel(e)}
        className="selectors__container"
      >
        {selectors &&
          selectors.length > 0 &&
          selectors.map((selector, index) => (
            <button
              key={index}
              value={selector}
              className={`btn tab ${
                selector === currentTab.value ? "active" : ""
              }`}
              onClick={(e) => currentTab.setter(e.target.value)}
            >
              {spaceBeforeInteger(selector)}
            </button>
          ))}
      </div>
    </div>
  );
}
