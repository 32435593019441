import React from "react";
import TournamentDetailsView from "./view";

export default function TournamentDetailsController({
  tourCode,
  tournamentProps,
}) {
  return (
    <TournamentDetailsView
      tournamentProps={tournamentProps}
      tourCode={tourCode}
    />
  );
}
