import React from "react";
import { computeDateRange } from "../../../../utility";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function Navigator({ prevNext }) {
  const { prev, next } = prevNext;

  return (
    <div className="tournament__navigator">
      {prev ? (
        <Link to={`/tournaments/${prev.id}`} className="tournament__prev">
          <div className="icon">
            <Icon name="chevron left" />
          </div>
          <div className="empty__content">
            <span className="subtitle">Previous Event</span>
            <h5>{prev.tournament_name}</h5>
            <span className="date">
              {computeDateRange(prev.tournament_start, prev.tournament_end)}
            </span>
          </div>
        </Link>
      ) : (
        <div></div>
      )}

      {next ? (
        <Link to={`/tournaments/${next.id}`} className="tournament__next">
          <div className="empty__content">
            <span className="subtitle">Next Event</span>
            <h5>{next.tournament_name}</h5>
            <span className="date">
              {computeDateRange(next.tournament_start, next.tournament_end)}
            </span>
          </div>
          <div className="icon">
            <Icon name="chevron right" />
          </div>
        </Link>
      ) : (
        <div></div>
      )}
    </div>
  );
}
