import React from "react";
import Document from "../../../../components/Document";
import EmptyDialogue from "../../../../components/EmptyDialogue";
import SectionHead from "../../../../components/SectionHead";
import { extractFileNameFromPath } from "../../../../utility";
import LoadingDialogue from "../../../../components/LoadingDialogue";

export default function TalentRotationView({
  files,
  initializing,
  sectionRef,
}) {
  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Talent Rotations"} method={null} />
      <div
        className="section__body clamp__height"
        style={{ position: "relative" }}
      >
        {initializing ? (
          <div className="empty__content">
            <LoadingDialogue />
          </div>
        ) : (
          <>
            {files &&
              Array.isArray(files) &&
              (files.length > 0 ? (
                <div
                  className="document__container col2"
                  style={{ position: "relative", height: "100%" }}
                >
                  {files.map((file, index) => (
                    <Document
                      key={index}
                      name={
                        file.label
                          ? file.label
                          : extractFileNameFromPath(file.file_name)
                      }
                      path={{
                        prepend: null,
                        path: file.fileURI,
                      }}
                      externalURL={file.s3URI}
                      lastUpdated={"2d Ago"}
                    />
                  ))}
                </div>
              ) : (
                <div className="empty__content">
                  <EmptyDialogue />
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
