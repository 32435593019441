import React, { useContext, useEffect, useState } from "react";
import UINotification from "../components/UINotification";
import { useIsMounted } from "../hooks/useIsMounted";
const NotificationContext = React.createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
  const [displayNotification, setDisplayNotification] = useState(false);
  const [notification, setNotification] = useState({
    message: "hello",
    iconName: "checkmark",
    hex: "var(--highlight-primary-2)",
  });

  let isMounted = useIsMounted();

  useEffect(() => {
    if (displayNotification === true) {
      setTimeout(() => {
        if (isMounted.current) {
          setNotification({
            message: "",
            iconName: "check",
            hex: "var(--highlight-primary-2)",
          });
          setDisplayNotification(false);
        }
      }, 3100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNotification]);

  const value = {
    setDisplayNotification,
    setNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {displayNotification && (
        <UINotification
          message={notification.message}
          iconName={notification.iconName}
          hex={notification.hex}
        />
      )}
      {children}
    </NotificationContext.Provider>
  );
}
