import React, { useEffect, useState } from "react";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";
import { useAuth } from "../context/AuthProvider";
import { Redirect } from "react-router-dom";
import Preloader from "../components/Preloader";

export default function AuthPage({ location }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const authContext = useAuth();
  const { profile, token } = authContext;

  const setUserData = ({ response }) => {
    token.accessToken.setter(response.access_token);
    token.idToken.setter(response.id_token);
    token.tokenExpiry.setter(response.expires_in);
    const currentTime = new Date().getTime() / 1000;
    token.tokenGenerationTime.setter(currentTime);
    profile.setter(jwtDecode(response.id_token));
  };

  useEffect(() => {
    if (
      (typeof profile.value === "object" && profile.value === null) ||
      profile.value === null ||
      profile.value === undefined
    ) {
      const hash = location.hash;
      setIsLoading(true);
      const response = parse(hash);
      if (response.error) {
        return setIsLoading(false);
      } else {
        setUserData({ response: response });
      }
    } else {
      setIsAuthenticated(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isAuthenticated) {
    return <Redirect to="/" />;
  } else if (!isAuthenticated && isLoading) {
    return (
      <Preloader
        message={"Loading application..."}
        position={{
          pos: "fixed",
          top: "0",
          left: "0",
        }}
        zIndex="600000"
      />
    );
  } else {
    return <Redirect to="/login" />;
  }
}
