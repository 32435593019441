import React, { useEffect, useState } from "react";
import PlayerNotesView from "./view";
import { postPlayerNotes } from "../../../../api/Resources/player_notes";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { useAuth } from "../../../../context/AuthProvider";

export default function PlayerNotesController({ playerNotes, sectionRef }) {
  const [isInitializing, setIsInitializing] = useState(false);
  const [computedPlayerNotes, setComputedPlayerNotes] = useState([]);

  const authContext = useAuth();
  const { apiKey, isStaff, isAdmin, isTalent, isGuest } = authContext;

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isStaff || isAdmin || isTalent || isGuest) {
      if (isMounted.current) setIsInitializing(true);
      let note_ = [...playerNotes];

      for (let i = 0; i < note_.length; i++) {
        let s3URL;
        try {
          const { data } = await postPlayerNotes.fetchS3Url({
            uid: note_[i].id,
            apiKey: apiKey,
          });
          s3URL = data.s3_url;
        } catch (error) {
          console.log(error);
          s3URL = "";
        }

        note_[i] = {
          ...note_[i],
          fileURI: note_[i].fileURI.replace(`/${note_[i].id}`, ""),
          s3URI: s3URL,
        };
      }
      if (isMounted.current) setIsInitializing(false);
      if (isMounted.current) setComputedPlayerNotes(note_);
    }
  };

  useEffect(() => {
    if (Array.isArray(playerNotes) && playerNotes.length > 0) initializeData();
  }, [playerNotes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlayerNotesView
      playerNotes={computedPlayerNotes}
      initializing={isInitializing}
      sectionRef={sectionRef}
    />
  );
}
