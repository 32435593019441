import React, { useContext, useEffect, useState } from "react";
import { getAllTournaments } from "../api/Tournaments/tournament";
import Preloader from "../components/Preloader";
import useLocalStorage from "../hooks/useLocalStorage";
import { useAuth } from "./AuthProvider";
const TournamentContext = React.createContext();

export function useTournaments() {
  return useContext(TournamentContext);
}

export function TournamentsProvider({ children }) {
  let tournamentsInitialState = {
    R: [],
    S: [],
    H: [],
  };
  const [tournaments, setTournaments] = useState(tournamentsInitialState);
  const [tournamentsLoaded, setTournamentsLoaded] = useState(false);
  const [selectedTour, setSelectedTour] = useLocalStorage("selectedTour", "R");
  const { apiKey } = useAuth();

  const fetchTournaments = async () => {
    let tournamentsState = { ...tournamentsInitialState };
    let tourCodes = Object.keys(tournamentsInitialState);

    for (let i = 0; i < tourCodes.length; i++) {
      let result = [];
      let tourCode = tourCodes[i];

      try {
        result = await getAllTournaments({ apiKey: apiKey, tourCode });
        if (result.error)
          throw new Error(
            `error fetching tournaments in tour code: ${tourCode}`
          );
      } catch (error) {
        console.log(error);
        result = [];
      }
      tournamentsState[tourCode] = result;
    }

    setTournaments(tournamentsState);
    setTournamentsLoaded(true);
  };

  const setBrandColors = () => {
    let brandColor = brandColors[selectedTour];
    if (!brandColor) return;

    Object.entries(brandColor).forEach(([key, hex]) => {
      document.documentElement.style.setProperty(variableMap[key], `${hex}`);
    });
  };

  useEffect(() => {
    setBrandColors();
  }, [selectedTour]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchTournaments();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tours = [
    {
      key: "R",
      label: "PGA TOUR",
    },
    {
      key: "S",
      label: "PGA TOUR CHAMPIONS",
    },
    {
      key: "H",
      label: "KORN FERRY TOUR",
    },
  ];

  const value = {
    selectedTour: {
      value: selectedTour,
      setter: setSelectedTour,
    },
    tours: tours,
    tournaments: tournaments,
    fetchTournaments: fetchTournaments,
  };

  return (
    <TournamentContext.Provider value={value}>
      {children}
      {!tournamentsLoaded && (
        <Preloader
          message={"Loading application data..."}
          position={{
            pos: "fixed",
            top: "0",
            left: "0",
          }}
          zIndex="600000"
        />
      )}
    </TournamentContext.Provider>
  );
}

const brandColors = {
  R: {
    primary: "#044a95",
    secondary: "#043c77",
    highlight: "#fc1923",
  },
  H: {
    primary: "#00503e",
    secondary: "#003a2c",
    highlight: "#fc1923",
  },
  S: {
    primary: "#44494c",
    secondary: "#23282b",
    highlight: "#fc1923",
  },
};

const variableMap = {
  primary: "--highlight-primary-1",
  secondary: "--highlight-primary-2",
  highlight: "--highlight-primary-3",
};
