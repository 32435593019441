import React, { useRef, useState, useEffect } from "react";
import DotComLowerThirdsView from "./view";
import { useReactToPrint } from "react-to-print";

export default function DotComLowerThirdsController({ sectionRef, promos }) {
  const initialPromos = {
    1: [],
    2: [],
    3: [],
    4: [],
  };
  const [computedPromos, setComputedPromos] = useState(initialPromos);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const organizePromosIntoRounds = () => {
    let rounds = { ...initialPromos };

    promos
      .sort((a, b) => {
        // parse unix time to js date.
        return a.position - b.position;
      })
      .forEach((p) => {
        if (!rounds[p.round_id]) rounds[p.round_id] = [];
        rounds[p.round_id].push(p);
      });

    setComputedPromos(rounds);
  };

  useEffect(() => {
    if (Array.isArray(promos) && promos.length > 0) organizePromosIntoRounds();
  }, [promos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DotComLowerThirdsView
      promos={computedPromos}
      printRef={printRef}
      method={{
        function: () => handlePrint(),
        name: "Print Section",
        icon: "print",
      }}
      sectionRef={sectionRef}
    />
  );
}
