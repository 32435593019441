import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SmartImage from "../../../../components/SmartImage/controller";
import Memorandums from "../../../TournamentDetails/Components/Memorandums/controller";
import PersonnelList from "../../../../components/PersonnelList";
import TournamentDetails from "../../../../components/TournamentDetails";

export default function TournamentPreview({ selectTournament, previewRef }) {
  return (
    <>
      {selectTournament.selected && (
        <>
          <div
            className="section__anchor__main overview"
            ref={previewRef}
          ></div>
          <div className="preview__overview" style={{ margin: 0, padding: 0 }}>
            {selectTournament.selected.id === selectTournament?.current?.id ? (
              <div className="current">This Week's Event</div>
            ) : selectTournament?.current?.id ? (
              <div className="current">
                <button
                  onClick={() =>
                    selectTournament.select(selectTournament.current.id)
                  }
                >
                  <Icon name="chevron left" /> Back to this week&apos;s event
                </button>
              </div>
            ) : (
              <div className="current">Event</div>
            )}

            <div className="tournament__header">
              <div>
                <Link to={`/tournaments/${selectTournament.selected.id}`}>
                  <h3>{selectTournament.selected.tournament_name}</h3>
                </Link>
              </div>
              <div className="tournament__logo">
                <Link to={`/tournaments/${selectTournament.selected.id}`}>
                  <SmartImage
                    url={selectTournament.selected.tournament_logo_url}
                    alt="tournament logo"
                  />
                </Link>
              </div>
            </div>
            <TournamentDetails
              date_range={selectTournament?.selected?.date_range}
              tournament_location={
                selectTournament?.selected?.tournament_location
              }
              golf_course={selectTournament?.selected?.golf_course}
            />
            <PersonnelList
              key={`${selectTournament?.selected.id} – personnel`}
              personnel={selectTournament?.selected?.personnel}
            />

            <Memorandums
              key={`${selectTournament?.selected.id} – memorandums`}
              memos={selectTournament?.selected?.memos}
              links={selectTournament?.selected?.memos_link}
            />
          </div>
        </>
      )}
    </>
  );
}
