import React from "react";
import { Icon } from "semantic-ui-react";

export default function SectionHead({ title, method, icon }) {
  return (
    <div className={`section__head ${icon ? "icon" : ""}`}>
      {icon && (
        <div className="head__icon">
          <Icon name={icon} />
        </div>
      )}
      <h2
        style={
          method
            ? { textAlign: "left", paddingLeft: 20, paddingRight: 110 }
            : { textAlign: "center", paddingRight: 0 }
        }
      >
        {title}
      </h2>
      {method && (
        <button onClick={() => method.function()} className="btn">
          {method.icon && <Icon name={method.icon} />}
          {method.name}
        </button>
      )}
    </div>
  );
}
