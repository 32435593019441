import React, { useState, useEffect } from "react";
import AdditionalResourcesView from "./view";
import { postAdditionalResourceFile } from "../../../../api/Resources/additional_resource";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { useAuth } from "../../../../context/AuthProvider";

export default function AdditionalResourcesController({
  files,
  links,
  sectionRef,
}) {
  const [computedFiles, setComputedFiles] = useState([]);
  const [isInitializing, setIsInitializing] = useState(false);

  const { apiKey } = useAuth();

  let isMounted = useIsMounted();

  const initializeData = async () => {
    if (isMounted.current) setIsInitializing(true);
    let files_ = [...files];

    for (let i = 0; i < files_.length; i++) {
      let s3URL;
      try {
        const { data } = await postAdditionalResourceFile.fetchS3Url({
          uid: files_[i].id,
          apiKey: apiKey,
        });

        s3URL = data.s3_url;
      } catch (error) {
        console.log(error);
        s3URL = "";
      }

      files_[i] = {
        ...files_[i],
        fileURI: files_[i].fileURI.replace(`/${files_[i].id}`, ""),
        s3URI: s3URL,
      };
    }

    if (isMounted.current) {
      setIsInitializing(false);
      setComputedFiles(files_);
    }
  };

  useEffect(() => {
    if (Array.isArray(files) && files.length > 0) {
      initializeData();
    } else {
      setComputedFiles([]);
    }
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AdditionalResourcesView
      files={computedFiles}
      sectionRef={sectionRef}
      initializing={isInitializing}
      links={links}
    />
  );
}
